import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';
import {BindObservable} from 'bind-observable';
import {isObject} from 'lodash';
import { BehaviorSubject, isObservable } from 'rxjs';
import {take} from 'rxjs/operators';

/*tslint:disable component-class-suffix component-selector*/
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'field-switch-select',
    template: `
        <div class="input-group">
            <ng-select
                    class="flex-width"
                    [items]="options$ | async"
                    [formControl]="formControl"
                    [searchable]="to.hasOwnProperty('searchable') ? to.searchable : true"
                    [clearable]="!to.required"
                    [addTag]="field.templateOptions.tagable ? addTagHandler : false"
                    [compareWith]="field.templateOptions.compareWith ? field.templateOptions.compareWith : compareFn"
                    bindValue="value"
            >
                <ng-template ng-header-tmp>
                    <div class='d-flex'>
                        <app-switch [(ngModel)]='switchOptions' (ngModelChange)='changeOptions()'></app-switch>
                        <div style='margin-left: 5px'>{{to.checkboxLabel}}</div>
                    </div>

                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label" [title]="item.label">{{ item.label }}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <span class="ng-option-label" [title]="item.label">{{ item.label }}</span>
                </ng-template>
            </ng-select>
        </div>

    `,
})
export class Switchselect extends FieldType<FieldTypeConfig> implements AfterViewInit, OnInit {
    declare to: any;
    switchOptions = false;
    @BindObservable()
    private optionsSubject = new BehaviorSubject<any[]>([]);
    options$ = this.optionsSubject.asObservable();

    changeOptions() {
        const options = this.switchOptions ? this.to.options2 : this.to.options1;
        if (isObservable(options)) {
            options.pipe(take(1)).subscribe(data => this.optionsSubject.next(data as []));
        } else {
            this.optionsSubject.next(options as []);
        }
    }

    ngOnInit() {
        this.changeOptions();
    }

    get disableIfSingleOption(): boolean {
        if (this.to.hasOwnProperty('disableIfSingleOption')) {
            return this.to.disableIfSingleOption;
        }
        return false;
    }

    ngAfterViewInit(): void {
        if (!this.field.templateOptions.readonly) {
            this.formControl.enable();
        } else {
            this.formControl.disable();
        }
        if (this.disableIfSingleOption) {
            this.options$.pipe(take(1)).subscribe(options => {
                if (options.length === 1 && this.to.required && this.formControl.value === options[0].value) {
                    this.formControl.disable();
                }
            });
        }
    }

    addTagHandler(s) {
        return {label: s, value: s, tag: true};
    }

    compareFn(item, selected) {
        return item.value === (isObject(selected) ? selected['value'] : selected);
    }

}
