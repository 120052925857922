import {Component} from '@angular/core';
import {fadeAnimation} from 'shared/animations/fade.animation';

@Component({
    selector: 'app-main-layout',
    template: `
        <app-sidebar>
            <div top>
                <app-logo></app-logo>
                <app-notifications-chip></app-notifications-chip>
            </div>
            <app-user-chip bottom></app-user-chip>
        </app-sidebar>
        <div class="main-view-wrapper">
            <div class="main-view">
                <app-navbar></app-navbar>
                <div class="p-3 pt-0">
                    <div class="p-0 border-0">
                        <div class="p-0">
                            <div class="router-container" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
                                <router-outlet #o="outlet"></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    `,
    animations: [fadeAnimation],
})
export class MainLayoutComponent {
}
