import {Component} from '@angular/core';
import {NotificationService} from 'app/modules/notification/notification.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-notifications-chip',
    template: `
        <div class="d-flex flex-column align-items-center" style="height: 44px">
            <a routerLink="/notification">
                <div class="position-relative">
                <span class="fs-2 text-secondary">
                    <i class="bi bi-bell"></i>
                </span>
                    <ng-container *ngLet="notificationsCount$ | async as notificationsCount">
                        <span class="position-absolute badge" *ngIf="notificationsCount">{{ notificationsCount }}</span>
                    </ng-container>
                </div>
            </a>
        </div>
    `,
    styles: []
})
export class NotificationsChipComponent {
    notificationsCount$: Observable<number>;

    constructor(private data: NotificationService) {
        this.notificationsCount$ = this.data.notifications$.pipe(
            map(items => items.filter((item) => !item.is_read).length),
        );
    }
}
