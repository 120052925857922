import {of} from "rxjs";
import {tap} from "rxjs/operators";

export function CachedObservable(cachedProperty) {
    return (target: any, key: string, descriptor: PropertyDescriptor) => {

        if(descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            if (target[cachedProperty]) {
              return of(target[cachedProperty]);
            }

            return originalMethod.apply(this, args).pipe(
                tap(data => target[cachedProperty] = data)
            );
        };

        return descriptor;
    }
}