import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
})
export class InfoModalComponent {
  title: 'Инфо';
  closeButtonTitle: 'Закрыть';
  message: string;
  modalRef: BsModalRef;
}
