import {NgModule} from '@angular/core';
import {LogoSvgComponent} from 'app/layout/logo-svg/logo-svg.component';

@NgModule({
    imports: [],
    exports: [
        LogoSvgComponent
    ],
    declarations: [LogoSvgComponent],
    providers: [],
})
export class LogoSvgModule {
}
