import { CurrencyPipe } from '@angular/common';
import {NgModule} from '@angular/core';
import {MoneyPipe} from "shared/pipes/money/money.pipe";

@NgModule({
    imports: [],
    exports: [MoneyPipe],
    declarations: [MoneyPipe],
    providers: [
        CurrencyPipe
    ],
})
export class MoneyPipeModule {
}
