import {trigger} from '@angular/animations';
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-logo-svg',
    template: `
        <svg width="247" height="61" viewBox="0 0 247 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path @path
                  d="M83.2836 11.959H84.1766L97.9946 45H94.9866L90.4276 34.331H77.0326L72.5676 45H69.5596L83.2836 11.959ZM84.8816 20.701C84.3803 19.4477 83.9886 18.2257 83.7066 17.035C83.3306 18.5077 82.9546 19.7297 82.5786 20.701L78.1606 31.699H89.2996L84.8816 20.701ZM117.822 42.697C114.626 42.4777 111.9 41.6943 109.644 40.347C107.419 38.9683 105.727 37.2293 104.568 35.13C103.44 33.0307 102.876 30.8217 102.876 28.503C102.876 26.153 103.456 23.9283 104.615 21.829C105.774 19.7297 107.482 17.9907 109.738 16.612C111.994 15.2333 114.704 14.45 117.869 14.262V10.972H120.689V14.262C123.885 14.45 126.595 15.249 128.82 16.659C131.076 18.0377 132.768 19.7767 133.896 21.876C135.055 23.9753 135.635 26.2 135.635 28.55C135.635 30.9 135.071 33.1247 133.943 35.224C132.815 37.3233 131.123 39.0623 128.867 40.441C126.611 41.8197 123.885 42.603 120.689 42.791V46.034H117.869L117.822 42.697ZM117.869 16.894C115.3 17.1133 113.091 17.787 111.242 18.915C109.425 20.043 108.046 21.4687 107.106 23.192C106.166 24.884 105.696 26.67 105.696 28.55C105.696 30.3987 106.15 32.1533 107.059 33.814C107.999 35.4433 109.378 36.822 111.195 37.95C113.044 39.078 115.253 39.783 117.822 40.065L117.869 16.894ZM120.689 39.971C123.258 39.783 125.452 39.125 127.269 37.997C129.118 36.869 130.496 35.4747 131.405 33.814C132.345 32.122 132.815 30.336 132.815 28.456C132.815 26.576 132.345 24.8057 131.405 23.145C130.496 21.453 129.118 20.043 127.269 18.915C125.452 17.787 123.258 17.1133 120.689 16.894V39.971ZM171.535 45H168.715V22.346L168.997 18.633L166.553 21.829L150.009 42.321L147.377 45.047H145.544V12.805H148.364V37.997L148.317 40.347L149.915 38.232L170.642 12.382H171.535V45ZM183.705 12.852H186.525V26.952H203.868V12.852H206.688V45H203.868V29.584H186.525V45H183.705V12.852ZM229.413 11.959H230.306L244.124 45H241.116L236.557 34.331H223.162L218.697 45H215.689L229.413 11.959ZM231.011 20.701C230.51 19.4477 230.118 18.2257 229.836 17.035C229.46 18.5077 229.084 19.7297 228.708 20.701L224.29 31.699H235.429L231.011 20.701Z"
                  fill="#272727"
            />
            <mask id="path-2-outside-1_85_13" maskUnits="userSpaceOnUse" x="29.0454" y="10.489" width="33.8446" height="35.9659" fill="black">
                <rect fill="white" x="29.0454" y="10.489" width="33.8446" height="35.9659"/>
                <path @path d="M36.3895 17.1757C38.1479 15.8706 40.1909 15.0016 42.3505 14.64C44.5102 14.2783 46.7249 14.4345 48.8124 15.0956C50.9 15.7568 52.8008 16.904 54.3586 18.4429C55.9163 19.9818 57.0866 21.8685 57.7731 23.9478C58.4596 26.0272 58.6428 28.2398 58.3075 30.4037C57.9722 32.5676 57.1281 34.621 55.8445 36.3951C54.561 38.1693 52.8747 39.6134 50.9242 40.6087C48.9738 41.6041 46.815 42.1223 44.6252 42.1207L44.6267 40.0206C46.4841 40.022 48.3153 39.5824 49.9696 38.7382C51.624 37.8939 53.0544 36.669 54.1431 35.1642C55.2318 33.6594 55.9478 31.9176 56.2322 30.0821C56.5166 28.2467 56.3612 26.3699 55.7789 24.6062C55.1966 22.8425 54.204 21.2422 52.8827 19.9368C51.5614 18.6315 49.9491 17.6585 48.1784 17.0977C46.4077 16.5369 44.5292 16.4044 42.6973 16.7112C40.8655 17.0179 39.1326 17.755 37.6411 18.862L36.3895 17.1757Z"/>
            </mask>
            <path @path d="M36.3895 17.1757C38.1479 15.8706 40.1909 15.0016 42.3505 14.64C44.5102 14.2783 46.7249 14.4345 48.8124 15.0956C50.9 15.7568 52.8008 16.904 54.3586 18.4429C55.9163 19.9818 57.0866 21.8685 57.7731 23.9478C58.4596 26.0272 58.6428 28.2398 58.3075 30.4037C57.9722 32.5676 57.1281 34.621 55.8445 36.3951C54.561 38.1693 52.8747 39.6134 50.9242 40.6087C48.9738 41.6041 46.815 42.1223 44.6252 42.1207L44.6267 40.0206C46.4841 40.022 48.3153 39.5824 49.9696 38.7382C51.624 37.8939 53.0544 36.669 54.1431 35.1642C55.2318 33.6594 55.9478 31.9176 56.2322 30.0821C56.5166 28.2467 56.3612 26.3699 55.7789 24.6062C55.1966 22.8425 54.204 21.2422 52.8827 19.9368C51.5614 18.6315 49.9491 17.6585 48.1784 17.0977C46.4077 16.5369 44.5292 16.4044 42.6973 16.7112C40.8655 17.0179 39.1326 17.755 37.6411 18.862L36.3895 17.1757Z"
                  fill="#5960FF"
            />
            <path @path d="M36.3895 17.1757C38.1479 15.8706 40.1909 15.0016 42.3505 14.64C44.5102 14.2783 46.7249 14.4345 48.8124 15.0956C50.9 15.7568 52.8008 16.904 54.3586 18.4429C55.9163 19.9818 57.0866 21.8685 57.7731 23.9478C58.4596 26.0272 58.6428 28.2398 58.3075 30.4037C57.9722 32.5676 57.1281 34.621 55.8445 36.3951C54.561 38.1693 52.8747 39.6134 50.9242 40.6087C48.9738 41.6041 46.815 42.1223 44.6252 42.1207L44.6267 40.0206C46.4841 40.022 48.3153 39.5824 49.9696 38.7382C51.624 37.8939 53.0544 36.669 54.1431 35.1642C55.2318 33.6594 55.9478 31.9176 56.2322 30.0821C56.5166 28.2467 56.3612 26.3699 55.7789 24.6062C55.1966 22.8425 54.204 21.2422 52.8827 19.9368C51.5614 18.6315 49.9491 17.6585 48.1784 17.0977C46.4077 16.5369 44.5292 16.4044 42.6973 16.7112C40.8655 17.0179 39.1326 17.755 37.6411 18.862L36.3895 17.1757Z"
                  stroke="#5960FF"
                  stroke-width="1.2"
                  mask="url(#path-2-outside-1_85_13)"
            />
            <mask id="path-3-outside-2_85_13" maskUnits="userSpaceOnUse" x="24.4339" y="-0.441974" width="49.5788" height="52.8731" fill="black">
                <rect fill="white" x="24.4339" y="-0.441974" width="49.5788" height="52.8731"/>
                <path @path d="M37.5514 9.35357C40.3314 8.32932 43.3022 7.92691 46.2545 8.17468C49.2069 8.42245 52.069 9.31438 54.6395 10.7877C57.2099 12.2609 59.4261 14.2798 61.1322 16.702C62.8382 19.1242 63.9925 21.891 64.5139 24.8075C65.0352 27.724 64.9109 30.7193 64.1497 33.5825C63.3886 36.4458 62.009 39.1074 60.1082 41.38C58.2073 43.6526 55.8314 45.4808 53.1478 46.7361C50.4641 47.9913 47.5379 48.6431 44.5752 48.6454L44.5736 46.621C47.2405 46.6189 49.8744 46.0322 52.2901 44.9023C54.7057 43.7724 56.8443 42.1267 58.5553 40.0811C60.2663 38.0355 61.5081 35.6397 62.1932 33.0624C62.8784 30.4851 62.9903 27.7889 62.521 25.1637C62.0517 22.5385 61.0127 20.0481 59.477 17.8677C57.9414 15.6874 55.9465 13.8702 53.6328 12.5441C51.319 11.2179 48.7427 10.4151 46.0852 10.192C43.4278 9.96901 40.7537 10.3312 38.2513 11.2532L37.5514 9.35357Z"/>
            </mask>
            <path @path d="M37.5514 9.35357C40.3314 8.32932 43.3022 7.92691 46.2545 8.17468C49.2069 8.42245 52.069 9.31438 54.6395 10.7877C57.2099 12.2609 59.4261 14.2798 61.1322 16.702C62.8382 19.1242 63.9925 21.891 64.5139 24.8075C65.0352 27.724 64.9109 30.7193 64.1497 33.5825C63.3886 36.4458 62.009 39.1074 60.1082 41.38C58.2073 43.6526 55.8314 45.4808 53.1478 46.7361C50.4641 47.9913 47.5379 48.6431 44.5752 48.6454L44.5736 46.621C47.2405 46.6189 49.8744 46.0322 52.2901 44.9023C54.7057 43.7724 56.8443 42.1267 58.5553 40.0811C60.2663 38.0355 61.5081 35.6397 62.1932 33.0624C62.8784 30.4851 62.9903 27.7889 62.521 25.1637C62.0517 22.5385 61.0127 20.0481 59.477 17.8677C57.9414 15.6874 55.9465 13.8702 53.6328 12.5441C51.319 11.2179 48.7427 10.4151 46.0852 10.192C43.4278 9.96901 40.7537 10.3312 38.2513 11.2532L37.5514 9.35357Z"
                  fill="#5960FF"
            />
            <path @path d="M37.5514 9.35357C40.3314 8.32932 43.3022 7.92691 46.2545 8.17468C49.2069 8.42245 52.069 9.31438 54.6395 10.7877C57.2099 12.2609 59.4261 14.2798 61.1322 16.702C62.8382 19.1242 63.9925 21.891 64.5139 24.8075C65.0352 27.724 64.9109 30.7193 64.1497 33.5825C63.3886 36.4458 62.009 39.1074 60.1082 41.38C58.2073 43.6526 55.8314 45.4808 53.1478 46.7361C50.4641 47.9913 47.5379 48.6431 44.5752 48.6454L44.5736 46.621C47.2405 46.6189 49.8744 46.0322 52.2901 44.9023C54.7057 43.7724 56.8443 42.1267 58.5553 40.0811C60.2663 38.0355 61.5081 35.6397 62.1932 33.0624C62.8784 30.4851 62.9903 27.7889 62.521 25.1637C62.0517 22.5385 61.0127 20.0481 59.477 17.8677C57.9414 15.6874 55.9465 13.8702 53.6328 12.5441C51.319 11.2179 48.7427 10.4151 46.0852 10.192C43.4278 9.96901 40.7537 10.3312 38.2513 11.2532L37.5514 9.35357Z"
                  stroke="#5960FF"
                  stroke-width="1.2"
                  mask="url(#path-3-outside-2_85_13)"
            />
            <path @path d="M38.571 18.8328L32.2844 10.3413L32.2844 27.8666L42.4199 27.8666" stroke="#5960FF" stroke-width="2.8"/>
            <mask id="path-5-outside-3_85_13" maskUnits="userSpaceOnUse" x="9.3598" y="14.7395" width="33.8446" height="35.9659" fill="black">
                <rect fill="white" x="9.3598" y="14.7395" width="33.8446" height="35.9659"/>
                <path @path d="M35.8603 44.0186C34.102 45.3237 32.059 46.1928 29.8993 46.5544C27.7396 46.916 25.5249 46.7598 23.4374 46.0987C21.3498 45.4376 19.449 44.2904 17.8912 42.7515C16.3335 41.2125 15.1632 39.3258 14.4767 37.2465C13.7902 35.1672 13.6071 32.9546 13.9423 30.7906C14.2776 28.6267 15.1217 26.5733 16.4053 24.7992C17.6888 23.0251 19.3751 21.581 21.3256 20.5856C23.276 19.5903 25.4349 19.0721 27.6246 19.0737L27.6231 21.1737C25.7657 21.1724 23.9346 21.6119 22.2802 22.4562C20.6258 23.3004 19.1954 24.5253 18.1067 26.0302C17.018 27.535 16.302 29.2767 16.0176 31.1122C15.7332 32.9476 15.8886 34.8244 16.4709 36.5881C17.0532 38.3518 18.0458 39.9522 19.3671 41.2575C20.6885 42.5628 22.3008 43.5359 24.0714 44.0966C25.8421 44.6574 27.7206 44.7899 29.5525 44.4832C31.3843 44.1765 33.1172 43.4393 34.6087 42.3323L35.8603 44.0186Z"/>
            </mask>
            <path @path d="M35.8603 44.0186C34.102 45.3237 32.059 46.1928 29.8993 46.5544C27.7396 46.916 25.5249 46.7598 23.4374 46.0987C21.3498 45.4376 19.449 44.2904 17.8912 42.7515C16.3335 41.2125 15.1632 39.3258 14.4767 37.2465C13.7902 35.1672 13.6071 32.9546 13.9423 30.7906C14.2776 28.6267 15.1217 26.5733 16.4053 24.7992C17.6888 23.0251 19.3751 21.581 21.3256 20.5856C23.276 19.5903 25.4349 19.0721 27.6246 19.0737L27.6231 21.1737C25.7657 21.1724 23.9346 21.6119 22.2802 22.4562C20.6258 23.3004 19.1954 24.5253 18.1067 26.0302C17.018 27.535 16.302 29.2767 16.0176 31.1122C15.7332 32.9476 15.8886 34.8244 16.4709 36.5881C17.0532 38.3518 18.0458 39.9522 19.3671 41.2575C20.6885 42.5628 22.3008 43.5359 24.0714 44.0966C25.8421 44.6574 27.7206 44.7899 29.5525 44.4832C31.3843 44.1765 33.1172 43.4393 34.6087 42.3323L35.8603 44.0186Z"
                  fill="#272727"
            />
            <path @path d="M35.8603 44.0186C34.102 45.3237 32.059 46.1928 29.8993 46.5544C27.7396 46.916 25.5249 46.7598 23.4374 46.0987C21.3498 45.4376 19.449 44.2904 17.8912 42.7515C16.3335 41.2125 15.1632 39.3258 14.4767 37.2465C13.7902 35.1672 13.6071 32.9546 13.9423 30.7906C14.2776 28.6267 15.1217 26.5733 16.4053 24.7992C17.6888 23.0251 19.3751 21.581 21.3256 20.5856C23.276 19.5903 25.4349 19.0721 27.6246 19.0737L27.6231 21.1737C25.7657 21.1724 23.9346 21.6119 22.2802 22.4562C20.6258 23.3004 19.1954 24.5253 18.1067 26.0302C17.018 27.535 16.302 29.2767 16.0176 31.1122C15.7332 32.9476 15.8886 34.8244 16.4709 36.5881C17.0532 38.3518 18.0458 39.9522 19.3671 41.2575C20.6885 42.5628 22.3008 43.5359 24.0714 44.0966C25.8421 44.6574 27.7206 44.7899 29.5525 44.4832C31.3843 44.1765 33.1172 43.4393 34.6087 42.3323L35.8603 44.0186Z"
                  stroke="#272727"
                  stroke-width="1.2"
                  mask="url(#path-5-outside-3_85_13)"
            />
            <mask id="path-6-outside-4_85_13" maskUnits="userSpaceOnUse" x="-1.7629" y="8.76325" width="49.5788" height="52.8731" fill="black">
                <rect fill="white" x="-1.7629" y="8.76325" width="49.5788" height="52.8731"/>
                <path @path d="M34.6984 51.8408C31.9139 52.8667 28.9381 53.2687 25.9812 53.0185C23.0243 52.7682 20.1584 51.8719 17.5859 50.3926C15.0133 48.9134 12.797 46.8874 11.0933 44.4577C9.38961 42.028 8.24015 39.2539 7.7261 36.3313C7.21205 33.4086 7.34595 30.4088 8.11834 27.5436C8.89074 24.6784 10.2828 22.0177 12.1962 19.7495C14.1095 17.4812 16.4976 15.6607 19.1917 14.4166C21.8858 13.1724 24.8201 12.5349 27.7876 12.5492L27.7779 14.5736C25.1068 14.5608 22.4655 15.1346 20.0405 16.2545C17.6154 17.3744 15.4659 19.0131 13.7436 21.0548C12.0213 23.0965 10.7683 25.4915 10.073 28.0705C9.37776 30.6496 9.25723 33.3498 9.71994 35.9806C10.1827 38.6113 11.2173 41.1084 12.7509 43.2954C14.2844 45.4825 16.2794 47.3061 18.595 48.6376C20.9106 49.9691 23.4903 50.776 26.1519 51.0012C28.8135 51.2265 31.4921 50.8646 33.9985 49.9411L34.6984 51.8408Z"/>
            </mask>
            <path @path d="M34.6984 51.8408C31.9139 52.8667 28.9381 53.2687 25.9812 53.0185C23.0243 52.7682 20.1584 51.8719 17.5859 50.3926C15.0133 48.9134 12.797 46.8874 11.0933 44.4577C9.38961 42.028 8.24015 39.2539 7.7261 36.3313C7.21205 33.4086 7.34595 30.4088 8.11834 27.5436C8.89074 24.6784 10.2828 22.0177 12.1962 19.7495C14.1095 17.4812 16.4976 15.6607 19.1917 14.4166C21.8858 13.1724 24.8201 12.5349 27.7876 12.5492L27.7779 14.5736C25.1068 14.5608 22.4655 15.1346 20.0405 16.2545C17.6154 17.3744 15.4659 19.0131 13.7436 21.0548C12.0213 23.0965 10.7683 25.4915 10.073 28.0705C9.37776 30.6496 9.25723 33.3498 9.71994 35.9806C10.1827 38.6113 11.2173 41.1084 12.7509 43.2954C14.2844 45.4825 16.2794 47.3061 18.595 48.6376C20.9106 49.9691 23.4903 50.776 26.1519 51.0012C28.8135 51.2265 31.4921 50.8646 33.9985 49.9411L34.6984 51.8408Z"
                  fill="#272727"
            />
            <path @path d="M34.6984 51.8408C31.9139 52.8667 28.9381 53.2687 25.9812 53.0185C23.0243 52.7682 20.1584 51.8719 17.5859 50.3926C15.0133 48.9134 12.797 46.8874 11.0933 44.4577C9.38961 42.028 8.24015 39.2539 7.7261 36.3313C7.21205 33.4086 7.34595 30.4088 8.11834 27.5436C8.89074 24.6784 10.2828 22.0177 12.1962 19.7495C14.1095 17.4812 16.4976 15.6607 19.1917 14.4166C21.8858 13.1724 24.8201 12.5349 27.7876 12.5492L27.7779 14.5736C25.1068 14.5608 22.4655 15.1346 20.0405 16.2545C17.6154 17.3744 15.4659 19.0131 13.7436 21.0548C12.0213 23.0965 10.7683 25.4915 10.073 28.0705C9.37776 30.6496 9.25723 33.3498 9.71994 35.9806C10.1827 38.6113 11.2173 41.1084 12.7509 43.2954C14.2844 45.4825 16.2794 47.3061 18.595 48.6376C20.9106 49.9691 23.4903 50.776 26.1519 51.0012C28.8135 51.2265 31.4921 50.8646 33.9985 49.9411L34.6984 51.8408Z"
                  stroke="#272727"
                  stroke-width="1.2"
                  mask="url(#path-6-outside-4_85_13)"
            />
            <path @path d="M33.6788 42.3616L39.9654 50.8531L39.9654 33.3277L29.83 33.3277" stroke="#272727" stroke-width="2.8"/>
        </svg>
    `,
    animations: [
        trigger('path', [
            // transition(':enter', [
            //     style({'stroke-dashoffset': '122'}),
            //     animate(500, style({'stroke-dashoffset': '0'})),
            // ])
            // transition(':enter', [
            //     style({'stroke-dashoffset': '743', fill: '#ffffff'}),
            //     animate(5000, style({'stroke-dashoffset': '370', fill: 'green'})),
            // ])
            // transition(':enter', [
            //     style({'stroke-dashoffset': '122', fill: '#ffffff'}),
            //     animate(5000, style({'stroke-dashoffset': '0', fill: 'green'})),
            // ])
        ]),
    ]
})

export class LogoSvgComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
