<div class="modal-header">
    <div class="d-flex justify-content-start">
        <ng-content select=".header"></ng-content>
        <h4 class="modal-title pull-left">{{title}}</h4>
    </div>
    <div>

        <ng-content select=".custom-html"></ng-content>

        <button type="button" class="btn-close float-end" aria-label="Close" (click)="close()">
            <span aria-hidden="true"></span>
        </button>

    </div>
</div>
<ng-content></ng-content>

