import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AuthService, PasswordChangeUrl} from 'app/services/auth.service';
import {ErrorInterceptor} from 'app/services/interceptors/error.interceptor';
import {PermissionsService} from 'app/services/permissions.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {finalize, take, tap} from 'rxjs/operators';

const SecurePasswordValidator = {
    expression: ({value}: UntypedFormControl) => {
        const latinLetters = 'a-z';
        const numbers = '0-9';
        const specialSymbols = '!"#$%&\'()*+,-./:;<=>?@^_`{}~';
        const test = (regexPart: string) => new RegExp(`[${regexPart}]+`).test(value);
        return value.length >= 6 && test(latinLetters) && test(numbers) && test(specialSymbols);
    },
    message: () => 'Пароль должен состоять минимум из 6 символов. Содержать цифры, буквы и спец. символы'
};

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent {

    @ViewChild('codeInputWrapper', {read: ElementRef}) codeInputWrapper: ElementRef<HTMLElement>;
    @ViewChild('oneTimeCodeModal', {read: TemplateRef}) oneTimeCodeModal: TemplateRef<any>;

    form = this.fb.group({
        login: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
        // sms_code: new FormControl('', Validators.required),
    });
    sms_code = '123456';
    // tslint:disable-next-line:prefer-optional
    error: string | undefined;

    constructor(
        private auth: AuthService,
        private permissions: PermissionsService,
        private fb: UntypedFormBuilder,
        // private modalService: ModalService,
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        public ref: BsModalRef,
        private bs: BsModalService
    ) {
        AuthService.removeToken();
        this.permissions.removePermissions();

        if (route.snapshot.url.slice(-1)[0].path === PasswordChangeUrl) {
            this.openChangePasswordModal();
        }
    }

    login() {
        this.error = undefined;
        if (this.form.valid) {
            ErrorInterceptor.skipError(1);
            const {login, password} = this.form.value;
            this.auth.requestOneTimeCode(login, password).subscribe(
                () => {
                    this.continueLogin();
                },
                err => {
                    this.error = err.error.error_message;
                }
            );
        } else {
            this.form.statusChanges.pipe(
                take(1),
                tap(() => this.error = ''),
            ).subscribe();
            this.error = 'Все поля обязательны для заполнения';
        }
    }

    continueLogin() {
        const {login, password} = this.form.value;
        this.auth.login(login, password, this.sms_code).pipe(
            finalize(() => this.ref.hide())
        ).subscribe(
            () => {

            },
            err => {
                this.error = err.error.error_message;
            }
        );
    }

    openNewPasswordModal() {

    }

    openChangePasswordModal() {
        const token = this.route.snapshot.queryParamMap.get('token');
    }
}
