import {Component, ElementRef, HostBinding, HostListener, ViewChild} from '@angular/core';
import {OWL_DATE_TIME_FORMATS} from '@danielmoncada/angular-datetime-picker';
import {FieldType} from '@ngx-formly/core';
import {DateView, DateViewType} from 'shared/types';

const formatForMonthDayPicker = {
    parseInput: null,
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
    datePickerInput: {month: 'long', day: 'numeric'},
    timePickerInput: {hour: 'numeric', minute: 'numeric'},
    monthYearLabel: {month: 'long', day: 'numeric'},
    dateA11yLabel: {month: 'long', day: 'numeric'},
    monthYearA11yLabel: {month: 'long', day: 'numeric'},
};

/*tslint:disable component-class-suffix component-selector*/
@Component({
    selector: 'field-monthday',
    providers: [{provide: OWL_DATE_TIME_FORMATS, useValue: formatForMonthDayPicker}],
    template: `
        <!--suppress TypeScriptValidateTypes -->
        <input class="form-control"
               #inputElement
               autocomplete="off"
               readonly
               [class.is-invalid]="showError"
               [formlyAttributes]="field"
               [formControl]="$any(formControl)"
               [owlDateTimeTrigger]="dt"
               [owlDateTime]="dt"
        />
        <owl-date-time #dt [pickerType]="'calendar'"
                       [startView]="startView"
                       panelClass="monthday-picker"
        ></owl-date-time>
    `,
    styles: [
        `
            .form-control {
                background: #fff;
            }

            ::ng-deep .monthday-picker owl-date-time-calendar[data-view="year"] .owl-dt-calendar-control {
                display: none;
            }

            ::ng-deep .owl-dt-weekdays {
                display: none;
            }

            ::ng-deep .monthday-picker owl-date-time-calendar[data-view="month"] .owl-dt-calendar-control-content {
                pointer-events: none;
            }

            ::ng-deep .monthday-picker owl-date-time-calendar[data-view="month"] .owl-dt-control-button-arrow {
                display: none;
            }
        `
    ],
})
export class MonthDayField extends FieldType {
    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
    declare to: any;

    @HostBinding('class.cursor-pointer')
    cursorPointer = true;

    startView: DateViewType = DateView.YEAR;

    @HostListener('click')
    onClick() {
        this.inputElement.nativeElement.click();
    }
}
