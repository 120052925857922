import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from 'app/layout/main/main-layout.component';
import {LoadPermissionsGuard} from 'app/services/load-permissions.guard';
import {RouteGuard} from 'app/services/route.guard';
import {makeEnum} from 'shared/utils/make-enum';
import {LoginComponent} from './pages/login/login.component';
import {LoginUrl} from './services/auth.service';


export const RootRoutes = makeEnum(
    'dashboard',
    'wage',
    'overhead-expenses',
    'projects',
    'positions',
    'handbooks',
    'admin',
    'competition',
    'settings',
    'notification'
);


const routes: Routes = [
    {path: LoginUrl, component: LoginComponent},

    {
        path: '', component: MainLayoutComponent,
        canActivate: [LoadPermissionsGuard],
        canActivateChild: [RouteGuard],
        children: [
            {path: '', pathMatch: 'full', redirectTo: `${RootRoutes.wage}/fund/list`},
            {
                path: RootRoutes.dashboard,
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: RootRoutes.wage,
                loadChildren: () => import('app/modules/wage/wage.module').then(m => m.WageModule)
            },
            {
                path: RootRoutes['overhead-expenses'],
                loadChildren: () => import('app/modules/overhead-expenses/overhead-expenses.module').then(m => m.OverheadExpensesModule)
            },
            {
                path: RootRoutes.projects,
                loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
            },
            {
                path: RootRoutes.admin,
                loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: RootRoutes.positions,
                loadChildren: () => import('./modules/positions/positions.module').then(m => m.PositionsModule)
            },
            {
                path: RootRoutes.handbooks,
                loadChildren: () => import('./modules/handbook/handbook.module').then(m => m.HandbookModule)
            },
            {
                path: RootRoutes.competition,
                loadChildren: () => import('./modules/competition/competition.module').then(m => m.CompetitionModule)
            },
            {
                path: RootRoutes.settings,
                loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: RootRoutes.notification,
                loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
