import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OverheadExpensesAttributeTitle, OverheadExpensesTotalAttribute} from 'app/modules/overhead-expenses/interfaces';
import {WageAttributeTitle, WageTotals} from 'app/modules/wage/shared/wage.interfaces';
import {BindObservable} from 'bind-observable';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';

export interface UserInfo {
    id: number;
    private_person_id?: number;
    login: string;
    name: string;
    email?: string;
    mobile_phone?: string;
    photo_file_url?: string;
    root: boolean;
}

export interface EmployeeInfo {
    agency_id: number;
    company_id: number;
    department_id: number;
    position_id: number;
    head_private_person_id: number;
    head_private_person_user_id: number;
    head_private_person_display_name: string;
    functional_head_private_person_id: number;
}

export interface CompetitionsInfo {
    wage_budget: {
        sent: number,
        recieved: number
    };
    overhead_expenses: {
        sent: number,
        recieved: number
    };
}

export type UserSettings = {
    WageColumns: { [key in WageAttributeTitle]?: boolean },
    WageTotals: { [key in keyof WageTotals]: boolean },
    OverheadExpensesColumns: { [key in OverheadExpensesAttributeTitle]?: boolean },
    OverheadExpensesTotals: { [key in OverheadExpensesTotalAttribute]?: boolean },
    PrivatePersonWageTotals: { [key in keyof WageTotals]: boolean },
    StartingPage: string,
};

@Injectable()
export class DashboardService {

    @BindObservable()
    userInfo: UserInfo;
    userInfo$!: Observable<UserInfo>;

    @BindObservable()
    userSettings: UserSettings;
    userSettings$!: Observable<UserSettings>;

    @BindObservable()
    employeeInfo: EmployeeInfo;
    employeeInfo$!: Observable<EmployeeInfo>;

    @BindObservable()
    competitions: CompetitionsInfo;
    competitions$!: Observable<CompetitionsInfo>;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService
    ) {
    }

    saveUserSettings(settings: Partial<UserSettings>) {
        const fresSettings = {...this.userSettings, ...settings};
        this.http.put(`/api/user/settings`, {settings: fresSettings}).subscribe(() => {
            this.toastr.info('Настройки сохранены');
            this.userSettings = fresSettings;
        });
    }

    flushAllCache() {
        this.http.get(`/api/cache/flush`).subscribe(() => {
            this.toastr.info('Кэш очищен');
            location.reload();
        });
    }
}
