import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {first, map, shareReplay, tap} from 'rxjs/operators';
import {PermissionsService} from "app/services/permissions.service";
import {MENU_ITEMS} from "app/modules/navigation/sidebar/sidebar.service";
import {MenuItem} from "app/modules/navigation/sidebar/sidebar-menu-item.component";
import _ from "lodash";

@Injectable({providedIn: 'root'})
export class RouteGuard implements CanActivateChild {

    menuPermissions$: Observable<{ [key: string]: boolean }>;

    constructor(
        private permissions: PermissionsService,
        private router: Router,
        @Inject(MENU_ITEMS) menuItems$: Observable<MenuItem[]>) {
        this.menuPermissions$ = menuItems$.pipe(
            map(data =>
                _.flatMap(data, item => [item, ...(item.children ? item.children : [])])
                    .filter(item => item.hasOwnProperty('permission'))
                    .reduce((start, next) => ({...start, ...{[next.route]: this.permissions.getPermissionValue(next.permission)}}), {}),
            ),
            shareReplay(1),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const currentURL = state.url.split('?').splice(0, 1)[0];
        return this.menuPermissions$.pipe(
            first(),
            map(p => !p.hasOwnProperty(currentURL) || p[currentURL]),
            tap(val => {
                if (!val) {
                    this.router.navigateByUrl('/');
                }
            }),
        );
    }
}
