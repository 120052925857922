import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToggleComponent} from "shared/modules/forms/toggle/toggle.component";

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [ToggleComponent],
    exports: [ToggleComponent],
})
export class ToggleModule {
}
