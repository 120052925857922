import {CurrencyPipe} from '@angular/common';
import {Inject, Pipe, PipeTransform} from '@angular/core';
import {MONEY_COEFF} from 'app/app.module';
import {roundTo} from 'shared/utils/utils';

@Pipe({
    name: 'money'

})
export class MoneyPipe implements PipeTransform {

    private postfix = {
        bil: {label: 'млрд', coef: 1000000000},
        mln: {label: 'млн', coef: 1000000},
        th: {label: 'тыс', coef: 1000}
    }

    constructor(@Inject(MONEY_COEFF) private coeff, private currencyPipe: CurrencyPipe) {
    }

    transform(value: number, digits: number = 0, reduced: boolean = false, symbol: boolean = false): string {

        const realValue = value / this.coeff;

        if (reduced) {
            let reducedValue: number;
            let postfix: string = '';

            if (Math.abs(reducedValue = roundTo(realValue / this.postfix.bil.coef, 2)) >= 1) {
                postfix = this.postfix.bil.label;
            }

            if (postfix === '' && Math.abs(reducedValue = roundTo(realValue / this.postfix.mln.coef, 2)) >= 1) {
                postfix = this.postfix.mln.label;
            }

            if (postfix === '' && Math.abs(reducedValue = roundTo(realValue / this.postfix.th.coef, 2)) >= 1) {
                postfix = this.postfix.th.label;
            }
            return `${this._transform(reducedValue, digits)} ${postfix}`;
        }

        return this._transform(value/this.coeff, digits, symbol)
    }

    private _transform(money: number, digits: number, symbol: boolean = false) {
        return this.currencyPipe.transform(money, 'RUB', symbol === false ? '' : '₽', `1.${digits}-${digits}`, 'ru')
    }
}
