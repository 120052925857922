<div class="waveWrapper waveAnimation">
  <div class="waveWrapperInner bgTop">
    <div class="wave waveTop" style="background-image: url('/assets/img/wave-top.png')"></div>
  </div>
  <div class="waveWrapperInner bgMiddle">
    <div class="wave waveMiddle"
         style="background-image: url('/assets/img/wave-mid.png')"></div>
  </div>
  <div class="waveWrapperInner bgBottom">
    <div class="wave waveBottom"
         style="background-image: url('/assets/img/wave-bot.png')"></div>
  </div>
</div>

<div class="position-relative w-100 vh-100 d-flex justify-content-center align-items-center">
  <div class="position-absolute" style="z-index: 20">
    <div class="login-container">
      <div class="login-content card">
        <div class="p-4 mb-3">
          <h1 class="pb-4 mt-0" style="display: flex;justify-content: center">
<!--            <span style="color: #4a4c64">mi </span>-->
<!--            <span style="color: #13294b">АФИНА</span>-->
<!--              <img -->
<!--                      src="/assets/logo/Logo_color.svg" -->
<!--                      alt="image description" -->
<!--                      class="img-fluid" -->
<!--                      style="height: 8rem"> -->
            <app-logo-svg ></app-logo-svg>

          </h1>
          <form [formGroup]="form">
            <div class="input-wrapper">
              <i class="fa fa-user"></i>
              <input id="login"
                     type="text"
                     class="form-control"
                     formControlName="login"
                     placeholder="E-mail"
              />
            </div>
            <div class="input-wrapper">
              <i class="fa fa-lock"></i>
              <input id="password"
                     type="password"
                     class="form-control"
                     formControlName="password"
                     placeholder="Пароль"
              />
            </div>
            <!-- <div class="mb-3 d-flex justify-content-between"> -->
            <!--   <a href="javascript:void(0)" (click)="openNewPasswordModal()"><u>Забыли пароль?</u></a> -->
            <!--   <a href="javascript:void(0)" (click)="openChangePasswordModal()"><u>Ввести код из пиcьма</u></a> -->
            <!-- </div> -->
            <ng-container *ngIf="error">
              <div class="alert alert-danger">
                {{ error }}
              </div>
            </ng-container>
            <button type="button" (disablingClick)="login()" class="login-btn btn-flat" ngDisable>Войти</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #oneTimeCodeModal>
  <app-modal [modalRef]="ref" [title]="'Введите одноразовый код'">
    <div class="login-container p-3">
      <div class="login-content">
        <form>
          <div class="input-wrapper">
            <input id="sms_code"
                   type="text"
                   class="form-control"
                   [(ngModel)]="sms_code"
                   [ngModelOptions]="{standalone: true}"
                   placeholder="Код из смс"
            />
          </div>
          <button type="button" ngDisable (disablingClick)="continueLogin()" class="login-btn btn-flat mt-3">Продолжить</button>
        </form>
      </div>

    </div>
  </app-modal>
</ng-template>
