import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ConfigViewerModule} from 'shared/base/config-viewer/config-viewer.module';
import {NgLetDirectiveModule} from 'shared/directives/ng-let/ng-let-directive.module';
import {ColorizationService, IColorizationInterface} from 'shared/modules/table/colorization/colorization.service';
import {DefaultCellModule} from 'shared/modules/table/custom-cells/default-cell/default-cell.module';
import {MapperCell} from 'shared/modules/table/custom-cells/mapper-cell/mapper-cell';
import {MoneyCell} from 'shared/modules/table/custom-cells/money-cell/money-cell';
import {OverflowCell} from 'shared/modules/table/custom-cells/overflow-cell/overflow-cell';
import {PeriodCell} from 'shared/modules/table/custom-cells/period-cell/period-cell';
import {ProgressCell} from 'shared/modules/table/custom-cells/progress-cell/progress-cell';
import {FooterWrapperComponent} from 'shared/modules/table/footer-wrapper/footer-wrapper.component';
import {HeaderWrapperComponent} from 'shared/modules/table/header-wrapper/header-wrapper.component';
import {PaginatorModule} from 'shared/modules/table/paginator/paginator.module';
import {MoneyPipeModule} from 'shared/pipes/money/money-pipe.module';
import {BooleanCell} from './custom-cells/boolean-cell/boolean-cell';
import {DateCell} from './custom-cells/date-cell/date-cell';
import {FilterCell} from './custom-cells/filter-cell/filter-cell.component';
import {ColumnHeading} from './directives/column-heading.directive';
import {Footer} from './directives/footer.directive';
import {PaginationWrapperComponent} from './pagination-wrapper/pagination-wrapper.component';
import {SorterComponent} from './sorter/sorter.component';
import {TableComponent} from './table/table.component';
import {LinkCell} from 'shared/modules/table/custom-cells/link-cell/link-cell';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ConfigViewerModule,
        DefaultCellModule,
        PaginatorModule,
        ProgressbarModule.forRoot(),
        TooltipModule,
        MoneyPipeModule,
        NgLetDirectiveModule,
    ],
    declarations: [
        TableComponent,
        SorterComponent,
        ColumnHeading,
        Footer,
        PaginationWrapperComponent,
        BooleanCell,
        DateCell,
        FilterCell,
        MapperCell,
        PeriodCell,
        ProgressCell,
        OverflowCell,
        FooterWrapperComponent,
        HeaderWrapperComponent,
        MoneyCell,
        LinkCell
    ],
    exports: [
        TableComponent,
        ColumnHeading,
        Footer,
        ConfigViewerModule,
        PaginationWrapperComponent,
        FooterWrapperComponent,
        HeaderWrapperComponent,
    ],
    providers: [{ provide: IColorizationInterface, useClass: ColorizationService }]
})
export class TableModule { }
