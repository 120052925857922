import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ExpandedService {

    private _expanded$: { [key: string]: BehaviorSubject<boolean> } = {
        'expense_per_month_for_accounting': new BehaviorSubject(true)
    };
    private _quarterExpanded$: { [key: string]: BehaviorSubject<boolean> } = {};
    private _formulaOpen$: { [key: string]: BehaviorSubject<boolean> } = {};

    constructor() {

    }

    isExpanded$(columnTitle: string): BehaviorSubject<boolean> {
        if (!!this._expanded$[columnTitle] === false) {
            this._expanded$[columnTitle] = new BehaviorSubject<boolean>(false);
        }

        return this._expanded$[columnTitle];
    }

    toggleExpandedState(columnTitle: string) {
        const newValue = !this._expanded$[columnTitle].getValue();
        this._expanded$[columnTitle].next(newValue);
    }

    isQuarterExpanded$(columnTitle: string): BehaviorSubject<boolean> {
        if (!!this._quarterExpanded$[columnTitle] === false) {
            this._quarterExpanded$[columnTitle] = new BehaviorSubject<boolean>(false);
        }

        return this._quarterExpanded$[columnTitle];
    }

    toggleQuarterExpandedState(columnTitle: string) {
        const newValue = !this._quarterExpanded$[columnTitle].getValue();
        this._quarterExpanded$[columnTitle].next(newValue);
    }

    formulaOpen$(columnTitle: string): BehaviorSubject<boolean> {
        if (!!this._formulaOpen$[columnTitle] === false) {
            this._formulaOpen$[columnTitle] = new BehaviorSubject<boolean>(false);
        }

        return this._formulaOpen$[columnTitle];
    }

    toggleFormulaOpen(columnTitle: string) {
        const newValue = !this._formulaOpen$[columnTitle].getValue();
        this._formulaOpen$[columnTitle].next(newValue);
    }
}
