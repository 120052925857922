/* tslint:disable */
import {Component} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {isObservable, Observable} from 'rxjs';

@Component({
  selector: 'formly-field-multicheckbox',
  template: `
      <div class="custom-control custom-checkbox" *ngFor="let option of (isObservable(to.options) ? (to.options | async) : to.options); let i = index;">
          <input class="custom-control-input" type="checkbox"
                 [id]="id + '_' + i"
                 [value]="option.value"
                 [formControl]="$any(formControl.get(option.key))"
                 [formlyAttributes]="field">
          <label class="custom-control-label" [for]="id + '_' + i">
              {{ option.value }}
          </label>
      </div>
  `,
})
export class FormlyFieldMultiCheckbox extends FieldType {
  isObservable = isObservable;
  static createControl(model: any, field: FormlyFieldConfig): AbstractControl {
    if (field.templateOptions.options instanceof Observable) {
      throw new Error(`[Formly Error] You cannot pass an Observable to a multicheckbox yet.`);
    }

    const controls = field.templateOptions.options.reduce((obj, option) => {
      obj[option.key] = new UntypedFormControl(model ? model[option.key] : undefined);
      return obj;
    }, {});

    return new UntypedFormGroup(
      controls,
      field.validators ? field.validators.validation : undefined,
      field.asyncValidators ? field.asyncValidators.validation : undefined,
    );
  }

}
