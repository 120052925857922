import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {OwlDateTime} from "@danielmoncada/angular-datetime-picker/lib/date-time/date-time.class";
import {addYears} from 'date-fns';
import {BaseValueAccessor} from 'shared/modules/forms/base-value-accessor.class';
import {DateView} from "shared/types";

@Component({
    selector: 'app-year-picker',
    styles: [
        `
          .calendar {
            position: absolute;
            right: 296px;
          }
        `
    ],
    template: `
        <div class="d-flex align-items-center justify-content-between h-100 position-relative">
            <span class="pl-2 change-month-button" *ngIf="value" (click)="prevYear()"><i class="fa fa-chevron-left icon-button"></i></span>
            <span [owlDateTimeTrigger]="dt2" class="trigger">{{ (value | date : dateFormat) || '&nbsp;' }}</span>
            <span class="pr-2 change-month-button" *ngIf="value" (click)="nextYear()"><i
                    class="fa fa-chevron-right icon-button"></i></span>
        </div>
        <div class="calendar">
            <owl-date-time #dt2 [pickerType]="'calendar'" [startView]="startView" (yearSelected)="pickYear($event, dt2)"
                           panelClass="month-picker"></owl-date-time>
            <input [owlDateTime]="dt2" class="invisible disabled w-100 position-absolute" [owlDateTimeTrigger]="dt2"
                   style="max-height: 0; bottom: 0" [formControl]="$any(formControl)">
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YearPickerComponent),
            multi: true,
        },
    ],
})

export class YearPickerComponent extends BaseValueAccessor implements OnInit, OnDestroy {
    dateFormat = 'yyyy';
    startView = DateView.MULTI_YEARS;

    constructor() {
        super();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
    }

    prevYear() {
        this.value = addYears(this.value, -1);
    }

    nextYear() {
        this.value = addYears(this.value, 1);
    }


    pickYear(d: Date, picker: OwlDateTime<Date>) {
        picker.select(d);
    }
}