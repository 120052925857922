import {Injectable} from '@angular/core';
import {fromEvent, merge, Subject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    public beforeInactivityTimeout$: Subject<boolean> = new Subject();
    public afterInactivityTimeout$: Subject<boolean> = new Subject();
    public onDeactivate$: Subject<boolean> = new Subject();
    private inactivityTime = 10 * 60 * 1000;
    private timeout;
    constructor() {
    }

    init() {
        this.setTimeout();
        merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'keydown'),
            fromEvent(window, 'resize'),
        ).pipe(
            takeUntil(this.onDeactivate$),
        ).subscribe(() => {
            clearTimeout(this.timeout);
            this.setTimeout();
        });

        this.beforeInactivityTimeout$.pipe(delay(5 * 1000)).subscribe(() => this.afterInactivityTimeout$.next(true));
    }

    setTimeout(): void {
        this.timeout = setTimeout(() => this.beforeInactivityTimeout$.next(true), this.inactivityTime);
    }
}
