<app-modal [title]="title" [modalRef]="modalRef" [size]="'lg'">

    <span class="custom-html" *ngIf="customHtml" [innerHTML]="customHtml"></span>

    <div class="modal-body" *ngIf="form">
        <form [formGroup]="$any(form)">
            <tabset>
                <tab *ngFor="let tab of fields; let i = index" [heading]="tab.label">
                    <formly-form *ngIf="tab.form"
                                 [model]="model"
                                 [fields]="tab.form"
                                 [form]="$any(form.at(i))"
                                 [class]="formlyClass"
                    ></formly-form>
                    <ng-container *ngIf="tab.template">
                        <ng-template [ngTemplateOutlet]="tab.template"
                                     [ngTemplateOutletContext]="{ $implicit: model }"
                        ></ng-template>
                    </ng-container>
                </tab>
            </tabset>
            <ng-container *ngIf="(err$ | async) as err">
                <hr>
                <div [innerHTML]="err" class="alert-danger p-3"></div>
            </ng-container>
            <hr [class]=" buttonTitle ? 'mt-0' : 'm-0'">
            <button *ngIf="buttonTitle"
                    type="button"
                    class="btn btn-lg btn-primary w-100"
                    ngDisable
                    (disablingClick)="submit.next(model)"
                    [disabled]="!form.valid">
                {{ buttonTitle }}
            </button>
        </form>
    </div>
</app-modal>
