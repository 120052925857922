/*tslint:disable */
import {HttpClient, HttpResponse} from '@angular/common/http';
import {UntypedFormControl} from '@angular/forms';
import {ErrorInterceptor} from 'app/services/interceptors/error.interceptor';
import _, {isNumber, isObject, isString, trim} from 'lodash';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {PeriodInterface} from 'shared/components/calendar/calendar.component';
import {Keyable} from 'shared/types';

export class Utils {
    static dateToBackend(date) {
        if (typeof date === 'string') {
            date = new Date(Date.parse(date));
        }
        const dateCopy = new Date(date.getTime());
        dateCopy.setHours(0, 0, 0, 0);
        dateCopy.setHours(-dateCopy.getTimezoneOffset() / 60);
        return dateCopy.toISOString().split('T')[0];
    }

    static timeToBackend(date) {
        const dateCopy = new Date(date.getTime());
        return dateCopy.toISOString().split('T')[1].split(':').slice(0, 2).join(':');
    }

    static isToday(date): boolean {
        return this.dateToBackend(Utils.now) === this.dateToBackend(date);
    }

    static get now() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    }

    static currentOrNextYear() {
        const today = new Date();
        return today.getMonth() === 11 ? today.getFullYear() + 1 : today.getFullYear();
    }

    static fromToday(n: number): Date {
        const date = Utils.now;
        date.setDate(date.getDate() + n);
        return date;
    }

    public static monthsOfYear(year: number) {
        // return Array.from({ length }, (_, i) => start + i)
        //     .map(num => {});

        return Array.from({length: 12}, (v, k) => k).map(monthNum => {
            const month = new Date();
            month.setFullYear(year, monthNum, 1);
            if (monthNum === 11) {
                month.setDate(31);
            }
            return month;
        });

        // const months = [];
        // for (let i = 0; i <= 11; i++) {
        //     const month = new Date;
        //     month.setFullYear(year, i, 1);
        //
        //     if (i === 11) {
        //         month.setDate(31);
        //     }
        //
        //     months.push(month);
        // }
        // return months;
    }

    public static deepCopy<T>(source: T): T {
        return Array.isArray(source)
            ? source.map(item => this.deepCopy(item))
            : source instanceof Date
                ? new Date(source.getTime())
                : source && typeof source === 'object'
                    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
                        o[prop] = this.deepCopy(source[prop]);
                        return o;
                    }, Object.create(Object.getPrototypeOf(source)))
                    : (source as T);
    }
}

export function groupBy<T>(array: T[], key): { [key: string | number]: T[] } {
    return array.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function sumAdvanced<T1, T2>(item: T1, sumItem: T2, field: string, withStrings = false): T2 {
    if (isObject(item[field])) {
        if (!!sumItem[field] === false) {
            sumItem[field] = {};
        }
        for (const fieldKey in item[field]) {
            if (!!sumItem[field][fieldKey] === false) {
                sumItem[field][fieldKey] = 0;
            }
            if (isNumber(item[field][fieldKey])) {
                sumItem[field][fieldKey] += item[field][fieldKey];
            }
        }
    } else if (isNumber(item[field])) {
        if (!!sumItem[field] === false) {
            sumItem[field] = 0;
        }
        sumItem[field] += item[field];
    } else if (isString(item[field]) && withStrings) {
        if (!!sumItem[field] === false || item[field].match(/\d\d\d\d/)) {
            sumItem[field] = item[field];
        } else {
            if (item[field].trim() !== '') {
                const str = sumItem[field].split(', ');
                str.push(item[field]);
                sumItem[field] = _.uniq(str).join(', ');
            }
        }
    }

    return sumItem;
}

export function sum(value: any) {
    if (!value) {
        return 0;
    }

    return _.sum(Object.values(value).filter(v => typeof v === 'number'));
}

export function safeDivide(x: number, y: number): number {
    if (x === 0 && y === 0) {
        return 0; //prevent NaN
    }
    return x / y;
}

let uid = 0;
export const uniqueid = () => ++uid;

export const getRandomInt = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
};

export const serializeGet = function(obj) {
    const str = [];
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            const value = obj[prop];
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    prop = prop + '[]';
                    value.forEach(value => str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`));
                }
            } else if (typeof value === 'boolean') {
                str.push(`${encodeURIComponent(prop)}=${value === true ? '1' : '0'}`);
            } else if (value) {
                str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`);
            }
        }
    }
    return str.join('&');
};

export const thisYear = () => {
    const from = new Date();
    const to = new Date();
    from.setMonth(0, 1);
    to.setMonth(11, 31);
    return [from, to];
};
export const thisMonth = (max: Date = null): PeriodInterface => {
    let from;
    let to;
    from = new Date();
    to = new Date();
    if (max && from.getMonth() >= max.getMonth()) {
        from = new Date(max);
        to = new Date(max);
    }
    from.setMonth(from.getMonth(), 1);
    to.setMonth(from.getMonth() + 1, 0);
    [from, to].forEach(d => d.setHours(0, 0, 0, 0));
    return [from, to];
};

export const removeDuplicates = <T>(array: T[]): T[] => Array.from(new Set(array));
export const mergeArrays = <T>(array: T[][]): T[] => [].concat(...array);
export const mergeAndRemoveDuplicates = <T>(array: T[][]): T[] => removeDuplicates(mergeArrays(array));

export const arrayIsNullOrEmpty = arr => arr === null || arr === undefined || arr.length === 0;
export const arrayHaveLength = (arr, n: number) => !(arr === null || arr === undefined) && arr.length === n;

export const deleteProviderValue = (v: Function, inModule, NOT_YET) => {
    const diMap: Map<any, any> = inModule.records;
    diMap.get(v).value = NOT_YET;
};

export function saveAs(blobOrString: Blob, filename: string);
export function saveAs(blobOrString: string, filename?: string);
export function saveAs(blobOrString: Blob | string, filename?: string) {
    const link = document.createElement('a');

    let url: string;
    if (typeof blobOrString === 'string') {
        url = blobOrString;
    } else {
        url = URL.createObjectURL(blobOrString);
    }

    link.setAttribute('href', url);
    if (filename) {
        link.setAttribute('download', filename);
    }
    document.body.appendChild(link); // Required for FF

    link.click();
    requestAnimationFrame(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    });
}

// tslint:disable max-line-length
export function listToObject<T, K extends Keyable>(l: T[], keyCb: (e: T, i?: number) => K): { [key in K]: T };
export function listToObject<T, R, K extends Keyable>(
    l: T[],
    keyCb: (e: T, i?: number) => K,
    transformCb: (e: T, i?: number) => R,
): { [key in K]: R };
export function listToObject<T, R, K extends Keyable>(
    l: T[],
    keyCb: (e: T, i?: number) => K,
    transformCb?: (e: T, i?: number) => R,
): { [key in K]: R } | { [key in K]: T } {
    const result: any = {};
    if (transformCb) {
        l.forEach((v, i) => {
            const key = keyCb(v, i);
            result[key] = transformCb(v, i);
        });
    } else {
        l.forEach((v, i) => {
            const key = keyCb(v, i);
            result[key] = v;
        });
    }
    return result;
}

export function textToClipboard(text: string) {
    const area = document.createElement('textarea');
    area.style.position = 'fixed';
    area.value = text;
    document.body.append(area);
    area.select();
    document.execCommand('copy');
    area.remove();
}

export function isElement(n: Node): n is Element {
    return !!n['tagName'];
}

const defaultGetFileOptions = {
    accept: 'image/*',
    multiple: false,
};

export function getFile(cb: (f: FileList) => void, options = defaultGetFileOptions): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = options.accept;
    input.multiple = options.multiple;
    input.style.display = 'none';
    document.body.appendChild(input);

    input.addEventListener('change', () => {
        cb(input.files);
        input.remove();
    });
    input.click();
}

export const nextAnimationFrame = () => new Promise(requestAnimationFrame);

export const tuple = <T extends [void] | {}>(val: T): T => val;

export const formControlValue$ = <T = any>(f: UntypedFormControl) => f.valueChanges.pipe(startWith(f.value)) as Observable<T>;

export function toggleElementInArray<T>(e: T, a: T[], x: (ee: T) => number): T[] {
    const index = x(e);
    if (index === -1) {
        a.push(e);
    } else {
        a.splice(index, 1);
    }
    return a;
}

export let md5 = string => {
    function RotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function AddUnsigned(lX, lY) {
        let lX4, lY4, lX8, lY8, lResult;
        lX8 = lX & 0x80000000;
        lY8 = lY & 0x80000000;
        lX4 = lX & 0x40000000;
        lY4 = lY & 0x40000000;
        lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
        if (lX4 & lY4) {
            return lResult ^ 0x80000000 ^ lX8 ^ lY8;
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
            } else {
                return lResult ^ 0x40000000 ^ lX8 ^ lY8;
            }
        } else {
            return lResult ^ lX8 ^ lY8;
        }
    }

    function F(x, y, z) {
        return (x & y) | (~x & z);
    }

    function G(x, y, z) {
        return (x & z) | (y & ~z);
    }

    function H(x, y, z) {
        return x ^ y ^ z;
    }

    function I(x, y, z) {
        return y ^ (x | ~z);
    }

    function FF(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function GG(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function HH(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function II(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function ConvertToWordArray(string) {
        let lWordCount;
        let lMessageLength = string.length;
        let lNumberOfWords_temp1 = lMessageLength + 8;
        let lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        let lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        let lWordArray = Array(lNumberOfWords - 1);
        let lBytePosition = 0;
        let lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition);
            lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    }

    function WordToHex(lValue) {
        let WordToHexValue = '',
            WordToHexValue_temp = '',
            lByte,
            lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            WordToHexValue_temp = '0' + lByte.toString(16);
            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
        }
        return WordToHexValue;
    }

    function Utf8Encode(string) {
        string = string.replace(/\r\n/g, '\n');
        let utftext = '';

        for (let n = 0; n < string.length; n++) {
            let c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }

        return utftext;
    }

    let x = Array();
    let k, AA, BB, CC, DD, a, b, c, d;
    let S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22;
    let S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20;
    let S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23;
    let S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301;
    b = 0xefcdab89;
    c = 0x98badcfe;
    d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = FF(a, b, c, d, x[k + 0], S11, 0xd76aa478);
        d = FF(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
        c = FF(c, d, a, b, x[k + 2], S13, 0x242070db);
        b = FF(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
        a = FF(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
        d = FF(d, a, b, c, x[k + 5], S12, 0x4787c62a);
        c = FF(c, d, a, b, x[k + 6], S13, 0xa8304613);
        b = FF(b, c, d, a, x[k + 7], S14, 0xfd469501);
        a = FF(a, b, c, d, x[k + 8], S11, 0x698098d8);
        d = FF(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
        c = FF(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
        b = FF(b, c, d, a, x[k + 11], S14, 0x895cd7be);
        a = FF(a, b, c, d, x[k + 12], S11, 0x6b901122);
        d = FF(d, a, b, c, x[k + 13], S12, 0xfd987193);
        c = FF(c, d, a, b, x[k + 14], S13, 0xa679438e);
        b = FF(b, c, d, a, x[k + 15], S14, 0x49b40821);
        a = GG(a, b, c, d, x[k + 1], S21, 0xf61e2562);
        d = GG(d, a, b, c, x[k + 6], S22, 0xc040b340);
        c = GG(c, d, a, b, x[k + 11], S23, 0x265e5a51);
        b = GG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
        a = GG(a, b, c, d, x[k + 5], S21, 0xd62f105d);
        d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = GG(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
        b = GG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
        a = GG(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
        d = GG(d, a, b, c, x[k + 14], S22, 0xc33707d6);
        c = GG(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
        b = GG(b, c, d, a, x[k + 8], S24, 0x455a14ed);
        a = GG(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
        d = GG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
        c = GG(c, d, a, b, x[k + 7], S23, 0x676f02d9);
        b = GG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
        a = HH(a, b, c, d, x[k + 5], S31, 0xfffa3942);
        d = HH(d, a, b, c, x[k + 8], S32, 0x8771f681);
        c = HH(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
        b = HH(b, c, d, a, x[k + 14], S34, 0xfde5380c);
        a = HH(a, b, c, d, x[k + 1], S31, 0xa4beea44);
        d = HH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
        c = HH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
        b = HH(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
        a = HH(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
        d = HH(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
        c = HH(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
        b = HH(b, c, d, a, x[k + 6], S34, 0x4881d05);
        a = HH(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
        d = HH(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
        c = HH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
        b = HH(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
        a = II(a, b, c, d, x[k + 0], S41, 0xf4292244);
        d = II(d, a, b, c, x[k + 7], S42, 0x432aff97);
        c = II(c, d, a, b, x[k + 14], S43, 0xab9423a7);
        b = II(b, c, d, a, x[k + 5], S44, 0xfc93a039);
        a = II(a, b, c, d, x[k + 12], S41, 0x655b59c3);
        d = II(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
        c = II(c, d, a, b, x[k + 10], S43, 0xffeff47d);
        b = II(b, c, d, a, x[k + 1], S44, 0x85845dd1);
        a = II(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
        d = II(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
        c = II(c, d, a, b, x[k + 6], S43, 0xa3014314);
        b = II(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
        a = II(a, b, c, d, x[k + 4], S41, 0xf7537e82);
        d = II(d, a, b, c, x[k + 11], S42, 0xbd3af235);
        c = II(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
        b = II(b, c, d, a, x[k + 9], S44, 0xeb86d391);
        a = AddUnsigned(a, AA);
        b = AddUnsigned(b, BB);
        c = AddUnsigned(c, CC);
        d = AddUnsigned(d, DD);
    }

    let temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
};

export const declOfNum = (n, textForms) => {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
        return textForms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return textForms[1];
    }
    if (n1 === 1) {
        return textForms[0];
    }
    return textForms[2];
};

export const minutesBetween = (firstDate: Date, secondDate: Date): number => {
    const diffMs = secondDate.getTime() - firstDate.getTime();
    // minutes
    return diffMs / 1000 / 60;
};

export const camelToSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));

export const convertKeysCase = (obj, converter: (key: string) => string) => {
    for (let oldName in obj) {
        let newName = converter(oldName);

        if (newName != oldName) {
            if (obj.hasOwnProperty(oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }

        if (Object.prototype.toString.call(obj[newName]) == '[object Object]' && Object.keys(obj[newName]).length > 0) {
            obj[newName] = converter(obj[newName]);
        }
    }
    return obj;
};

export function downloadFileFromBinaryResponseByUrl(fileUrl: string, http: HttpClient) {
    ErrorInterceptor.skipError(2);
    http.get(fileUrl, {observe: 'response', responseType: 'arraybuffer'})
        .subscribe(
            response => {
                const filename = decodeURI(response.headers.get('content-disposition').split('filename*=utf-8\'\'')[1]);
                const blob = new Blob([response.body]);
                const url = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                ErrorInterceptor.eraseCounter();
            },
        );
}

export function downloadFileFromBinaryResponse(response: HttpResponse<ArrayBuffer>) {
    let filename = decodeURI(response.headers.get('content-disposition').split('filename="')[1]);
    filename = trim(filename, '"');
    const blob = new Blob([response.body]);
    const url = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
}

export function downloadFileByUrl(url: string) {
    const link = document.createElement('a');
    link.download = '';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function roundTo(n: number, d: number): number {
    return Math.round((n + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d);
}

export function getObjectFieldsCount(obj): number {
    return (obj !== null && typeof obj === 'object') ? Object.entries(obj)?.length ?? 0 : 0;
}

export function uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

export function uniqByKeepLast(a, key) {
    return [
        ...new Map(
            a.map(x => [key(x), x]),
        ).values(),
    ];
}

export function mapsEqual(map1, map2) {
    let testVal;
    if (map1.size !== map2.size) {
        return false;
    }
    for (let [key, val] of map1) {
        testVal = map2.get(key);
        // in cases of an undefined value, make sure the key
        // actually exists on the object so there are no false positives
        if (JSON.stringify(testVal) !== JSON.stringify(val) || (testVal === undefined && !map2.has(key))) {
            return false;
        }
        if (testVal.diffs && !mapsEqual(testVal.diffs, val.diffs)) {
            return false;
        }
    }
    return true;
}

export function isJson(str: string): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function formatPayrolls(payroll) {
    if (typeof payroll === 'object') {
        return Object.values(payroll).map((p:number, index) => new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
            p/10000,
        ))
    }
    payroll = payroll / 10000;
    return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        payroll,
    )
}

export function isNotNullOrUndefined<T>(input: T): boolean {
    return !!input && input !== null && input !== undefined;
}

export function isBoolean(object: any): object is boolean {
    return typeof object === 'boolean';
}
