/* tslint:disable */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-textarea',
  template: `
      <textarea [formControl]="$any(formControl)" [cols]="to.cols"
                [rows]="to.rows" class="form-control" [class.is-invalid]="showError"
                [formlyAttributes]="field">
    </textarea>
  `,
})
export class FormlyFieldTextArea extends FieldType {
}
