import {Directive, HostBinding, HostListener, isDevMode, NgModule, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

// tslint:disable:directive-selector
@Directive({ selector: 'app-searchbar:not([skip-coupling])' })
export class SearchbarInputDirective implements OnInit, OnDestroy {
  private static instance: SearchbarInputDirective;
  private static valueSubject$ = new BehaviorSubject<String>(undefined);
  static value = SearchbarInputDirective.valueSubject$.asObservable().pipe(debounceTime(300));
  value: string;

  ngOnInit() {
    if (isDevMode() && SearchbarInputDirective.instance) {
      throw Error('Multiple searchbars on the same screen');
    }
    SearchbarInputDirective.instance = this;
  }

  ngOnDestroy() {
    SearchbarInputDirective.instance = undefined;
    SearchbarInputDirective.valueSubject$.next(undefined);
  }

  @HostListener('input', ['$event.target.value'])
  private saveValue(value) {
    SearchbarInputDirective.valueSubject$.next(value);
  }
}

/* tslint:disable:directive-selector */
@Directive({ selector: 'app-pagination-wrapper' })
export class PaginationWrapperListenerDirective implements OnInit, OnDestroy {
  @HostBinding('attr.data-searchstring')
  private searchstringValue;
  private sub: Subscription;

  ngOnInit() {
    this.sub = SearchbarInputDirective.value
      .subscribe((value) => this.searchstringValue = value);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

@NgModule({
  declarations: [SearchbarInputDirective, PaginationWrapperListenerDirective],
  exports: [SearchbarInputDirective, PaginationWrapperListenerDirective],
})
export class SearchbarPaginationWrapperCouplingModule {}
