import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FilterService {
    private _filters: Map<string, any> = new Map();
    private _filtersUpdated$ = new Subject<void>();

    get filters$(): Observable<{ [key: string]: any }> {
        return this._filtersUpdated$.pipe(
            startWith(''),
            map(() => Object.fromEntries(this._filters))
        );
    }

    anyFilter$: Observable<boolean>;

    private _flushed$ = new Subject<void>();

    get flushed$(): Observable<void> {
        return this._flushed$.asObservable();
    }

    constructor() {
        this.anyFilter$ = this.filters$.pipe(
            map(filters => Object.keys(filters).length > 0)
        );
    }

    setFilter(key: string, value: any): void {
        if (value === null) {
            this._filters.delete(key);
        } else {
            this._filters.set(key, value);
        }
        this._filtersUpdated$.next();
    }

    batchSet(filters: { [key: string]: any }): void {
        Object.entries(filters).forEach(([key, value]) => {
            this._filters.set(key, value);
        });
        this._filtersUpdated$.next();
    }

    flush(): void {
        this._filters.clear();
        this._flushed$.next();
        this._filtersUpdated$.next();
    }
}
