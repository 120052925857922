import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';
import {LoadingInterceptor} from 'app/services/interceptors/loading.interceptor';
import {combineLatest, Subject} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Directive({
    selector: '[ngDisable]',
})
export class NgDisableDirective {
    private loader: HTMLElement;

    @Output() disablingClick = new EventEmitter();
    private clicks = new Subject();

    @HostListener('click', ['$event']) onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.el.nativeElement.disabled = true;
        this.renderer.appendChild(this.el.nativeElement, this.loader);
        this.clicks.next(event);
    }

    constructor(
        private el: ElementRef,
        private li: LoadingInterceptor,
        private renderer: Renderer2
    ) {
        this.loader = this.renderer.createElement('i');
        this.renderer.addClass(this.loader, 'fa-solid');
        this.renderer.addClass(this.loader, 'fa-circle-notch');
        this.renderer.addClass(this.loader, 'fa-spin');
        this.renderer.addClass(this.loader, 'ms-1');
    }

    ngOnInit() {
        combineLatest([
            this.clicks,
        ])
            .pipe(
                tap(([e]) => this.disablingClick.emit(e)),
                switchMap(() => this.li.isLoading$),
            )
            .subscribe(
                isLoading => {
                    if (isLoading === 0) {
                        this.el.nativeElement.disabled = false;
                        this.renderer.removeChild(this.el.nativeElement, this.loader);
                    }
                },
            );
    }

}
