import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule} from 'ngx-currency';
import {ConfigViewerModule} from 'shared/base/config-viewer/config-viewer.module';
import {FormPartsModule} from 'shared/modules/forms/forms.module';
import {ModalModule} from 'shared/modules/modal/modal.module';
import {SearchbarPaginationWrapperCouplingModule} from 'shared/modules/searchbar-pagination-wrapper-coupling/searchbar-pagination-wrapper-coupling.module';
import {SearchbarModule} from 'shared/modules/searchbar/searchbar.module';
import {TableWithControlsModule} from 'shared/modules/table/table-with-controls/table-with-controls.module';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: " ",
    nullable: true,
    min: undefined,
    max: undefined,
    inputMode: CurrencyMaskInputMode.NATURAL
};

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ModalModule,
        ConfigViewerModule,
        SearchbarModule,
        TableWithControlsModule,
        FormPartsModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        SearchbarPaginationWrapperCouplingModule,
        TranslateModule,
        RouterModule,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ModalModule,
        ConfigViewerModule,
        SearchbarModule,
        TableWithControlsModule,
        FormPartsModule,
        SearchbarPaginationWrapperCouplingModule,
        TranslateModule,
        RouterModule,
    ],
    declarations: [],
})
export class SharedModule {}
