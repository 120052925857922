import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SidebarService} from 'app/modules/navigation/sidebar/sidebar.service';

export interface MenuItem {
    route?: string;
    label: string;
    icon?: string;
    permission?: boolean;
    permissionCallback?: any;
    isOpened?: boolean;
    external?: boolean;
    hasRedirect?: boolean;
    children?: MenuItem[];
}

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: '[appSidebarMenuItem]',
    template: `
        <ng-container *ngIf="hasChildren; else direct">

                    <span dropdown container="body" placement="auto">
                      <a href
                         dropdownToggle
                         (click)="false"
                         class="nav-link nav-link-dropdown"
                         [ngClass]="{'active': router.url.indexOf(menuItem.route) !== -1}"
                      >

                          <div class="py-3" style="line-height: 1rem">
                            <i *ngIf="menuItem.icon" class="{{ menuItem.icon }} fs-2"></i>
                            <br><span class="nav-caption">{{ menuItem.label }}</span>
                        </div>
                      </a>
                      <ul *dropdownMenu class="dropdown-menu"
                          role="menu" aria-labelledby="basic-link"
                      >
                        <li *ngFor="let item of menuItem.children">
                          <a class="dropdown-item" [routerLink]="item.route" [queryParams]="{}"><i *ngIf="item.icon" class="{{ item.icon }}"></i>
                              {{ item.label }}</a>
                        </li>
                      </ul>
                    </span>
        </ng-container>
        <ng-template #direct>
            <a [routerLink]="menuItem.route ? menuItem.route : menuItem.children[0].route"
               [queryParams]="{}"
               (click)="openItem($event)" routerLinkActive="active" class="nav-link" aria-current="page"
               title=""
            >
                <div class="py-3" style="line-height: 1rem">
                    <i *ngIf="menuItem.icon" class="{{ menuItem.icon }} fs-2"></i>
                    <br>
                    <span class="nav-caption">{{ menuItem.label }}</span>
                </div>
            </a>
        </ng-template>
    `,
})
export class SidebarMenuItemComponent implements OnChanges {
    @Input() menuItem: MenuItem;

    hasChildren = false;

    constructor(public route: ActivatedRoute, public router: Router, private sidebarService: SidebarService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.hasChildren = !!(this.menuItem.children && this.menuItem.children.length);
    }

    openItem($event: MouseEvent) {
        if (this.hasChildren) {
            this.sidebarService.subMenu$.next(this.menuItem.children);
        } else {
            this.sidebarService.subMenu$.next(null);
        }
    }

    isActive(route) {
        return this.router.url.includes(route);
    }
}
