export const environment = {
    production: true,
    sentrySettings: {
        dsn: '',
        ignoreErrors: ['Cannot read properties of undefined (reading \'nativeElement\')'],
        environment: 'production'
    },
    hmr: false,
    MONEY_COEFF: 10000,
    PIT: 13,
    PIT_EXCESS: 15,
    useOriginalBudget: false,
    WEBSOCKET_CONFIG: {
        key: '\'app-key\'',
        wsHost: null,
        wsPort: 6001,
        wssPort: 6001,
    }
};
