import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {APP_INITIALIZER, InjectionToken, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl} from '@danielmoncada/angular-datetime-picker';
import {NgSelectConfig} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import * as enJSON from 'app/i18n/en.json';
import * as ruJSON from 'app/i18n/ru.json';
import {LayoutModule} from 'app/layout/layout.module';
import {LogoSvgModule} from 'app/layout/logo-svg/logo-svg.module';
import {NavbarContentService} from 'app/modules/navigation/navbar/navbar-content.service';
import {NavigationModule} from 'app/modules/navigation/navigation.module';
import {MENU_ITEMS, menuItemsFactory, SidebarService} from 'app/modules/navigation/sidebar/sidebar.service';
import {WageFundControlsService} from 'app/modules/wage/fund/wage-fund-controls.service';
import {WageChangesService} from 'app/modules/wage/shared/wage-changes.service';
import {DashboardComponent} from 'app/pages/dashboard/dashboard.component';
import {DashboardService} from 'app/services/dashboard.service';
import {LoadingInterceptor} from 'app/services/interceptors/loading.interceptor';
import {PermissionsService} from 'app/services/permissions.service';
import {ru} from 'date-fns/locale';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ToastrModule} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateFnsDateTimeAdapter, OWL_DATEFNS_DATE_TIME_ADAPTER_OPTIONS, OwlDateFnsDateTimeAdapterOptions} from 'shared/adapter/date-fns-date-time-adapter.class';
import {NgDisableDirectiveModue} from 'shared/directives/ng-disable/ng-disable-directive.modue';
import {ApiResponse} from 'shared/interfaces/response';
import {SEARCHBAR_PLACEHOLDER} from 'shared/modules/searchbar/searchbar.component';
import {SentryErrorHandlerModule} from 'shared/modules/sentry-error-handler/sentry-error-handler.module';
import {SharedModule} from 'shared/modules/shared.module';
import {PaginationService} from 'shared/modules/table/paginator/pagination.service';
import {SelectableService} from 'shared/modules/table/table-with-controls/control-cell/selectable.service';
import {ChangeDetectionService} from 'shared/services/change-detection.service';
import {ExpandedService} from 'shared/services/expanded.service';
import {FilterService} from 'shared/services/filter.service';
import {RefsMixinsService} from 'shared/services/refs.mixins';
import {RefsService} from 'shared/services/refs.service';
import {release} from '../../release';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WageFundChangesService} from './modules/wage/fund/full-view/wage-fund-changes.service';
import {LoginComponent} from './pages/login/login.component';
import {AuthService, CheckAuth} from './services/auth.service';
import {AcceptLanguageInterceptor} from './services/interceptors/accept-language.interceptor';
import {TokenInterceptor} from './services/interceptors/auth.interceptor';
import {ErrorInterceptor} from './services/interceptors/error.interceptor';
import {RuOwlDateTimeIntl} from './shared/types';

registerLocaleData(localeRu, 'ru');

export function fixSafariDatePipe() {
    const isSafari = /.*Version.*Safari.*/.test(navigator.userAgent);
    if (isSafari) {
        const transform = DatePipe.prototype.transform;
        DatePipe.prototype.transform = function (...args) {
            args[0] = typeof (args[0]) === 'string' ? args[0].replace(/\s/g, 'T') : args[0];
            return transform.apply(this, args);
        };
    }
}

export const MONEY_COEFF = new InjectionToken('MONEY_COEFF');
export const PIT = new InjectionToken('PIT');
export const PIT_EXCESS = new InjectionToken('PIT_EXCESS');
export const WEBSOCKET_CONFIG = new InjectionToken('WEBSOCKET_CONFIG');
export const USE_ORIGINAL_BUDGET = new InjectionToken('USE_ORIGINAL_BUDGET');

const translations = {
    'en': enJSON,
    'ru': ruJSON
};

class CustomLoader implements TranslateLoader {

    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {

        return this.http.get<ApiResponse<any>>('/api/handbook/translation').pipe(
            map(response => ({...translations[lang], ...response.data}))
        );
    }
}

// tslint:disable-next-line:max-classes-per-file
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        NavigationModule,
        BsDropdownModule,
        LayoutModule,
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useClass: CustomLoader, deps: [HttpClient]},
            defaultLanguage: 'ru',
        }),
        NgDisableDirectiveModue,
        LogoSvgModule,
        SentryErrorHandlerModule.withSettings(environment.sentrySettings, release),
    ],
    providers: [
        AuthService,
        DashboardService,
        SidebarService,
        NavbarContentService,
        RefsService,
        SelectableService,
        PaginationService,
        {
            provide: WageChangesService,
            useClass: WageFundChangesService
        },
        FilterService,
        ExpandedService,
        {provide: LOCALE_ID, useValue: 'ru'},
        {provide: 'defaultLang', useValue: 'ru'},
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: AuthService) => async () => {
                await CheckAuth(auth);
            },
            deps: [AuthService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptLanguageInterceptor,
            multi: true,
        },
        LoadingInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: LoadingInterceptor,
            multi: true,
        },
        {
            provide: MENU_ITEMS,
            useFactory: menuItemsFactory,
            deps: [PermissionsService],
        },
        {
            provide: SEARCHBAR_PLACEHOLDER,
            useValue: 'Поиск по разделу',
        },
        {
            provide: MONEY_COEFF,
            useValue: environment.MONEY_COEFF,
        },
        {
            provide: PIT,
            useValue: environment.PIT,
        },
        {
            provide: PIT_EXCESS,
            useValue: environment.PIT_EXCESS,
        },
        {
            provide: WEBSOCKET_CONFIG,
            useValue: environment.WEBSOCKET_CONFIG,
        },
        {
            provide: USE_ORIGINAL_BUDGET,
            useValue: environment.useOriginalBudget,
        },
        {provide: OwlDateTimeIntl, useClass: RuOwlDateTimeIntl},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'}, // default: 'en-US'.
        {provide: DateTimeAdapter, useClass: DateFnsDateTimeAdapter},
        {
            provide: OWL_DATE_TIME_FORMATS, useValue: {
                // tslint:disable-next-line:no-duplicate-string
                parseInput: 'dd.MM.yyyy',
                fullPickerInput: 'dd.MM.yyyy HH:mm',
                datePickerInput: 'dd.MM.yyyy',
                timePickerInput: 'HH:mm',
                monthYearLabel: 'MMM yyyy',
                dateA11yLabel: 'dd.MM.yyyy',
                monthYearA11yLabel: 'MMMM yyyy',
            }
        },
        {provide: OWL_DATEFNS_DATE_TIME_ADAPTER_OPTIONS, useValue: {locale: ru} as OwlDateFnsDateTimeAdapterOptions},
        ChangeDetectionService,
        WageFundControlsService,
        RefsMixinsService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    static injector: Injector;
    constructor(ngSelectConfig: NgSelectConfig, injector: Injector) {
        fixSafariDatePipe();
        this.localizeNgSelect(ngSelectConfig);
        AppModule.injector = injector;
    }

    localizeNgSelect(ngSelectConfig: NgSelectConfig) {
        ngSelectConfig.notFoundText = 'Нет значений';
        ngSelectConfig.clearAllText = 'Очистить';
        ngSelectConfig.loadingText = 'Загрузка';
        ngSelectConfig.typeToSearchText = 'Поиск';
    }
}
