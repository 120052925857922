import {PrivatePersonInterface} from 'app/modules/wage/shared/wage.interfaces';
import {OptionInterface} from 'shared/services/refs.interfaces';
import {makeEnum} from 'shared/utils/make-enum';

export interface WageBudget {
    id: number;
    name: string;
    year: number;
    status_id: number;
    version_number: number;
    status_name?: string;
    department_name: string | null;
    department_id_list: number[];
    is_group: boolean;
    is_shared: boolean;
    is_owner: boolean;
    available_for_compare: boolean;
    is_original_annual: boolean;
    root_id: number;
    delta: number;
    sum: number;
    created_at: string;
    created_by: string;
}

export enum CompetitionStatusEnum {
    Prepare = 1,
    WaitingApprove,
    Approved,
    Rejected,
    PartlyApproved,
    Exported,
    OverheadRevision,
    OverheadRevisionArchive,
}

export enum WageBudgetStatusNameShort {
    Prepare= 'Черновик',
    WaitingApprove= 'Ожидает согл.',
    Approved= 'Согласов.',
    Rejected= 'Направл. на дораб.',
    PartlyApproved= 'Согласов.',
    Exported= 'Утвержд.'
}

export enum WageBudgetStatusName {
    Prepare= 'Черновик',
    WaitingApprove= 'Ожидает согласования',
    Approved= 'Согласовано',
    Rejected= 'Направлено на доработку',
    PartlyApproved= 'Согласовано',
    Exported= 'Утверждено',
}

export type WageBudgetOption = OptionInterface<number, {
    status_name: string,
    status_id: number,
    is_group: boolean,
    is_shared: boolean,
    is_owner: boolean,
    is_fund: boolean,
    department_name: string,
    root_id: number,
    delta: number,
    created_at: string
}>;

export interface WageBudgetTimelineItemApi {
    date: string;
    wage_budget_id: number;
    wage_budget_alias: string;
    private_person_id: number;
    private_person_display_name: string;
    private_person_photo_url: string;
    action_description: string;
    action_comment: string;
}

export type WageBudgetTimelineItem = Omit<WageBudgetTimelineItemApi, 'date'> & {
    date: string,
};

export interface WageBudgetRejection {
    id: number;
    department_id: number;
    rejected_by: PrivatePersonInterface;
    comment: string;
}

export interface WageBudgetDepartmentComments {
    department_id: number;
    comment: string;
}

export interface WageBudgetMerge {
    id: number;
    name: string;
    url: string;
}

export interface WageBudgetMergeList {
    merged_into_list: WageBudgetMerge[];
    merged_list: WageBudgetMerge[];
}

export interface CheckItem {
    id: number;
    name: string;
}

export interface WageBudgetCheck {
    employees: {
        added: CheckItem[];
        removed: CheckItem[];
    };
    vacancies: {
        added: CheckItem[];
        removed: CheckItem[];
    };
    outstaff: {
        added: CheckItem[];
        removed: CheckItem[];
    };
    departments: {
        added: CheckItem[];
        removed: CheckItem[];
    };
}

export const wageBudgetCompare = makeEnum(
    'root', 'current', 'budget'
);

export type WageBudgetCompareEnum = keyof typeof wageBudgetCompare;
