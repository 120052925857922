import {Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
    selector: 'formly-repeat-section',
    template: `
        <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
            <formly-field class="col-9" [field]="field"></formly-field>
            <div class="col-3 d-flex flex-row-reverse align-items-start">
                <button class="btn btn-danger" type="button" (click)="remove(i)">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div>
            <button class="btn btn-primary" type="button" (click)="add()">{{ to.addText }}</button>
        </div>
    `,
})
export class RepeatTypeComponent extends FieldArrayType {

}
