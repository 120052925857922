import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyModule} from '@ngx-formly/core';
import {TranslateModule} from '@ngx-translate/core';
import {TextMaskModule} from 'angular2-text-mask';
import {RuOwlDateTimeIntl} from 'app/shared/types';
import {ru} from 'date-fns/locale';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CurrencyMaskInputMode, NgxCurrencyModule} from 'ngx-currency';
import {DateFnsDateTimeAdapter, OWL_DATEFNS_DATE_TIME_ADAPTER_OPTIONS, OwlDateFnsDateTimeAdapterOptions} from 'shared/adapter/date-fns-date-time-adapter.class';
import {EventDispatcherModule} from 'shared/modules/forms/event-dispatcher.directive';
import {FormlyBootstrapModule} from 'shared/modules/forms/formly-bootstrap/bootstrap';
import {HistoryInputComponent} from 'shared/modules/forms/history-input/history-input.component';
import {LabelWrapperModule} from 'shared/modules/forms/label-wrapper/label-wrapper.module';
import {MoneyInputComponent} from 'shared/modules/forms/money/money-input.component';
import {Monthpicker, MonthpickerFormlyModule} from 'shared/modules/forms/month-picker/month-picker-formly-wrapper';
import {ObserveInputComponent} from 'shared/modules/forms/observe-input/observe-input.component';
import {RepeatTypeComponent} from 'shared/modules/forms/repeat-section-type/repeat-section-type';
import {SwitchModule} from 'shared/modules/forms/switch/switch.module';
import {ToggleModule} from 'shared/modules/forms/toggle/toggle.module';
import {FormlyTypeaheadComponent} from 'shared/modules/forms/typeahead-select/formly-typeahead.component';
import {TypeaheadSelectComponent} from 'shared/modules/forms/typeahead-select/typeahead-select.component';
import {YearPickerComponent} from 'shared/modules/forms/year-picker/year-picker.component';
import {YearPickerModule} from 'shared/modules/forms/year-picker/year-picker.module';
import {DateField} from './date/date';
import {DateRangepickerComponent} from './daterangepicker/date-rangepicker.component';
import {FormlyWrapperFormField} from './form-field-wrapper/form-field.wrapper';
import {HistoryCheckboxesInputComponent} from './history-checkboxes-input/history-checkboxes-input.component';
import {LabelWrapperComponent} from './label-wrapper/label-wrapper.component';
import {FormlyFieldMaskedInput} from './masked-input/masked-input';
import {MonthDayField} from './month-day/month-day';
import {Multiselect} from './multiselect/multiselect';
import {Timepicker} from './timepicker/timepicker';
import {Switchselect} from './switch-select/switchselect';

const wrappers = ['forms-field'];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        FormlyBootstrapModule,
        NgSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SwitchModule,
        MonthpickerFormlyModule,
        LabelWrapperModule,
        YearPickerModule,
        FormlyModule.forRoot({
            types: [
                {
                    name: 'daterange',
                    component: DateRangepickerComponent,
                    wrappers,
                    defaultOptions: {
                        templateOptions:
                            {
                                placement: 'bottom',
                            },
                    },
                },
                {
                    name: 'date',
                    component: DateField,
                    wrappers,
                    defaultOptions: {
                        templateOptions:
                            {
                                pickerType: 'calendar',
                            },
                    },
                },
                {
                    name: 'month-day',
                    component: MonthDayField,
                    wrappers,
                },
                {
                    name: 'multiselect',
                    component: Multiselect,
                    wrappers,
                },
                {
                    name: 'switch-select',
                    component: Switchselect,
                    wrappers,
                },
                {
                    name: 'timepicker',
                    component: Timepicker,
                    wrappers,
                },
                {
                    name: 'monthpicker',
                    component: Monthpicker,
                    wrappers,
                },
                {
                    name: 'masked-input',
                    component: FormlyFieldMaskedInput,
                    wrappers,
                },
                {
                    name: 'history-input',
                    component: HistoryInputComponent,
                    wrappers,
                },
                {
                    name: 'history-checkboxes-input',
                    component: HistoryCheckboxesInputComponent,
                    wrappers,
                },
                {
                    name: 'observe-input',
                    component: ObserveInputComponent,
                    wrappers,
                },
                {
                    name: 'repeat',
                    component: RepeatTypeComponent,
                },
                {
                    name: 'typeahead',
                    component: FormlyTypeaheadComponent,
                    wrappers,
                },
                {
                    name: 'money',
                    component: MoneyInputComponent,
                    wrappers,
                },
                {
                    name: 'year',
                    component: YearPickerComponent,
                    wrappers,
                },
            ],
            wrappers: [
                {name: 'forms-field', component: FormlyWrapperFormField},
            ],
        }),
        EventDispatcherModule,
        TooltipModule,
        ToggleModule,
        NgxCurrencyModule.forRoot({
            align: 'right',
            allowNegative: true,
            allowZero: true,
            decimal: ',',
            precision: 2,
            prefix: '',
            suffix: '',
            thousands: ' ',
            nullable: true,
            min: null,
            max: null,
            inputMode: CurrencyMaskInputMode.NATURAL
        }),
        TranslateModule
    ],
    exports: [
        FormlyModule,
        FormlyBootstrapModule,
        ReactiveFormsModule,
        FormsModule,
        LabelWrapperComponent,
        EventDispatcherModule,
        SwitchModule,
        TypeaheadSelectComponent,
        MoneyInputComponent,
        ToggleModule
    ],
    declarations: [
        FormlyWrapperFormField,
        DateRangepickerComponent,
        Multiselect,
        Switchselect,
        Timepicker,
        DateField,
        FormlyFieldMaskedInput,
        MonthDayField,
        RepeatTypeComponent,
        HistoryInputComponent,
        ObserveInputComponent,
        TypeaheadSelectComponent,
        FormlyTypeaheadComponent,
        MoneyInputComponent,
        HistoryCheckboxesInputComponent,
    ],
    providers: [
        {provide: OwlDateTimeIntl, useClass: RuOwlDateTimeIntl},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'}, // default: 'en-US'.
        {provide: DateTimeAdapter, useClass: DateFnsDateTimeAdapter},
        {
            provide: OWL_DATE_TIME_FORMATS, useValue: {
                // tslint:disable-next-line:no-duplicate-string
                parseInput: 'dd.MM.yyyy',
                fullPickerInput: 'dd.MM.yyyy HH:mm',
                datePickerInput: 'dd.MM.yyyy',
                timePickerInput: 'HH:mm',
                monthYearLabel: 'MMM yyyy',
                dateA11yLabel: 'dd.MM.yyyy',
                monthYearA11yLabel: 'MMMM yyyy',
            }
        },
        {provide: OWL_DATEFNS_DATE_TIME_ADAPTER_OPTIONS, useValue: {locale: ru} as OwlDateFnsDateTimeAdapterOptions}
    ]
})
export class FormPartsModule {
}
