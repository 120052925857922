import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Hrbp} from 'app/modules/admin/hrbp/hrbp.interfaces';
import {Permission, RoleComponent} from 'app/modules/admin/roles/roles.interfaces';
import {MaHandbookDepartmentInterface} from 'app/modules/handbook/ma-handbook-departments/ma-handbook-department-fetch.service';
import {ProjectApiModel} from 'app/modules/projects/projects.interfaces';
import {CompetitionStatusEnum, WageBudget, WageBudgetOption, WageBudgetStatusNameShort } from 'app/modules/wage/budget/wage-budget.interfaces';
import {WageFundControlsService} from 'app/modules/wage/fund/wage-fund-controls.service';
import {DashboardService} from 'app/services/dashboard.service';
import {combineLatest, MonoTypeOperatorFunction, Observable} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {ListResponse} from 'shared/interfaces/list';
import {ApiResponse} from 'shared/interfaces/response';
import {AgencyRefApiModel, CompanyRefApiModel, DelegationPrivatePersonOption, DepartmentRefApiModel, DivisionRefApiModel, EmployeeRefApiModel, OptionInterface, PositionRefApiModel, PrivatePersonOption, PrivatePersonRefApiModel } from 'shared/services/refs.interfaces';
import {CachedObservable} from 'shared/utils/cached-observable.decorator';
import {LocalStorage} from 'shared/utils/local-storage.decorator';
import {serializeGet} from 'shared/utils/utils';

function addEmpty<T extends any[]>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => source.pipe(map(value => {
        value.unshift({ label: 'Пусто', value: null, selected: false });
        return value;
    }));
}

@Injectable({ providedIn: 'root' })
export class RefsService {

    constructor(
        private http: HttpClient,
        private ds: DashboardService,
        private wageFundControlsService: WageFundControlsService,
    ) {
    }

    private _useUserInfo = false;

    wageYears$ = this.years();
    overheadExpensesYears$ = this.oeYears();

    @LocalStorage({ initialValue: undefined })
    private _years: number[];

    companies$ = this.companiesFull();
    @LocalStorage({initialValue: undefined})
    private _companies: OptionInterface<number>[];

    myCompanies$ = this.withUser(this.companiesFull);
    @LocalStorage({initialValue: undefined})
    private _myCompanies: OptionInterface<number>[];

    myCompaniesShort$ = this.withUser(this.companiesShort);
    @LocalStorage({initialValue: undefined})
    private _myCompaniesShort: OptionInterface<number>[];

    agencies$ = this.agenciesFull();
    @LocalStorage({initialValue: undefined})
    private _agencies: OptionInterface<number>[];

    agenciesShort$ = this.agenciesShort();
    @LocalStorage({initialValue: undefined})
    private _agenciesShort: OptionInterface<number>[];

    oeAgencies$ = this.agenciesFull({component: 'overhead_expenses'});
    @LocalStorage({initialValue: undefined})
    private _oeAgencies: OptionInterface<number>[];

    myAgencies$ = this.withUser(this.agenciesFull);
    @LocalStorage({initialValue: undefined})
    private _myAgencies: OptionInterface<number>[];

    agenciesMa$ = this.agenciesMa();
    @LocalStorage({initialValue: undefined})
    private _agenciesMa: OptionInterface<number>[];

    divisions$ = this.divisions();
    @LocalStorage({ initialValue: undefined })
    private _divisions: OptionInterface<number>[];

    myDivisions$ = this.withUser(this.divisions);
    @LocalStorage({ initialValue: undefined })
    private _myDivisions: OptionInterface<number>[];

    departments$ = this.departments();
    @LocalStorage({ initialValue: undefined })
    private _departments: OptionInterface<number>[];

    departmentsMa$ = this.departmentsMa();
    @LocalStorage({ initialValue: undefined })
    private _departmentsMa: OptionInterface<number>[];

    myDepartments$ = this.withUser(this.departments);
    @LocalStorage({ initialValue: undefined })
    private _myDepartments: OptionInterface<number>[];

    myDepartmentsWithHeads$ = this.withUser(this.departmentsWithHeads);
    @LocalStorage({ initialValue: undefined })
    private _myDepartmentsWithHeads$: OptionInterface<number>[];

    hrbps$ = this.hrbps();
    @LocalStorage({ initialValue: undefined })
    private _hrbps: OptionInterface<number>[];

    positions$ = this.positions();
    @LocalStorage({ initialValue: undefined })
    private _positions: OptionInterface<number>[];

    myPositions$ = this.withUser(this.positions);
    @LocalStorage({ initialValue: undefined })
    private _myPositions: OptionInterface<number>[];

    employees$ = this.employees();
    @LocalStorage({ initialValue: undefined })
    private _employees: OptionInterface<number>[];

    persons$ = this.persons();
    @LocalStorage({ initialValue: undefined })
    private _persons: PrivatePersonOption[];

    myPersons$ = this.withUser(this.persons);
    @LocalStorage({ initialValue: undefined })
    private _myPersons: PrivatePersonOption[];

    myPersonsWithUserId$ = this.withUser(this.persons).pipe(map((data) => data.filter((item => !!item.payload.user_id))), shareReplay());
    @LocalStorage({ initialValue: undefined })
    private _myPersonsWithUserId: PrivatePersonOption[];

    delegationPersons$ = this.withUser(this.delegationPersons).pipe(map((data) => data.filter((item => item.payload.user_id !== this.ds.userInfo.id))), shareReplay());
    @LocalStorage({ initialValue: undefined })
    private _delegationPersons: DelegationPrivatePersonOption[];

    myPersonsWithoutVacanciesAndFired$ = this.withUser(this.persons, { with_fired: false })
        .pipe(map((data) => data.filter((item => !item.label.match(/ВАКАНСИЯ/)))), shareReplay());

    functionalHeads$ = this.withUser(this.functionalHeads);
    @LocalStorage({ initialValue: undefined })
    private _functionalHeads: PrivatePersonOption[];

    directHeads$ = this.withUser(this.directHeads);
    @LocalStorage({ initialValue: undefined })
    private _directHeads: PrivatePersonOption[];

    hrs$ = this.hrs();
    @LocalStorage({ initialValue: undefined })
    private _hrs: OptionInterface<number>[];

    partners$ = this.partners();
    @LocalStorage({ initialValue: undefined })
    private _partners: OptionInterface<number>[];

    projects$ = this.projects();
    @LocalStorage({ initialValue: undefined })
    private _projects: OptionInterface<number>[];

    clients$ = this.clients();
    @LocalStorage({ initialValue: undefined })
    private _clients: OptionInterface<number>[];

    mediaTypes$ = this.mediaTypes();
    @LocalStorage({ initialValue: undefined })
    private _mediaTypes: OptionInterface<number>[];

    statuses$ = this.statuses();
    @LocalStorage({ initialValue: undefined })
    private _statuses: OptionInterface<number>[];

    myStatuses$ = this.withUser(this.statuses);
    @LocalStorage({ initialValue: undefined })
    private _myStatuses: OptionInterface<number>[];

    reasons$ = this.reasons();
    @LocalStorage({ initialValue: undefined })
    private _reasons: OptionInterface<number>[];
    @LocalStorage({ initialValue: undefined })
    private _myReasons: OptionInterface<number>[];
    @LocalStorage({ initialValue: undefined })
    private _netBonusPercentage: OptionInterface<number>[];
    @LocalStorage({ initialValue: undefined })
    private _freezePercentage: OptionInterface<number>[];

    wageBudgetList$: Observable<WageBudgetOption[]> = this.wageBudgetList();
    @LocalStorage({ initialValue: undefined })
    private _wageBudgetList: WageBudgetOption[];

    ownedAndSharedWageBudgetList$: Observable<WageBudgetOption[]> = this.ownedAndSharedWageBudgetList();
    @LocalStorage({ initialValue: undefined })
    private _ownedAndSharedWageBudgetList: WageBudgetOption[];

    overheadExpensesBudgetHolderList$: Observable<PrivatePersonOption[]> = this.overheadExpensesBudgetHolderList();
    @LocalStorage({ initialValue: undefined })
    private _overheadExpensesBudgetHolderList: PrivatePersonOption[];

    myReasons$ = (year: number) => this.withUser(this.reasons, { year });

    netBonusPercentage$ = (year: number) => this.netBonusPercentage({ year });

    freezePercentage$ = (year: number) => this.withUser(this.freezePercentage, { year });

    withUser<T>(list: (body?: any) => Observable<T>, queryParams?: {}): Observable<T> {
        list = list.bind(this);
        return combineLatest([
            this.ds.userInfo$,
            this.wageFundControlsService.selectedYear$,
            this.wageFundControlsService.viewAll$,
        ]).pipe(
            switchMap(([userInfo, selectedYear, viewAll]) => list({
                user_id: userInfo.id,
                all_list: viewAll,
                year: selectedYear, ...queryParams,
            })),
            shareReplay(),
        );
    }

    withEmptyNullValue<T extends any[]>(source: Observable<T>): Observable<T> {
        return source.pipe(
            addEmpty(),
            shareReplay(),
        );
    }

    @CachedObservable('_years')
    private years(body?: any): Observable<number[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<number[]>>(`/api/wage/years${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data),
            tap(results => {
                results.sort();
            }),
            shareReplay(),
        );
    }

    private oeYears(body?: any): Observable<number[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<number[]>>(`/api/overhead-expenses/years${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data),
            tap(results => {
                results.sort();
            }),
            shareReplay(),
        );
    }


    companies(body?: any): Observable<ListResponse<CompanyRefApiModel>> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<CompanyRefApiModel>>(`/api/handbook/company-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            shareReplay()
        );
    }

    companiesFull(body?: any): Observable<OptionInterface<number, {agency_id_list: number[]}>[]> {
        return this.companies(body).pipe(
            map(response => response.data.items.map(item => ({selected: false, label: item.name, value: item.id, payload: {
                    agency_id_list: item.agency_id_list
                }}))),
            shareReplay()
        );
    }

    companiesShort(body?: any): Observable<OptionInterface<number, {agency_id_list: number[]}>[]> {
        return this.companies(body).pipe(
            map(response => response.data.items.map(item => ({selected: false, label: item.abbreviation, value: item.id, payload: {
                    agency_id_list: item.agency_id_list
                }}))),
            shareReplay()
        );
    }

    agencies(body?: any): Observable<ListResponse<AgencyRefApiModel>> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<AgencyRefApiModel>>(`/api/handbook/agency-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            shareReplay()
        );
    }

    agenciesFull(body?: any): Observable<OptionInterface<number>[]> {
        return this.agencies(body).pipe(
            map(response => response.data.items.map(item => ({selected: false, label: item.name, value: item.id, payload: {unified_company_id_list: item.unified_company_id_list}}))),
            shareReplay()
        );
    }

    agenciesShort(body?: any): Observable<OptionInterface<number>[]> {
        return this.agencies(body).pipe(
            map(response => response.data.items.map(item => ({selected: false, label: item.short_name, value: item.id, payload: {unified_company_id_list: item.unified_company_id_list}}))),
            shareReplay()
        );
    }

    // @CachedObservable('_agenciesMa')
    private agenciesMa(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<AgencyRefApiModel>>(`/api/handbook/agency-list-ma${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(
                item => ({ selected: false, label: item.short_name, value: item.id }))),
            shareReplay(),
        );
    }

    // @CachedObservable('_divisions')
    private divisions(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<DivisionRefApiModel>>(`/api/handbook/division-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    departments(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<DepartmentRefApiModel[]>>(`/api/handbook/department-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    departmentsMa(body?: any): Observable<OptionInterface<number, {parent_id: number, level: number, agency_id: number}>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<MaHandbookDepartmentInterface>>(`/api/management-accounting/handbook/department/list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({selected: false, label: item.name, value: item.id, payload: {
                    parent_id: item.parent_id,
                    level: item.level,
                    agency_id: item.agency_id,
                }}))),
            shareReplay()
        );
    }

    departmentsWithHeads(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<DepartmentRefApiModel[]>>(`/api/handbook/department-with-head-list${delimeter}${serializeGet(!!body ? body : {})}`)
            .pipe(
                map(response => response.data.map(item => ({
                    selected: false,
                    label: item.name,
                    value: item.id,
                    payload: {
                        head_private_person_id: item.head_private_person_id,
                        head_agency_id: item.head_agency_id,
                    },
                }))),
                shareReplay(),
            );
    }

    positions(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<PositionRefApiModel>>(`/api/handbook/position-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_myPositions')
    private myPositions(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<PositionRefApiModel>>(`/api/handbook/wage-holder-position-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_persons')
    private employees(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<EmployeeRefApiModel>>(`/api/handbook/employee-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(
                item => ({ selected: false, label: item.display_name, value: item.id }))),
            shareReplay(),
        );
    }

    persons(body?: any): Observable<PrivatePersonOption[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<PrivatePersonRefApiModel>>(`/api/handbook/private-person-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(
                item => ({
                    selected: false,
                    label: item.display_name,
                    value: item.id,
                    payload: {
                        department_id: item.department_id,
                        is_top: item.is_top,
                        snils: item.snils,
                        user_id: item.user_id,
                        company_id: item.company_id
                    },
                }))),
            shareReplay(),
        );
    }

    delegationPersons(body?: any): Observable<DelegationPrivatePersonOption[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<any>>(`/api/delegate/available-private-person-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(
                item => ({
                    selected: false,
                    label: item.display_name + (item.has_delegation ? '  ✔' : ''),
                    value: item.id,
                    payload: { has_delegation: item.has_delegation, user_id: item.user_id },
                }))),
            shareReplay(),
        );
    }

    personsWithoutVacancies(body?: any): Observable<PrivatePersonOption[]> {
        return this.persons(body).pipe(map((data) => data.filter((item => !item.label.match(/ВАКАНСИЯ/)))), shareReplay());
    }

    functionalHeads(body?: any): Observable<PrivatePersonOption[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<PrivatePersonRefApiModel>>(`/api/handbook/functional-head-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(
                item => ({
                    selected: false,
                    label: item.display_name,
                    value: item.id,
                    payload: {
                        department_id: item.department_id,
                        is_top: item.is_top,
                        snils: item.snils,
                        user_id: item.user_id,
                        company_id: item.company_id,
                    },
                }))),
            shareReplay(),
        );
    }

    directHeads(body?: any): Observable<PrivatePersonOption[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<PrivatePersonRefApiModel>>(`/api/handbook/direct-head-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(
                item => ({
                    selected: false,
                    label: item.display_name,
                    value: item.id,
                    payload: {
                        department_id: item.department_id,
                        is_top: item.is_top,
                        snils: item.snils,
                        user_id: item.user_id,
                        company_id: item.company_id
                    },
                }))),
            shareReplay(),
        );
    }

    @CachedObservable('_hrs')
    private hrs(): Observable<OptionInterface<number>[]> {
        return this.http.get<ListResponse<PrivatePersonRefApiModel>>(`/api/handbook/hrs-list`).pipe(
            map(response => response.data.items.map(
                item => ({ selected: false, label: item.display_name, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_partners')
    private partners(): Observable<OptionInterface<number>[]> {
        return this.http.get<ListResponse<PrivatePersonRefApiModel>>(`/api/handbook/partner-person-list`).pipe(
            map(response => response.data.items.map(
                item => ({ selected: false, label: item.display_name, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_projects')
    private projects(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<ProjectApiModel>>(`/api/company/project/list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({ selected: false, label: item.title, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_clients')
    private clients(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<{
            id,
            name
        }[]>>(`/api/client/handbook/client/list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_mediaTypes')
    private mediaTypes(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<{
            id,
            name
        }[]>>(`/api/client/handbook/media-type/list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    statuses(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<{
            id,
            name
        }>>(`/api/handbook/status-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({ selected: false, label: item.name, value: item.id })).filter((item) => item.value !== 1)),
            shareReplay(),
        );
    }

    // @CachedObservable('_reasons')
    private reasons(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ListResponse<{
            id,
            name
        }>>(`/api/handbook/reason-for-dismissal-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.items.map(item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    @CachedObservable('_netBonusPercentage')
    private netBonusPercentage(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<number[]>>(`/api/handbook/net-bonus-percentage-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(v => ({ selected: false, label: `${v}`, value: v }))),
            shareReplay(),
        );
    }

    // @CachedObservable('_freezePercentage')
    private freezePercentage(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<number[]>>(`/api/handbook/freeze-percentage-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(v => ({ selected: false, label: `${v}`, value: v }))),
            shareReplay(),
        );
    }

    @CachedObservable('_hrbps')
    private hrbps(): Observable<OptionInterface<number>[]> {
        return this.http.get<ListResponse<Hrbp>>(`/api/handbook/hrbp-list`).pipe(
            map(response => response.data.items.map(
                item => ({ selected: false, label: item.name, value: item.id }))),
            shareReplay(),
        );
    }

    components() {
        return this.http.get<ApiResponse<RoleComponent[]>>('api/role/component/list').pipe(map(it => it.data));
    }

    permissionList(forComponentWithId: number) {
        let apiUrl = '/api/role/permission/list';
        if (forComponentWithId) {
            apiUrl += `?component_id=${forComponentWithId}`;
        }
        return this.http
            .get<ApiResponse<Permission[]>>(apiUrl)
            .pipe(map(x => x.data.map(y => ({ value: y.id, selected: false, label: y.name }))));
    }

    rolesList(forComponentWithId: number) {
        let apiUrl = 'api/role/list';
        if (forComponentWithId) {
            apiUrl += `?component_id=${forComponentWithId}`;
        }
        return this.http.get(apiUrl).pipe(
            map((x: any) => x.data.items.map(y => ({ value: y.id, selected: false, label: y.name }))),
        );
    }

    wageBudgetList(): Observable<WageBudgetOption[]> {
        return this.http.get<ApiResponse<WageBudget[]>>(`/api/wage/budget/list`)
            .pipe(
                map(response => response.data.map(item => ({
                    selected: false,
                    label: `v${item.version_number} ${item.name}`,
                    value: item.id,
                    payload: {
                        status_id: item.status_id,
                        status_name: WageBudgetStatusNameShort[CompetitionStatusEnum[item.status_id]],
                        is_group: item.is_group,
                        is_shared: item.is_shared,
                        is_owner: item.is_owner,
                        is_fund: false,
                        department_name: item.department_name,
                        root_id: item.root_id,
                        delta: item.delta,
                        created_at: item.created_at,
                    },
                }))),
                shareReplay(),
            );
    }

    ownedAndSharedWageBudgetList(): Observable<WageBudgetOption[]> {
        return this.http.get<ApiResponse<WageBudget[]>>(`/api/wage/budget/owned-and-shared-list`)
            .pipe(
                map(response => response.data.map(item => ({
                    selected: false,
                    label: item.name,
                    value: item.id,
                    payload: {
                        status_id: item.status_id,
                        status_name: WageBudgetStatusNameShort[CompetitionStatusEnum[item.status_id]],
                        is_group: item.is_group,
                        is_shared: item.is_shared,
                        is_owner: item.is_owner,
                        is_fund: false,
                        department_name: item.department_name,
                        root_id: item.root_id,
                        delta: item.delta,
                        created_at: item.created_at,
                    },
                }))),
                shareReplay(),
            );
    }

    managementAccountingDepartments(): Observable<OptionInterface<number>[]> {
        return this.http
            .get<ApiResponse<{ name: string, id: number }[]>>(`/api/handbook/management-accounting-departments-list`)
            .pipe(
                map(response => response.data.map(({ name, id }) => ({ selected: false, label: name, value: id }))),
                shareReplay(),
            );
    }

    managementAccountingExpensesItems(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http
            .get<ApiResponse<{ name: string, id: number }[]>>(`/api/handbook/management-accounting-expenses-items-list${delimeter}${serializeGet(!!body ? body : {})}`)
            .pipe(
                map(response => response.data.map(({ name, id }) => ({ selected: false, label: name, value: id }))),
                shareReplay(),
            );
    }

    availableManagementAccountingExpensesItems(body?: any): Observable<OptionInterface<number>[]> {
        const delimeter = !!body ? '?' : '';
        return this.http
            .get<ApiResponse<{ name: string, id: number }[]>>(`/api/management-accounting/expense-item/available-list${delimeter}${serializeGet(!!body ? body : {})}`)
            .pipe(
                map(response => response.data.map(({ name, id }) => ({ selected: false, label: name, value: id }))),
                shareReplay(),
            );
    }

    overheadExpensesBudgetHolderList(body?: any): Observable<PrivatePersonOption[]> {
        const delimeter = !!body ? '?' : '';
        return this.http.get<ApiResponse<PrivatePersonRefApiModel[]>>(`/api/handbook/overhead-expenses-budget-holder-list${delimeter}${serializeGet(!!body ? body : {})}`).pipe(
            map(response => response.data.map(
                item => ({
                    selected: false,
                    label: item.display_name,
                    value: item.id,
                    payload: {
                        department_id: item.department_id,
                        is_top: item.is_top,
                        snils: item.snils,
                        user_id: item.user_id,
                        company_id: item.company_id
                    },
                }))),
            shareReplay(),
        );
    }
}
