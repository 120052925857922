import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {ApiResponse} from "shared/interfaces/response";
import {catchError, map} from "rxjs/operators";
import {laravelErrorsToInnerHTML} from "shared/utils/operators";

export interface NotificationInterface {
    body: string
    created_at: string
    deleted_at: string
    id: number
    is_read: number
    recipient_user_id: number
    subject: string
    updated_at: string
    via: string
}

@Injectable({
  providedIn: 'root'
})
export class NotificationFetchService {

    constructor(private http: HttpClient, private toastr: ToastrService) {
    }

    getNotifications(): Observable<NotificationInterface[]> {
        return this.http.get<ApiResponse<NotificationInterface[]>>(
            `/api/notification/list`).
        pipe(map(response => response.data));
    }

    markRead(notification_id_list: number[]){
        return this.http.post<ApiResponse<NotificationInterface[]>>(
            `/api/notification/read`, {notification_id_list}).pipe(
            laravelErrorsToInnerHTML(),
            catchError(e => {
                if (typeof e === 'string' && e.length) {
                    this.toastr.error(e, '', {enableHtml: true});
                }
                throw e;
            }),
        );
    }

    delete(notification_id_list: number[]){
        return this.http.delete<ApiResponse<NotificationInterface[]>>(
            `/api/notification`, {body: {notification_id_list}}).pipe(
            laravelErrorsToInnerHTML(),
            catchError(e => {
                if (typeof e === 'string' && e.length) {
                    this.toastr.error(e, '', {enableHtml: true});
                }
                throw e;
            }),
        );
    }
}
