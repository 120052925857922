import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LoaderOverlayComponent} from 'shared/components/loader-overlay/loader-overlay.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [LoaderOverlayComponent],
    declarations: [LoaderOverlayComponent],
    providers: [],
})
export class LoaderOverlayModule {
}
