import {debounce} from 'lodash';
import {addSeconds, compareAsc} from "date-fns";

export function LocalStorage({
                                 initialValue,
                                 debounceTime,
                                 expireInSeconds
                             }: { initialValue: any, debounceTime?: number, expireInSeconds?: number }) {
    if (!!expireInSeconds === false) {
        expireInSeconds = 60 * 60 * 24;
    }
    return function (target: Object, propName: string) {
        const now = new Date();
        let valueBag;
        let value;
        let expired = false;
        try {
            valueBag = JSON.parse(localStorage.getItem(propName));
            if (compareAsc(now, new Date(valueBag.expireIn)) === -1) {
                value = valueBag.value;
            } else {
                value = null;
                expired = true;
            }
        } catch (e) {
            value = null;
        }
        const boxed = {value: value || initialValue};

        function getValue(): any {
            return boxed.value;
        }

        let setValueInLocalStorage = (val: any) => localStorage.setItem(propName, JSON.stringify(val));
        if (debounceTime) {
            setValueInLocalStorage = debounce(setValueInLocalStorage, debounceTime);
        }

        function setValue(val: any) {
            boxed.value = val;
            if (expired) {
                setValueInLocalStorage({value: val, expireIn: addSeconds(new Date, expireInSeconds).getTime()});
            }
        }

        Object.defineProperty(target, propName, {
            configurable: true,
            enumerable: true,
            get: getValue,
            set: setValue
        });
    };
}
