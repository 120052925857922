import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TableModule} from '../table.module';
import {ControlCellComponent} from './control-cell/control-cell.component';
import {TableWithControlsService} from './table-with-controls.service';
import {TableWithControlsComponent} from './table-with-controls/table-with-controls.component';

@NgModule({
    // @ts-ignore
    imports: [CommonModule, TableModule, TooltipModule.forRoot()],
    // @ts-ignore
    exports: [TableModule, TableWithControlsComponent],
    providers: [TableWithControlsService],
    declarations: [TableWithControlsComponent, ControlCellComponent]
})
export class TableWithControlsModule {}
