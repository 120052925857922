import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal',
    templateUrl: './default-modal.component.html',
})
export class DefaultModalComponent implements AfterViewInit {
    @Input() modalRef: BsModalRef;
    @Input() title;
    @Output() initialized: EventEmitter<DefaultModalComponent> = new EventEmitter<DefaultModalComponent>();

    private _size: 'sm' | 'lg' | 'xl';
    @Input() set size(val: 'sm' | 'lg' | 'xl') {
        this._size = val;
        if (this._elementRef.nativeElement && this.dialogElement) {
            this.dialogElement.classList.add(`modal-${this.size}`);
        }
    }

    get size() {
        return this._size;
    }

    get dialogElement(): HTMLElement {
        return this._elementRef.nativeElement.closest('.modal-dialog') as any;
    }

    constructor(private _elementRef: ElementRef<HTMLElement>) {
    }

    close() {
        this.modalRef.hide();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.dialogElement.classList.add('modal-dialog-centered');
            if (this.size) {
                this.dialogElement.classList.add(`modal-${this.size}`);
            }
            this.initialized.emit(this);
        }, 0);
    }

}
