import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DashboardService} from 'app/services/dashboard.service';
import {ErrorInterceptor} from 'app/services/interceptors/error.interceptor';
import {PermissionsTree, UserAccess} from 'app/services/permissions.interface';
import {isBoolean, isNotNullOrUndefined} from 'app/shared/utils/utils';
import _ from 'lodash';
import {ReplaySubject} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {ApiResponse} from 'shared/interfaces/response';
import {PropStub} from 'shared/modules/table/table-config.model';
import {LocalStorage} from 'shared/utils/local-storage.decorator';

export const permStub = PropStub<PermissionsTree>();

@Injectable({providedIn: 'root'})
export class PermissionsService {

    permissions$ = new ReplaySubject<PermissionsTree>(1);

    @LocalStorage({initialValue: undefined})
    private permissions: PermissionsTree;
    @LocalStorage({initialValue: undefined})
    private root: boolean;

    constructor(
        private http: HttpClient,
        private ds: DashboardService
    ) {
    }

    loadPermissions() {
        ErrorInterceptor.skipError(2);
        return this.http.get<ApiResponse<UserAccess>>('/api/access').pipe(
            map(it => it.data),
            tap(({user, permissions, settings, employee, process, competitions}) => {
                this.ds.userInfo = user;
                this.ds.employeeInfo = employee;
                this.ds.userSettings = settings;
                this.ds.competitions = competitions;
                this.root = user.root;
                this.permissions = {Root: user.root, ...permissions};
                this.permissions$.next(permissions);
            }),
            finalize(() => ErrorInterceptor.eraseCounter()),
        );
    }

    removePermissions() {
        localStorage.removeItem('root');
        localStorage.removeItem('permissions');
    }

    getPermissionValue(val: boolean): boolean {
        if (this.root) {
            return true;
        }

        if (isBoolean(val)) {
            return val;
        }
        if (!isNotNullOrUndefined(val)) {
            console.warn('Permission not found');
            return false;
        }

        const path = ((val as any).path as string[]);
        return _.get(this.permissions, path.join('.'), false);
    }
}
