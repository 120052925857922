<i class="bi bi-search"></i>
<div class="input-group flex-nowrap animated {{ (search && search.length) || show ? 'opacity-1' : '' }}"
     style="margin-left: 0" [ngClass]="{'input-group-sm': small}">
    <!-- <ng-content select=".searchbar-right"></ng-content> -->
    <input *ngIf="!hideInput" type="text" class="form-control flex-grow-1" [placeholder]="placeholder"
           [(ngModel)]="search"/>
    <button *ngIf="(search && search.length)" class="btn btn-outline-secondary clear" (click)="clear()">
        <i class="bi bi-x-circle"></i>
    </button>
    <ng-content></ng-content>
</div>
