import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {PermissionsService} from 'app/services/permissions.service';
import {Observable} from 'rxjs';
import {mapTo} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoadPermissionsGuard implements CanActivate {

  constructor(private permissions: PermissionsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissions.loadPermissions().pipe(mapTo(true));
  }
}
