import {NgModule} from '@angular/core';
import {LogoComponent} from "app/layout/logo.component";
import {MainLayoutComponent} from "app/layout/main/main-layout.component";
import {NavigationModule} from "app/modules/navigation/navigation.module";
import {RouterModule} from "@angular/router";
import {SharedModule} from "shared/modules/shared.module";
import {UserChipComponent} from "app/layout/user-chip.component";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {AvatarModule} from "shared/components/avatar/avatar.module";
import {CardComponent} from "app/layout/card/card.component";
import { NotificationsChipComponent } from './notifications-chip.component';
import {NgLetDirectiveModule} from "shared/directives/ng-let/ng-let-directive.module";

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        NavigationModule,
        BsDropdownModule,
        AvatarModule,
        NgLetDirectiveModule,
    ],
    exports: [MainLayoutComponent, CardComponent],
    declarations: [LogoComponent, MainLayoutComponent, UserChipComponent, CardComponent, NotificationsChipComponent],
    providers: [],
})
export class LayoutModule {
}
