import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyModule} from '@ngx-formly/core';
import {FormlySelectModule} from '@ngx-formly/core/select';
import {FormlyBootstrapAddonsModule} from 'shared/modules/forms/formly-bootstrap/addons/addons.module';
import {EventDispatcherModule} from '../event-dispatcher.directive';
import {BOOTSTRAP_FORMLY_CONFIG, FIELD_TYPE_COMPONENTS} from './bootstrap.config';

@NgModule({
  declarations: [
    FIELD_TYPE_COMPONENTS,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormlySelectModule,
        FormlyBootstrapAddonsModule,
        NgSelectModule,
        FormlyModule.forRoot(BOOTSTRAP_FORMLY_CONFIG),
        EventDispatcherModule,
        FormsModule,
    ],
})
export class FormlyBootstrapModule {
}
