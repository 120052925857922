import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-label-wrapper',
    template: `
        <div class="mb-3 {{ formGroupClass }}">
            <label *ngIf="label">
                {{ label }}
            </label>
            <ng-content></ng-content>
        </div>
    `,
})
export class LabelWrapperComponent {
    @Input() label;
    @Input() alwaysOpened = false;
    @Input() formGroupClass;
    @Input() required = false;
}
