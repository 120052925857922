import {AfterViewInit, ChangeDetectionStrategy, Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';
import {BindObservable} from 'bind-observable';
import {isObject} from 'lodash';
import {isObservable, Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';

/*tslint:disable component-class-suffix component-selector*/
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'formly-field-select',
    template: `

        <div class="input-group">
            <ng-select
                    class="flex-width"
                    [items]="options$ | async"
                    [formControl]="formControl"
                    [searchable]="to.hasOwnProperty('searchable') ? to.searchable : true"
                    [clearable]="!to.required"
                    [addTag]="field.templateOptions.tagable ? addTagHandler : false"
                    [compareWith]="field.templateOptions.compareWith ? field.templateOptions.compareWith : compareFn"
                    bindValue="value"
            >
                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label" [title]="item.label">{{ item.label }}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <span class="ng-option-label" [title]="item.label">{{ item.label }}</span>
                </ng-template>
            </ng-select>
            <label *ngIf="!!to.alt_options && !!to.alt_options_label" class="input-group-text" for="inputGroupSelect02">{{ to.alt_options_label }}</label>
            <div *ngIf="!!to.alt_options && !!to.alt_options_label" class="input-group-text">
                <input class="form-check-input mt-0" type="checkbox" [(ngModel)]="altOptions">
            </div>
        </div>

    `,
})
export class FormlyFieldSelect extends FieldType<FieldTypeConfig> implements AfterViewInit {
    declare to: any;

    @BindObservable()
    altOptions = false;
    altOptions$: Observable<boolean>;

    // get options$(): Obsany[] {
    //     return this.altOptions$.pipe(
    //         distinctUntilChanged(),
    //         switchMap(altOptions => altOptions === false ? (isObservable(this.to.options) ? this.to.options : of(this.to.options))
    //                                                : (isObservable(this.to.alt_options) ? this.to.alt_options : of(this.to.alt_options)))
    //     );
    // }

    get options$() {
        if (this.altOptions) {
            return isObservable(this.to.alt_options) ? this.to.alt_options : of(this.to.alt_options);
        }
        return isObservable(this.to.options) ? this.to.options : of(this.to.options);
    }

    get disableIfSingleOption(): boolean {
        if (this.to.hasOwnProperty('disableIfSingleOption')) {
            return this.to.disableIfSingleOption;
        }

        return false;
    }

    ngAfterViewInit(): void {
        if (!this.field.templateOptions.readonly) {
            this.formControl.enable();
        } else {
            this.formControl.disable();
        }
        if (this.disableIfSingleOption) {
            this.options$.pipe(take(1)).subscribe(options => {
                if (options.length === 1 && this.to.required && this.formControl.value === options[0].value) {
                    this.formControl.disable();
                }
            });
        }
    }

    addTagHandler(s) {
        return {label: s, value: s, tag: true};
    }

    compareFn(item, selected) {
        return item.value === (isObject(selected) ? selected['value'] : selected);
    }

}
