import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

/*tslint:disable component-class-suffix no-host-metadata-property component-selector*/
@Component({
  selector: 'field-timepicker',
  template: `
    <input autocomplete="off"
           [owlDateTimeTrigger]="dt"
           [owlDateTime]="dt"
           [formControl]="$any(formControl)"
           class="form-control"
           [formlyAttributes]="field"
           [class.is-invalid]="showError">
    <owl-date-time #dt [pickerType]="'timer'" [stepMinute]="field.templateOptions['stepMinute'] ? field.templateOptions['stepMinute'] : 1"></owl-date-time>
  `,
  host: {
    '[class.d-inline-flex]': 'to.addonLeft || to.addonRight',
    '[class.custom-file]': 'to.addonLeft || to.addonRight',
  },
  styleUrls: [`timepicker.scss`],
})
export class Timepicker extends FieldType {
  declare to: any;
}
