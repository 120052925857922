import {Component, EventEmitter, HostBinding, Inject, InjectionToken, Input, Optional, Output} from '@angular/core';

export const SEARCHBAR_PLACEHOLDER = new InjectionToken('SEARCHBAR_PLACEHOLDER');

@Component({
    selector: 'app-searchbar',
    templateUrl: './searchbar.component.html',
})
export class SearchbarComponent {
    @HostBinding('class.typed')
    private _search: string;
    get search() {
        return this._search;
    }

    @Input() set search(val) {
        this.searchChange.emit(val);
        this._search = val;
    }

    @Output() searchChange = new EventEmitter();
    @Input() show = false;
    @Input() hideInput = false;
    @Input() placeholder;
    @Input() small = false;

    constructor(@Optional() @Inject(SEARCHBAR_PLACEHOLDER) placeholder) {
        this.placeholder = placeholder;
    }

    clear() {
        this.search = null;
    }
}
