import {Component, OnInit} from '@angular/core';
import {CellData} from 'shared/modules/table/table-config.model';

@Component({
    selector: 'app-money-cell',
    template: `
        <span class="d-block w-100 h-100 text-end">{{ cellData.value | money:digits }}</span>
    `,
    styles: [
        `
            :host {
                width: 100%;
                display: block;
            }
        `,
    ],
})

export class MoneyCell implements OnInit {

    get digits() {
        // @ts-ignore
        if (this.cellData.column.data && this.cellData.column.data.hasOwnProperty('digits')) {
            // @ts-ignore
            return this.cellData.column.data.digits;
        }
        return 0;
    }

    constructor(
        public cellData: CellData<any, { prop }>,
    ) {
    }

    ngOnInit() {

    }
}
