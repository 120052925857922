import { Injectable } from '@angular/core';
import {NotificationFetchService, NotificationInterface} from "app/modules/notification/notification-fetch.service";
import {BehaviorSubject, Observable} from "rxjs";
import {filter, map, shareReplay, switchMap, take} from "rxjs/operators";
import _ from "lodash";
import {PusherService} from "shared/services/pusher.service";
import {DashboardService} from "app/services/dashboard.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    notifications$: Observable<NotificationInterface[]>;
    next$ = new BehaviorSubject(undefined);
    constructor(
        private f: NotificationFetchService,
        private pusherService: PusherService,
        private da: DashboardService
    ) {
        this.notifications$ = this.next$.pipe(
            switchMap(() => {
                return this.f.getNotifications().pipe(
                    map((items) => _.reverse(items))
                );
            }),
            shareReplay()
        )

        this.pusherService.on('Notification', `User-${this.da.userInfo.id}.Notifications`)
            .subscribe(() => this.reload());
    }

    reload() {
        this.next$.next(0)
    }

    read() {
        this.notifications$.pipe(
            map(items => items.filter(item => !item.is_read)),
            filter((items) => items.length > 0),
            switchMap((items) => this.f.markRead(items.map(item => item.id)))
        ).subscribe();
    }

    clear() {
        this.notifications$.pipe(
            take(1),
            filter((items) => items.length > 0),
            switchMap((items) => this.f.delete(items.map(item => item.id)))
        ).subscribe(() => this.reload());
    }
}
