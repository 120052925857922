/* tslint:disable */
import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio',
  template: `
      <div [formGroup]="form">
          <div class="form-check" [ngClass]="{'form-check-inline': to['inline']}" *ngFor="let option of to.options | formlySelectOptions:field | async; let i = index;">
              <input class="form-check-input" type="radio"
                     [id]="id + '_' + i"
                     [name]="id"
                     [class.is-invalid]="showError"
                     [value]="option.value"
                     [formControl]="$any(formControl)"
                     [formlyAttributes]="field">
              <label class="form-check-label" [for]="id + '_' + i">
                  {{ option.label }}
              </label>
          </div>
      </div>
  `,
})
export class FormlyFieldRadio extends FieldType {}
