import {AfterViewInit, Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseValueAccessor} from 'shared/modules/forms/base-value-accessor.class';
import {uniqueid} from 'shared/utils/utils';

@Component({
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SwitchComponent), multi: true }],
    selector: 'app-switch',
    template: `
        <div>
            <div class="form-check form-switch mb-0">
                <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        [formControl]="$any(formControl)"
                        [id]="uid"
                />
                <label [for]="uid" class="form-check-label">{{ label }}</label>
            </div>
        </div>
    `,
    styles: [
        `
            //:host {
            //    display: flex;
            //    align-items: center;
            //    background-color: white;
            //    padding-left: 0.875rem;
            //    padding-right: 0.875rem;
            //    border: 1px solid rgb(204, 204, 204);
            //    border-radius: 4px;
            //}
        `,
    ],
})
export class SwitchComponent extends BaseValueAccessor implements AfterViewInit, OnDestroy {
    @Input() label;

    @HostBinding('class.toggle-switch')
    @Input()
    leftLabel;

    @HostBinding('class.animated-switch')
    private initialized = false;

    @Output() valueChange = new EventEmitter<boolean>();
    onChange = (_: any) => { this.valueChange.emit(this.formControl.value) };

    uid = uniqueid();

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        requestAnimationFrame(() => (this.initialized = true));
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
