import {Component, Input} from '@angular/core';
import {LoadingInterceptor} from 'app/services/interceptors/loading.interceptor';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-loader-overlay',
    template: `
        <div *ngIf="isLoading$ | async" class="wrapper bg-secondary-light w-100 h-100" style="opacity: 0.5">
            <div class="centered d-flex justify-content-start align-items-center">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    `,
    styles:  [`
                :host .wrapper {
                  display: block;
                  position: absolute;
                  z-index: 100;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }
              `]
})

export class LoaderOverlayComponent {

    @Input()
    contextualLoadingState$: Observable<number>;
    private globalLoadingState$: Observable<number>;

    get isLoading$(): Observable<number> {
        return !!this.contextualLoadingState$ ? this.contextualLoadingState$ : this.globalLoadingState$;
    }

    constructor(
        li: LoadingInterceptor,
    ) {
        this.globalLoadingState$ = li.isLoading$;
    }
}
