import {NgModule} from '@angular/core';
import {NgDisableDirective} from 'shared/directives/ng-disable/ng-disable.directive';

@NgModule({
    imports: [],
    exports: [NgDisableDirective],
    declarations: [NgDisableDirective],
    providers: [],
})
export class NgDisableDirectiveModue {
}
