import {Injectable, TemplateRef} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {empty, merge, Observable, of, Subject} from 'rxjs';
import {catchError, delay, exhaustMap, filter, finalize, first, map, switchMap, take, tap} from 'rxjs/operators';
import {ActionsModalComponent} from 'shared/modules/modal/actions-modal/actions-modal.component';
import {ImageModalComponent} from 'shared/modules/modal/image-modal/image-modal.component';
import {InfoModalComponent} from 'shared/modules/modal/info-modal/info-modal.component';
import {Ctor, Omit} from 'shared/types';
import {FormUtils} from 'shared/utils/form.utils';
import {laravelErrorsToInnerHTML} from 'shared/utils/operators';
import {ConfirmModalComponent} from './confirm-modal/confirm-modal.component';
import {FormModalAbstract, FormModalComponent, modalSizes} from './form-modal/form-modal.component';
import {WizardModalComponent, WizardSteps} from './wizard-modal/wizard-modal.component';

const CLOSE_ERROR: 'close_error' = 'close_error';
const CANCEL = false;

export interface OpenFormPayload<TModel, TResult, TForm> {
    modalSize?: modalSizes;
    title: string;
    form: TForm;
    onSubmit: (formValue: TModel) => Observable<TResult>;
    onCancel?: (formValue: TModel) => Observable<TResult>;
    customHtml?: any;
    template?: TemplateRef<any>;
    model?: TModel;
    buttonTitle?: string;
    cancelButtonTitle?: string;
}

export interface OpenAbstractFormPayload<TModel, TResult, TCmp extends FormModalAbstract>
    extends OpenFormPayload<TModel, TResult, TCmp['fields']> {
    component: Ctor<TCmp>;
}

export const disableFormAndSetSizeToTheTextAreas = (cmp: { form: AbstractControl }) => requestAnimationFrame(() => {
    [].forEach.call(
        document.querySelectorAll('.forms-as-info textarea'),
        (textarea: HTMLTextAreaElement) => {
            textarea.style.height = `0`;
            textarea.style.height = `${textarea.scrollHeight + 3}px`;
            textarea.style.resize = `none`;
        },
    );
    cmp.form.disable();
});

export interface ActionModalButton<TModel, TResult> {
    label: string;
    onSubmit: (formValue: TModel) => Observable<TResult>;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    static titles = {
        create: 'Создать',
        update: 'Сохранить изменения',
        infoTitle: 'Ок',
        close: 'Закрыть',
        confirmOkButtonTitle: 'Продолжить',
        confirmCancelButtonTitle: 'Отменить',
        confirmTitle: 'Подтвердить действие',
    };

    get openedModalsCount() {
        return this.bsModalService.getModalsCount();
    }

    constructor(public bsModalService: BsModalService, private toastr: ToastrService) {}

    showInfo(payload: { message, title?, closeButtonTitle?, files? }) {
        const { cmp, modalRef } = this.openModalComponent(InfoModalComponent);
        cmp.modalRef = modalRef;
        cmp.message = payload.message;
        cmp.title = payload.title || ModalService.titles.infoTitle;
        cmp.closeButtonTitle = payload.closeButtonTitle || ModalService.titles.infoTitle;
    }

    showImage(payload: { src }) {
        const { cmp, modalRef } = this.openModalComponent(ImageModalComponent, false);
        cmp.modalRef = modalRef;
        cmp.photoSrc = payload.src;
    }

    confirm(message: string, settings: { okButtonTitle?: string, cancelButtonTitle?: string, modalTitle?: string } = {}) {
        const { cmp, modalRef } = this.openModalComponent(ConfirmModalComponent);

        const initialHide = modalRef.hide;
        modalRef.hide = () => {
            initialHide();
            cmp.answer.next(false);
        };

        cmp.message = message;
        cmp.okButtonTitle = settings.okButtonTitle
            || ModalService.titles.confirmOkButtonTitle;
        cmp.cancelButtonTitle = settings.cancelButtonTitle
            || ModalService.titles.confirmCancelButtonTitle;
        cmp.modalTitle = settings.modalTitle
            || ModalService.titles.confirmTitle;

        return cmp.answer.pipe(
            take(1),
            tap(() => modalRef.hide()),
            switchMap(ans => ans
                ? of(true)
                : empty(),
            ),
        );
    }

    actions<TModel, TResult>(title: string, message: string, settings: { actions?: ActionModalButton<TModel, TResult>[], model: TModel }) {
        const { cmp, modalRef } = this.openModalComponent(ActionsModalComponent);
        cmp.message = message;
        cmp.actions = settings.actions;
        cmp.modalTitle = title || ModalService.titles.confirmTitle;
        cmp.model = settings.model;
        return cmp.submit.pipe(
            // @ts-ignore
            exhaustMap((action: ActionModalButton<TModel, TResult>) => {
                    return action.onSubmit(cmp.model).pipe(
                        tap(() => modalRef.hide()),
                        delay(100), // due to animation (PORTAL-193)s
                    );
                },
            ),
        );
    }

    createForm<TModel, TResult>(payload: OpenFormPayload<TModel, TResult, FormlyFieldConfig[]>) {
        return this._createForm({ ...payload, ...{ component: FormModalComponent } });
    }

    deleteForm<TModel, TResult>(payload: OpenFormPayload<TModel, TResult, FormlyFieldConfig[]>) {
        return this._deleteForm({ ...payload, ...{ component: FormModalComponent } });
    }

    updateForm<TModel, TResult>(payload: OpenFormPayload<TModel, TResult, FormlyFieldConfig[]>) {
        return this._updateForm({ ...payload, ...{ component: FormModalComponent } });
    }

    viewForm<TModel, TResult>(payload: Omit<OpenFormPayload<TModel, TResult, FormlyFieldConfig[]>, 'onSubmit'>) {
        return this._viewForm({ ...payload, ...{ component: FormModalComponent } });
    }

    createWizardForm<TModel, TResult>(payload: OpenFormPayload<TModel, TResult, WizardSteps[]>) {
        return this._createForm({ ...payload, ...{ component: WizardModalComponent } });
    }

    updateWizardForm<TModel, TResult>(payload: OpenFormPayload<TModel, TResult, WizardSteps[]>) {
        return this._updateForm({ ...payload, ...{ component: WizardModalComponent } });
    }

    viewWizardForm<TModel, TResult>(payload: Omit<OpenFormPayload<TModel, TResult, WizardSteps[]>, 'onSubmit'>) {
        return this._viewForm({ ...payload, ...{ component: WizardModalComponent } });
    }

    /**
     * @return component - any entry component
     * @return modalRef;
     * @return onClose$ - throws on place-book-forms closing
     */
    openModalComponent<T extends { modalRef: BsModalRef }>(component: Ctor<T>, ignoreBackdropClick = true)
        : { cmp: T, modalRef: BsModalRef, onClose$: Observable<any> } {
        const modalRef = this.bsModalService.show(component, { ignoreBackdropClick });
        const cmp: T = modalRef.content;
        const onClose$ = this.bsModalService.onHide.pipe(
            first(),
            /*tslint:disable no-string-throw*/
            map(() => {
                throw CLOSE_ERROR;
            }),
        );
        cmp.modalRef = modalRef;
        return { cmp, modalRef, onClose$ };
    }

    private openFormModalAbstract<TModel, TResult, TCmp extends FormModalAbstract>
    (payload: OpenAbstractFormPayload<TModel, TResult, TCmp>): { cmp: TCmp, onSuccessOrClose$: Observable<TResult> } {
        const { cmp, modalRef, onClose$ } = this.openModalComponent(payload.component);
        const onSuccess$ = new Subject<TResult>();
        cmp.title = payload.title;
        cmp.fields = payload.form;
        cmp.template = payload.template;
        cmp.buttonTitle = payload.buttonTitle;
        cmp.cancelButtonTitle = !!payload.cancelButtonTitle ? payload.cancelButtonTitle : null;
        cmp.modalSize = payload.modalSize;
        cmp.modalRef = modalRef;
        cmp.err$ = cmp.submit.pipe(
            exhaustMap(() => {
                    return payload.onSubmit(cmp.model)
                        .pipe(
                            map((answer) => {
                                modalRef.hide();
                                onSuccess$.next(answer);
                                return;
                            }),
                            laravelErrorsToInnerHTML(),
                            catchError(e => {
                                if (typeof e === 'string') {
                                    return of(e);
                                }
                                throw e;
                            }),
                            delay(100), // due to animation (PORTAL-193)
                        );
                },
            ),
        );

        onClose$.pipe(catchError((e) => {
            FormUtils.markFormUntouched(cmp.form);
            return of(e);
        })).subscribe();
        const onSuccessOrClose = merge(onSuccess$, onClose$);
        return {
            cmp, onSuccessOrClose$: (onSuccessOrClose.pipe(
                catchError(() => of(CLOSE_ERROR)),
                filter(x => x !== CLOSE_ERROR),
            ) as Observable<TResult>),
        };
    }

    private _createForm<TModel, TResult, TCmp extends FormModalAbstract>
    (payload: OpenAbstractFormPayload<TModel, TResult, TCmp>): Observable<TResult> {
        payload.buttonTitle = payload.buttonTitle || ModalService.titles.create;
        const { cmp, onSuccessOrClose$ } = this.openFormModalAbstract(payload);
        cmp.customHtml = payload.customHtml;
        return onSuccessOrClose$;
    }

    private _deleteForm<TModel, TResult, TCmp extends FormModalAbstract>
    (payload: OpenAbstractFormPayload<TModel, TResult, TCmp>): Observable<TResult> {
        payload.buttonTitle = payload.buttonTitle || ModalService.titles.create;
        const { cmp, onSuccessOrClose$ } = this.openFormModalAbstract(payload);
        cmp.customHtml = payload.customHtml;
        return onSuccessOrClose$;
    }

    private _updateForm<TModel, TResult, TCmp extends FormModalAbstract>
    (payload: OpenAbstractFormPayload<TModel, TResult, TCmp>): Observable<TResult> {
        payload.buttonTitle = payload.buttonTitle || ModalService.titles.update;
        const { cmp, onSuccessOrClose$ } = this.openFormModalAbstract(payload);
        cmp.model = payload.model;
        cmp.customHtml = payload.customHtml;
        return onSuccessOrClose$;
    }

    private _viewForm<TModel, TCmp extends FormModalAbstract>
    (payload: Omit<OpenAbstractFormPayload<TModel, {}, TCmp>, 'onSubmit'>): Observable<any> {
        const { cmp, modalRef, onClose$ } = this.openModalComponent(payload.component);
        cmp.fields = payload.form;
        cmp.model = payload.model;
        cmp.title = payload.title;
        cmp.formlyClass = 'forms-as-info';
        cmp.buttonTitle = payload.buttonTitle;
        cmp.template = payload.template;
        cmp.customHtml = payload.customHtml;
        cmp.submit.subscribe(() => modalRef.hide());
        disableFormAndSetSizeToTheTextAreas(cmp);
        return onClose$.pipe(finalize(() => cmp.form.enable()));
    }
}
