import {ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Observable, Subject} from 'rxjs';

export type modalSizes = 'sm' | 'lg' | 'xl';

export abstract class FormModalAbstract {
    form: UntypedFormGroup | UntypedFormArray;
    model: any = {};
    template: TemplateRef<any>;
    customHtml: any;
    fields: any[] = [];
    /**/
    modalRef: BsModalRef;
    submit = new Subject<boolean>();
    cancel = new Subject<boolean>();
    err$: Observable<any>;
    buttonTitle: string;
    cancelButtonTitle = null;
    title = '';
    formlyClass = '';
    modalSize: modalSizes = 'sm';
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-forms-modal',
    templateUrl: './form-modal.component.html',
})
export class FormModalComponent extends FormModalAbstract {
    form: UntypedFormGroup = new UntypedFormGroup({});
    fields: FormlyFieldConfig[] = [];

    constructor(private ref: ChangeDetectorRef) {
        super();
        ref.detach();
        setInterval(() => {
            this.ref.detectChanges();
        }, 0);

        this.cancel.subscribe(() => this.modalRef.hide());
    }
}
