/*tslint:disable */
import {Injectable} from '@angular/core';
import {OwlDateTimeIntl} from '@danielmoncada/angular-datetime-picker';
import {Observable} from 'rxjs';

export type Ctor<T = {}> = { new(...args): T };
export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

// https://medium.com/dailyjs/typescript-create-a-condition-based-subset-types-9d902cea5b8c
type FilterFlags<Base, Condition> = {
    [Key in keyof Base]:
    Base[Key] extends Condition ? Key : never
};
export type PropsOfType<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];
export type SubType<Base, Condition> = Pick<Base, PropsOfType<Base, Condition>>;

export type Keyable = string | number | symbol;
export const isKeyable = (v: any): v is Keyable => typeof v === 'string' || typeof v === 'number' || typeof v === 'symbol';

export type ObjectOfFor<T, TT extends Keyable> = { [key in TT]: T };

export type UnpackObservable<T> = T extends Observable<infer R> ? R : any;
export type UnpackPromise<T> = T extends Promise<infer R> ? R : any;
export type UnpackObjectValues<T> = T extends { [key: number]: infer R } ? R : any;
export type UnpackArray<T> = T extends Array<infer R> ? R : any;

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
                     ? Array<DeepPartial<U>>
                     : T[P] extends ReadonlyArray<infer U>
                       ? ReadonlyArray<DeepPartial<U>>
                       : DeepPartial<T[P]>
};

export function tuple<T extends [void] | {}>(val: T): T {
    return val;
}

export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
                                                 ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
                                                 : S

export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
                                                 ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
                                                 : S

// @ts-ignore
export type SnakeToCamelCaseType<T> = { [K in keyof T as SnakeToCamelCase<K>]: T[K] }
// @ts-ignore
export type CamelToSnakeCaseType<T> = { [K in keyof T as CamelToSnakeCase<K>]: T[K] }

export type Constructor<T> = new(...args: any[]) => T;

export type GroupedAnalyticData = { name: string, comparable: number, comparative: number }[];
export type ComparativeAnalyticData = { comparable: number, comparative: number };

export enum DateView {
    MONTH = 'month',
    YEAR = 'year',
    MULTI_YEARS = 'multi-years'
}

export type DateViewType = DateView.MONTH | DateView.YEAR | DateView.MULTI_YEARS;

export type PickerMode = 'popup' | 'dialog' | 'inline';

export type Flatten<T> = {
    [P in keyof T]: T[P];
}

export type PartialByKeys<T, K extends keyof T = keyof T> = Flatten<{
    [Key in K]?: T[Key];
} & {
    [P in keyof Omit<T, K>]: T[P]
}>;

export enum Months {
    January = 1,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export enum YearHalfs {
    H1 = 1,
    H2,
}

export type DistributionByMonth<T> = {
    [key in Months]: T;
};

export enum EntryPresence {
    Gone = -1,
    Stayed = 0,
    Came = 1
}

export interface SortInterface {
    orderBy: string;
    asc: boolean;
    compare?: <T>(w: T) => number;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class RuOwlDateTimeIntl extends OwlDateTimeIntl {
    upSecondLabel = 'Плюс секунда';
    downSecondLabel = 'Минус секунда';
    upMinuteLabel = 'Плюс минута';
    downMinuteLabel = 'Минус минута';
    upHourLabel = 'Плюс час';
    downHourLabel = 'Минус час';
    prevMonthLabel = 'Предыдущий месяц';
    nextMonthLabel = 'Следующий месяц';
    prevYearLabel = 'Предыдущий год';
    nextYearLabel = 'Следующий год';
    prevMultiYearLabel = 'Предыдущие 21 год';
    nextMultiYearLabel = 'Следующие 21 год';
    switchToMonthViewLabel = 'Переключиться на выбор месяца';
    switchToMultiYearViewLabel = 'Выбрать месяц и год';
    cancelBtnLabel = 'Отменить';
    setBtnLabel = 'Установить';
    rangeFromLabel = 'От';
    rangeToLabel = 'До';
    hour12AMLabel = 'ДП';
    hour12PMLabel = 'ПП';
}
