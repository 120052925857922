import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OwlDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {EventDispatcherModule} from 'shared/modules/forms/event-dispatcher.directive';
import {YearPickerComponent} from 'shared/modules/forms/year-picker/year-picker.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, OwlDateTimeModule, EventDispatcherModule],
    declarations: [YearPickerComponent],
    exports: [YearPickerComponent],
})
export class YearPickerModule {
}
