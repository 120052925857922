import {NgModule} from '@angular/core';
import {NavbarComponent} from "app/modules/navigation/navbar/navbar.component";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {SidebarComponent} from "app/modules/navigation/sidebar/sidebar.component";
import {SidebarMenuItemComponent} from "app/modules/navigation/sidebar/sidebar-menu-item.component";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {AvatarModule} from "shared/components/avatar/avatar.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        BsDropdownModule,
        AvatarModule,
        TooltipModule
    ],
  exports: [
    NavbarComponent,
    SidebarComponent
  ],
  declarations: [NavbarComponent, SidebarComponent, SidebarMenuItemComponent],
  providers: [],
})
export class NavigationModule {
}
