import {Inject, Injectable} from '@angular/core';
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {WEBSOCKET_CONFIG} from "app/app.module";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import {AuthService} from "app/services/auth.service";
import {BindObservable} from "bind-observable";
import {shareReplay, switchMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PusherService {

    private _echo: Echo;

    @BindObservable()
    private _connected: boolean;
    private _connected$: Observable<boolean>;

    public connected$: Observable<boolean>;

    public lastError: any;

    constructor(
        @Inject(WEBSOCKET_CONFIG) public websocketConfig,
        private authService: AuthService
    ) {
        try {
            window['Pusher'] = Pusher;
            this._echo = new Echo({
                ...this.websocketConfig,
                wsHost: this.websocketConfig.wsHost ?? window.location.hostname,
                broadcaster: 'pusher',
                authEndpoint: '/api/broadcasting/auth',
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + AuthService.token
                    },
                },
                enabledTransports: ['ws', 'wss'],
                cluster: 'eu',
                disableStats: true,
                unavailableTimeout: 3000,
                encrypted: true,
                forceTLS: false
            });

            this._echo.connector.pusher.connection.bind('error', (e) => {
                this.lastError = e;
                this._connected = false;
            })

            this._echo.connector.pusher.connection.bind('state_change', (e) => {
                if(e.current === 'connecting' || e.current === 'connected') {
                    this._connected = true
                } else {
                    this._connected = false;
                    this._echo.disconnect();
                }
            })
        } catch (e) {
            this._connected = false;
        }

        this.connected$ = this._connected$.pipe(shareReplay());
    }

    public get echo() {
        return this._echo;
    }

    public get connected() {
        return this._connected;
    }

    public log(event: string, channelName: string, isPrivate: boolean = false) {
        const channel = isPrivate ? this._echo.private(channelName) :  this._echo.channel(channelName);
        channel.listen('.' + event, (e) => console.log(e))
    }

    public on(event: string, channelName: string, initialState = null, isPrivate: boolean = false): Observable<any> {
        const channelSubject = initialState === null ? new Subject<any>() : new BehaviorSubject(initialState);
        const channel = isPrivate ? this._echo.private(channelName) :  this._echo.channel(channelName);
        channel.listen('.' + event, data => channelSubject.next(data));
        return this.connected$.pipe(
            switchMap((connected) => {
                if(connected) {
                    return channelSubject;
                } else {
                    return throwError(this.lastError ?? new Error('No websocket connection!'))
                }
            })
        );
    }
}
