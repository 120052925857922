import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ChangeDetectionService {

    private _queue$ = new Subject<boolean>();

    get queue() {
        return this._queue$;
    }

    constructor() {
    }

    queueChangeDetection() {
        this._queue$.next(true);
    }
}
