import {ApplicationRef, ChangeDetectorRef, Component, isDevMode} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AuthService} from 'app/services/auth.service';
import {Observable} from 'rxjs';
import {ManualChangeDetection} from 'shared/push/manual-change-detection';
import {LoadingInterceptor} from './services/interceptors/loading.interceptor';

@Component({
    selector: 'app-root',
    template: `
        <div class="progress animated fixed-top {{ (isLoading$ | async) ? 'my-show' : 'my-hide' }}"
             style="height: 3px; z-index: 100500">
            <div class="progress-bar progress-bar-animated progress-bar-striped bg-primary" role="progressbar"
                 style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <router-outlet></router-outlet>
    `
})
export class AppComponent {
    isLoading$: Observable<number>;

    constructor(
        private ref: ChangeDetectorRef,
        private title: Title,
        li: LoadingInterceptor,
        applicationRef: ApplicationRef,
        auth: AuthService
    ) {
        ManualChangeDetection.STATIC_APPLICATION_REF = applicationRef;
        ref.detach();
        setInterval(() => {
            this.ref.detectChanges();
        }, 0);
        this.isLoading$ = li.isLoading$;

        this.title.setTitle(`АФИНА${isDevMode() ? ' - dev' : ''}`);

        (document.querySelector('#appIcon') as HTMLLinkElement).href = isDevMode() ? '/assets/logo/favicon_dev_label.ico' : '/assets/logo/favicon.ico';
    }
}
