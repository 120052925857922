import {Inject, Injectable, InjectionToken, OnDestroy} from '@angular/core';
import {RootRoutes} from 'app/app-routing.module';
import {MenuItem} from 'app/modules/navigation/sidebar/sidebar-menu-item.component';
import {PermissionsService, permStub} from 'app/services/permissions.service';
import {isBoolean} from 'lodash';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {isNotNullOrUndefined, Utils} from 'shared/utils/utils';

// tslint:disable-next-line:no-big-function
export const menuItemsFactory = (permissionsService: PermissionsService): Observable<MenuItem[]> => of(
    [
        {
            icon: 'fa fa-handshake',
            label: 'Согласования',
            route: `/${RootRoutes['competition']}/approval`,
            permissionCallback(service: PermissionsService) {
                return service.getPermissionValue(permStub.Wage.canStartCompetitionForBudget) ||
                    service.getPermissionValue(permStub.Wage.canApproveTheBudget);
            },
        },
        // {
        //     icon: 'bi bi-graph-up',
        //     label: 'Фин. отчёт',
        //     route: `/${RootRoutes['dashboard']}/financial-report`,
        //     permission: permStub.Report.canAccessSmallFinancialReport
        // },
        {
            icon: 'bi bi-people',
            label: 'Текущий ФОТ',
            route: `/${RootRoutes['wage']}/fund/list`,
            permission: permStub.Wage.canAccessWageData
        },
        {
            icon: 'fa-solid fa-brain',
            label: 'Бюджет ФОТ',
            route: `/${RootRoutes['wage']}/budget/list`,
            permission: permStub.Wage.canViewTheBudget
        },
        // {
        //     icon: 'bi bi-layers',
        //     label: 'Накладные Расходы',
        //     permission: permStub.OverheadExpenses.hasAccess,
        //     children: [
        //         {
        //             label: 'Бюджет',
        //             route: `/${RootRoutes['overhead-expenses']}/budget`,
        //         },
        //         {
        //             label: 'Согласование',
        //             route: `/${RootRoutes['overhead-expenses']}/competition`,
        //         },
        //     ]
        // },
        {
            icon: 'fa-solid fa-person-circle-check',
            label: 'Вакансии',
            route: `/${RootRoutes['wage']}/vacancy`,
            permission: permStub.Wage.canAddVacancy
        },
        // {
        //     icon: 'fa-solid fa-weight-hanging',
        //     label: 'Нагрузка',
        //     route: `/${RootRoutes['wage']}/activity`,
        //     permission: permStub.Company.financial
        // },
        // {
        //     icon: 'fa-solid fa-ranking-star',
        //     label: 'Аналитика',
        //     children: [
        //         {
        //             icon: 'fa-solid fa-chart-simple',
        //             label: 'Дашборд',
        //             route: `/${RootRoutes['wage']}/analytics`,
        //             permission: permStub.Wage.canAccessWageAnalytics
        //         },
        //         {
        //             icon: 'fa-regular fa-file-lines',
        //             label: 'СВОД',
        //             route: `/${RootRoutes['wage']}/consolidated-report`,
        //             permission: permStub.Wage.canAccessConsolidatedReport
        //         },
        //     ]
        // },
        // {
        //     icon: 'fa-solid fa-money-bill-trend-up',
        //     label: 'Billing Revenue',
        //     route: `/${RootRoutes['wage']}/fund/stats-settings`,
        //     permission: permStub.Revenue.canAccessRevenueData
        // },
        {
            icon: 'bi bi-table',
            label: 'Справ.',
            route: `/${RootRoutes['handbooks']}/`,
            permission: permStub.Company.canViewHandbooks
        },
        {
            icon: 'bi bi-gear',
            label: 'Админ.',
            permission: permStub.Root,
            children: [
                {
                    icon: 'bi bi-person-badge',
                    label: 'Пользователи',
                    route: `/${RootRoutes.admin}/users`,
                },
                {
                    icon: 'bi bi-mask',
                    label: 'Роли',
                    route: `/${RootRoutes.admin}/roles`,
                }
            ]
        }
    ]
);

export const MENU_ITEMS = new InjectionToken('MENU_ITEMS');

@Injectable()
export class SidebarService implements OnDestroy {

    private data: MenuItem[] = [];
    private allData: MenuItem[];

    private sub: Subscription;

    constructor(
        private permissionsService: PermissionsService, @Inject(MENU_ITEMS) allData$: Observable<MenuItem[]>
    ) {
        this.sub = allData$.subscribe((allData) => this.allData = allData);
    }

    subMenu$ = new BehaviorSubject<MenuItem[]>(null);

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    getPermittedMenuItems() {
        this.data = Utils.deepCopy(this.allData);
        this.removeNotPermitedMenuItems();
        this.removeEmptyMenuItems();
        return this.data;
    }

    private removeNotPermitedMenuItems() {
        this.nestedForEach((item, index, list) => {

            let isPermitted = true;

            if (isNotNullOrUndefined(item.permission)) {

                isPermitted = isBoolean(item.permission)
                    ? item.permission
                    : this.permissionsService.getPermissionValue(item.permission);
            }

            if (isNotNullOrUndefined(item.permissionCallback)) {
                isPermitted = item.permissionCallback(this.permissionsService);

            }

            if (!isPermitted) {
                list.splice(index, 1);
            }
        });
    }

    private removeEmptyMenuItems() {
        this.nestedForEach((item, index, list) => {
            if (item.children && item.children.length === 0) {
                list.splice(index, 1);
            }
        });
    }

    private nestedForEach(callback: (item: MenuItem, index?: number, list?: MenuItem[]) => void, submenu: MenuItem[] = this.data) {
        for (let i = submenu.length - 1; i >= 0; i--) {
            const menuItem = submenu[i];
            callback(menuItem, i, submenu);
            if (menuItem.children && menuItem.children.length) {
                this.nestedForEach(callback, menuItem.children);
            }
        }
    }
}
