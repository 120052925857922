import {Component} from '@angular/core';

@Component({
    selector: 'app-logo',
    template: `
        <a
                href="/"
                class="d-flex flex-column align-items-center justify-content-start text-decoration-none"
                style="padding-top: 2rem;padding-bottom: 2rem;"
        >
            <img
                    src="/assets/logo/Logo__sign_color_blue.svg"
                    alt="image description"
                    class="img-fluid"
                    style="height: 44px"
            />
        </a>
    `,
})
export class LogoComponent {
}
