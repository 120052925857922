/* tslint:disable */
import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'formly-field-checkbox',
    template: `
        <div class="form-check">
            <input
                    id="formlyFieldCheckbox"
                    class="form-check-input"
                    type="checkbox"
                    [class.is-invalid]="showError"
                    [indeterminate]="isIndeterminate"
                    [formControl]="$any(formControl)"
                    [formlyAttributes]="field"
            />
            <label for="formlyFieldCheckbox" class="form-check-label">
                {{ to.label }}
                <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
            </label>
        </div>
    `,
})
export class FormlyFieldCheckbox extends FieldType {
    get isIndeterminate() {
        return (
            this.to.indeterminate &&
            (this.model || {})[this.key as string] === undefined
        );
    }

    declare to: any;
}
