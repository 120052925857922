import {Injectable} from '@angular/core';
import {AppModule} from 'app/app.module';
import {WageAttributeTitle} from 'app/modules/wage/shared/wage.interfaces';
import {combineLatest, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {OptionInterface} from 'shared/services/refs.interfaces';

export function RefsMixins(wageAttributeTitle: WageAttributeTitle) {
    const s = AppModule.injector.get<RefsMixinsService>(RefsMixinsService);
    return (target: any, key: string, descriptor: PropertyDescriptor) => {

        if (descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            return combineLatest([
                originalMethod.apply(this, args),
                s.mixinUpdated$,
            ]).pipe(
                map(data => (data as any).concat(s.withMixins(wageAttributeTitle))),
            );
        };

        return descriptor;
    };
}

@Injectable({
    providedIn: 'root'
})
export class RefsMixinsService {

    private localData = new Map<WageAttributeTitle, OptionInterface<number, any>[]>();
    private _mixinUpdated$ = new Subject();

    get mixinUpdated$() {
        return this._mixinUpdated$.asObservable();
    }

    constructor() {
    }

    setLocalData(key: WageAttributeTitle, data: OptionInterface<number, any>[]): void {
        this.localData.set(key, data);
    }

    withMixins(key: WageAttributeTitle): OptionInterface<number, any>[] {
        if (this.localData.has(key)) {
            return this.localData.get(key);
        }
        return [];
    }
}
