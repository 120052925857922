import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';


@Component({
    selector: 'observe-input',
    template: `
        <input #inputElement class="form-control"
               [type]="type"
               [class.is-invalid]="showError"
               [formlyAttributes]="field"
               [formControl]="$any(formControl)"
        >`,
})

export class ObserveInputComponent extends FieldType implements AfterViewInit {

    @ViewChild('inputElement') inputElement: ElementRef<HTMLElement>;

    get type() {
        return this.to.type || 'text';
    }

    constructor(private http: HttpClient) {
        super();
    }

    ngAfterViewInit(): void {
        if (this.to.focusAfterRender) {
            requestAnimationFrame(() => this.inputElement.nativeElement.focus());
        }
        this.field.formControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300)
            )
            .subscribe(val => this.runUpdate(val));
    }

    runUpdate(value) {
        // tslint:disable-next-line:max-line-length
        this.http.post(this.field.templateOptions.updateEndpoint, this.field.templateOptions.updatePayload(this.key, value)).subscribe(resp => {
            this.model[this.field.templateOptions.updateField] = resp['data'][this.key];
            this.formControl.parent.get(this.field.templateOptions.updateField).setValue(resp['data'][this.key]);
        });
    }
}
