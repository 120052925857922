import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule as BsModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {LoaderOverlayModule} from 'shared/components/loader-overlay/loader-overlay.module';
import {NgDisableDirectiveModue} from 'shared/directives/ng-disable/ng-disable-directive.modue';
import {ImageModalComponent} from 'shared/modules/modal/image-modal/image-modal.component';
import {InfoModalComponent} from 'shared/modules/modal/info-modal/info-modal.component';
import {FormPartsModule} from '../forms/forms.module';
import {ActionsModalComponent} from './actions-modal/actions-modal.component';
import {ConfirmModalComponent} from './confirm-modal/confirm-modal.component';
import {DefaultModalComponent} from './default-modal/default-modal.component';
import {FormModalComponent} from './form-modal/form-modal.component';
import {ModalService} from './modal.service';
import {WizardModalComponent} from './wizard-modal/wizard-modal.component';


@NgModule({
    imports: [
        CommonModule,
        BsModalModule.forRoot(),
        FormPartsModule,
        TabsModule.forRoot(),
        NgDisableDirectiveModue,
        LoaderOverlayModule,
    ],
    exports: [DefaultModalComponent, FormPartsModule, WizardModalComponent],
    declarations: [
        DefaultModalComponent,
        InfoModalComponent,
        ConfirmModalComponent,
        FormModalComponent,
        WizardModalComponent,
        ImageModalComponent,
        ActionsModalComponent,
    ],
    providers: [ModalService]
})
export class ModalModule {}
