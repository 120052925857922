<app-modal [title]="title" [modalRef]="modalRef" [size]="modalSize">
    <ng-content select=".custom-html"></ng-content>
    <div class="modal-body">
        <form [formGroup]="form">
            <div class="custom-html" *ngIf="customHtml" [innerHTML]="customHtml"></div>
            <formly-form [class]="formlyClass"
                         [model]="model"
                         [fields]="fields"
                         [form]="form">
            </formly-form>

            <ng-container *ngIf="template">
                <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ $implicit: model }"></ng-template>
            </ng-container>

            <ng-container *ngIf="(err$ | async) as err">
                <hr>
                <div [innerHtml]="err" class="alert-danger p-3"></div>
            </ng-container>
            <ng-container *ngIf="buttonTitle && buttonTitle !== '@HIDE@'">
                <hr>
                <div *ngIf="cancelButtonTitle" class="d-flex flex-row justify-content-between">
                    <button *ngIf="cancelButtonTitle"
                            ngDisable
                            (disablingClick)="cancel.next(model)"
                            class="btn btn-warning">
                        {{ cancelButtonTitle }}
                    </button>
                    <button type="button" class="btn btn-primary"
                            ngDisable
                            (disablingClick)="submit.next(model)"
                            [disabled]="!form.valid">{{ buttonTitle }}</button>
                </div>
                <button *ngIf="!!cancelButtonTitle === false" type="button" class="btn btn-primary w-100"
                        ngDisable
                        (disablingClick)="submit.next(model)"
                        [disabled]="!form.valid">{{ buttonTitle }}</button>
            </ng-container>
        </form>
    </div>
    <app-loader-overlay></app-loader-overlay>
</app-modal>
