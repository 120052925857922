import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NavbarContentService {
    private _template$: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
    template$ = this._template$.asObservable();

    private _htmlClass$ = new BehaviorSubject<string>('');
    htmlClass$ = this._htmlClass$.asObservable();

    private _htmlColor$ = new BehaviorSubject<string>('');
    htmlColor$ = this._htmlClass$.asObservable();

    private toggleSubject$ = new BehaviorSubject<boolean>(false);
    toggle$ = this.toggleSubject$.asObservable();

    private showTogglerSubject$ = new BehaviorSubject<boolean>(true);
    showToggler$ = this.showTogglerSubject$.asObservable();

    private _pageTitle$: BehaviorSubject<string> = new BehaviorSubject(null);
    pageTitle$ = this._pageTitle$.asObservable();

    private _showBackBtn$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    showBackBtn$ = this._showBackBtn$.asObservable();

    setTemplate(template: TemplateRef<any>) {
        this._template$.next(template);
        this._htmlClass$.next('');
    }

    setPageTitle(value: string) {
        this._pageTitle$.next(value);
    }

    removePageTitle() {
        this._pageTitle$.next('')
    }

    setClass(htmlClass: string) {
        this._htmlClass$.next(htmlClass);
    }

    setColor(htmlColor: string) {
        this._htmlColor$.next(htmlColor);
    }

    removeTemplate() {
        this._template$.next(null);
    }

    toggleBackBtn() {
        this._showBackBtn$.next(!this._showBackBtn$.getValue());
    }

    showToggler(val: boolean) {
        this.showTogglerSubject$.next(val);
    }

}
