import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/*tslint:disable component-class-suffix no-host-metadata-property component-selector*/
@Component({
    selector: 'field-typeahead',
    template: `
        <ng-container *ngIf="field">
            <typeahead-select
                class="w-100"
                [formControl]="$any(formControl)"
                [bindLabel]="field.templateOptions.labelKey"
                [bindValue]="field.templateOptions.valueKey"
                [initialState]="field.model[field.templateOptions.items]"
                [getListEndpoint]="field.templateOptions.getListEndpoint"
                [getOneEndpoint]="field.templateOptions.getOneEndpoint"
                [queryParameters]="field.templateOptions.queryParams"
                [multiple]="field.templateOptions.multiple ? field.templateOptions.multiple : false"
            >
            </typeahead-select>
        </ng-container>
    `,

})
export class FormlyTypeaheadComponent extends FieldType implements OnInit {

    ngOnInit(): void {
        if (this.field.model[this.field.templateOptions.items] && this.field.model[this.field.templateOptions.items].length > 0) {
            this.formControl.patchValue(this.field.model[this.field.templateOptions.items].map(item => item[this.field.templateOptions.valueKey]));
        }
    }

}
