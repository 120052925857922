import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'app/modules/navigation/sidebar/sidebar-menu-item.component';
import {SidebarService} from 'app/modules/navigation/sidebar/sidebar.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-sidebar',
    template: `
        <div class="card border-0 pe-0 h-100">
            <div class="d-flex card-body p-0 border-0 h-100 shadow-sharp">
                <div class="d-flex flex-column flex-grow-1 bg-white justify-content-between" style="width: 80px">
                    <ng-content select="[top]"></ng-content>
                    <div class="scrollable-content">
                        <ul *ngIf="menuItems" class="nav nav-pills nav-flush flex-column mb-auto text-center scrollable">
                            <li class="nav-item" appSidebarMenuItem *ngFor="let menuItem of menuItems"
                                [menuItem]="menuItem"></li>
                        </ul>
                    </div>
                    <ng-content select="[bottom]"></ng-content>
                </div>
            </div>
        </div>
    `
})

export class SidebarComponent implements OnDestroy {
    menuItems: MenuItem[];
    private destroyed$ = new Subject<boolean>();

    constructor(
        private nav: SidebarService, private router: Router
    ) {
        this.menuItems = this.nav.getPermittedMenuItems();
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
}
