import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {format} from 'date-fns';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Subject} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {ApiResponse} from 'shared/interfaces/response';
import {resolveDate} from 'shared/utils/form.utils';
import {Utils} from 'shared/utils/utils';

export interface NewCheckboxItemModel {
    effective_since?: string;
    field_value?: any;
}

@Component({
    selector: 'history-checkboxes-input',
    template: `
        <div class="card mt-1 mb-1 position-relative" style="min-height: 150px">
            <div class="loader centered animated {{ (isLoading$ | async) ? 'my-show' : 'my-hide' }}"></div>
            <div class="card-body">
                <table class="table table-striped mb-0">
                    <thead>
                    <tr>
                        <th>
                            Значение
                        </th>
                        <th>
                            Актуально с
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of items; index as i">
                        <td>
                            <table class="mb-0">
                            <tr *ngFor="let checkboxName of getKeys(item.field_value)">
                                <td [className]="item.field_value[checkboxName] ? 'text-success' : 'text-danger'">{{item.field_value[checkboxName] ? 'Да' : 'Нет'}}</td>
                                <td>{{ 'wageFundEntry.' + checkboxName | translate }}</td>
                            </tr>
                            </table>
                        </td>
                        <td>
                            {{ item.effective_since | date:'shortDate' }}
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger" (click)="deleteItem(i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
                <form [formGroup]="$any(formNew)" (ngSubmit)="submit.next()">
                    <div class="card-footer" [hidden]="!simpleVisible">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 pl-0">
                                <formly-form
                                    [model]="newModel"
                                    [fields]="fields"
                                    [form]="formNew">
                                </formly-form>
                            </div>
                            <div class="col-4 pr-0">
                                <button type="button" class="btn btn-secondary w-100 mb-3" (click)="add.next(newModel)" [disabled]="!newModel.effective_since">
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="input-group my-2" (click)="simpleVisible = !simpleVisible;">
                        <input class="form-control"
                               [class.is-invalid]="showError"
                               [formlyAttributes]="field"
                               [value]="visibleValue | async"
                               [placeholder]="'Добавить конфигурацию'"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button"><i class="fa fa-edit"></i>
                            </button>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary w-100">Сохранить конфигурацию
                    </button>
                </form>

        </div>
    `,
})
export class HistoryCheckboxesInputComponent extends FieldType implements OnInit {

    visibleValue = new BehaviorSubject<string>('');
    isVisible = new BehaviorSubject<boolean>(false);
    simpleVisible = false;
    isLoading$ = new BehaviorSubject(true);
    items: NewCheckboxItemModel[];
    newOptions: any[];
    newModel: NewCheckboxItemModel = {effective_since: '', field_value: {}};
    formNew: UntypedFormGroup | UntypedFormArray = new UntypedFormGroup({});
    fields: FormlyFieldConfig[];
    submit = new Subject();
    add = new Subject();

    Object: Object

    constructor(private http: HttpClient, private toastr: ToastrService, private translate: TranslateService) {
        super();
    }

    ngOnInit(): void {
        //this.visibleValue.next(this.model[this.field.templateOptions.visibleValue]);
        this.getEnums();
        this.getHistoryItems();
        this.submit.subscribe(() => this.saveItems());
        this.add.subscribe((model: NewCheckboxItemModel) => this.addItem(model));
    }

    getKeys(obj) {
        return Object.keys(obj);
    }

    getHistoryItems() {
        const httpOptions = {
            headers: new HttpHeaders({
                'no-intercept': '1',
            }),
        };
        // tslint:disable-next-line:max-line-length
        this.http.get<ApiResponse<NewCheckboxItemModel[]>>(
            this.field.templateOptions.endpointUrl(this.model),
            httpOptions,
        )
            .pipe(
                map(x => x.data.map(y => y)),
                finalize(() => this.isLoading$.next(false)),
            ).subscribe(items => this.items = items);
    }

    getEnums() {
        this.http.get(this.field.templateOptions.endpointListUrl(this.key)).pipe(
            map((x: any) => x.data),
        ).subscribe(options => {
            const checkboxes = [];
            options.forEach((item) => {
                this.newModel.field_value[item] = true;
                checkboxes.push(
                    {
                        key: 'field_value.' + item,
                        type: 'checkbox',
                        defaultValue: false,
                        templateOptions: {
                            label: this.translate.instant('wageFundEntry.' + item),
                        },
                    },
                )
            })
            this.fields = [
                ...checkboxes,
                {
                    key: 'effective_since',
                    type: 'date',
                    templateOptions: {
                        required: true,
                        label: 'Актуально с',
                        pickerMode: 'calendar',
                    },
                },
            ];
        });
    }

    addItem(model: NewCheckboxItemModel) {
        if (model.effective_since) {
            model.effective_since = format(resolveDate(model.effective_since), 'yyyy-MM-dd');
        }
        this.items.push(Utils.deepCopy(model));
    }

    deleteItem(index: number) {
        this.items.splice(index, 1);
    }

    saveItems() {
        this.http.put(this.field.templateOptions.endpointPutUrl(this.model, this.key), {items: this.items }).subscribe(() => {
            this.toastr.success('Конфигурация сохранена.');
        });
    }
}
