import {Component, OnInit} from '@angular/core';
import {AuthService} from 'app/services/auth.service';
import {DashboardService} from 'app/services/dashboard.service';
import {tap} from 'rxjs/operators';
import {ModalService} from 'shared/modules/modal/modal.service';

@Component({
    selector: 'app-user-chip',
    template: `
        <!--        <div class="btn-group w-100" dropdown [dropup]="true" *ngIf="(ds.userInfo$ | async) as userInfo">-->
        <!--            <button id="button-dropup" dropdownToggle type="button" class="btn btn-outline-light"-->
        <!--                    aria-controls="dropdown-dropup">-->
        <!--                <div class="d-flex flex-row justify-content-between align-items-center">-->
        <!--                    <app-user-avatar [photoUrl]="userInfo.photo_file_url" class="user-photo-xs"></app-user-avatar>-->
        <!--                    <span>{{ userInfo.name }}</span>-->
        <!--                </div>-->
        <!--            </button>-->
        <!--            <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu"-->
        <!--                role="menu" aria-labelledby="button-dropup">-->

        <!--                <li role="menuitem"><a class="dropdown-item" routerLink="/login">Выйти</a>-->
        <!--                </li>-->
        <!--            </ul>-->
        <!--        </div>-->
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="dropdown cursor-pointer text-center">
                <a href="mailto:afina.support@odysmtech.ru" class="text-decoration-none">
                    <div class="py-2" style="line-height: 1rem">
                        <span class="fs-2 text-primary"><i class="bi bi-envelope-at"></i></span>
                        <br><span class="nav-caption">Поддержка</span>
                    </div>
                </a>
            </div>
            <div *ngIf="ds.userInfo$ | async as userInfo" class="">
                <a href="/settings" class="link-dark text-decoration-none">
                    <div class="py-2" style="line-height: 1rem">
                        <app-user-avatar
                            [photoUrl]="userInfo.photo_file_url"
                            class="ms-auto"
                        ></app-user-avatar>
                        <span class="nav-caption">{{'Профиль'}}</span>
                    </div>


                </a>
            </div>
            <div class="dropdown cursor-pointer">
                <div class="py-2" style="line-height: 1rem" (click)="logout()">
                    <span class="fs-2 text-secondary"><i class="bi bi-door-closed"></i></span>
                    <br><span class="nav-caption">{{'Выход'}}</span>
                </div>
            </div>
        </div>

    `,
})
export class UserChipComponent implements OnInit {
    constructor(
        private modalService: ModalService,
        public ds: DashboardService,
        private auth: AuthService
    ) {
    }

    ngOnInit() {
    }

    logout() {
        this.modalService.confirm('Вы уверены, что хотите выйти?').pipe(
            tap(ans => {
                if (ans === true) {
                    this.auth.logout();
                }
            })).subscribe(() => {});
    }
}
