import {AfterViewChecked, ChangeDetectorRef, Component, TemplateRef} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormModalAbstract} from '../form-modal/form-modal.component';

export interface WizardSteps {
    label: string;
    form?: FormlyFieldConfig[];
    template?: TemplateRef<any>;
}

@Component({
    selector: 'app-wizard-modal',
    templateUrl: './wizard-modal.component.html',
})
export class WizardModalComponent extends FormModalAbstract implements AfterViewChecked {
    declare form: UntypedFormArray;
    /* Formly boilerplate */
    private _fields: WizardSteps[];
    // @ts-ignore
    set fields(fields: WizardSteps[]) {
        this._fields = fields;
        this.form = new UntypedFormArray(fields.map(() => new UntypedFormGroup({})));
    }

    get fields() {
        return this._fields;
    }

    constructor(
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
}
