import {HttpClient} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from 'app/services/dashboard.service';
import {InactivityService} from 'app/services/inactivity.service';
import {PermissionsService} from 'app/services/permissions.service';
import {ToastrService} from 'ngx-toastr';
import {of} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {catchAndShowError} from 'shared/utils/operators';

export function CheckAuth(authService: AuthService) {
    return authService.checkAuth().toPromise();
}

export const LoginUrl = 'login';
export const PasswordChangeUrl = 'password-change';
export const HappyBirthdayIliaUrl = 'Happy-Birthday-Ilia';


@Injectable()
export class AuthService {

    private redirect: string = null;

    static get token() {
        // @ts-ignore
        return localStorage.getItem('token');
    }

    static set token(v: string) {
        localStorage.setItem('token', v);
    }

    static removeToken() {
        localStorage.removeItem('token');
    }

    get router(): Router {
        return this.injector.get(Router);
    }

    get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }

    get isAuthorized(): boolean {
        return !!localStorage.getItem('token');
    }

    get permissions(): PermissionsService {
        return this.injector.get(PermissionsService);
    }

    get ds(): DashboardService {
        return this.injector.get(DashboardService);
    }

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private inactivityService: InactivityService,
    ) {
    }

    checkAuth() {
        if (!AuthService.token) {
            this.redirect = window.location.pathname + window.location.search;
            this.router.navigateByUrl('/' + LoginUrl);
            return of();
        } else {
            return of();
        }
    }

    requestOneTimeCode(login: any, password: any) {
        return this.http.post('/api/auth/code', {email: login, password}, {responseType: 'text'})
                   .pipe(
                       catchError(e => {
                           const errorResponse = JSON.parse(e.error);
                           if (errorResponse['error_message']) {
                               this.toastr.error(errorResponse['error_message'], '');
                           }
                           throw e;
                       }),
                   );
    }

    login(login: any, password: any, oneTimeCode: any) {
        return this.http.post('/api/auth', {email: login, password, one_time_code: oneTimeCode})
                   .pipe(
                       catchAndShowError(this.toastr),
                       tap((res: any) => {
                           AuthService.token = res.data.token;
                           this.inactivityService.init();
                           this.inactivityService.afterInactivityTimeout$.pipe(filter(() => this.isAuthorized)).subscribe(() => this.logout());
                       }),
                       switchMap(() => this.permissions.loadPermissions()),
                       tap(userAccess => {
                           let goTo = '/';
                           if (!!this.redirect) {
                               goTo = this.redirect;
                               this.redirect = null;
                           }
                           if (!!this.redirect === false && !!userAccess.settings.StartingPage) {
                               goTo = userAccess.settings.StartingPage;
                           }

                           this.router.navigateByUrl(goTo);
                       })
                   );
    }

    requestPasswordChange(account: { account: string }) {
        return this.http.post('/api/v2/user/auth/reset-password', account);
    }

    changePassword(payload: { new_password: string, token: string }) {
        return this.http.post('/api/v2/user/auth/change-password', payload);
    }

    logout() {
        window.location.href = '/login';
        this.inactivityService.onDeactivate$.next(true);
    }
}
