import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseValueAccessor} from 'shared/modules/forms/base-value-accessor.class';
import {DateView, DateViewType, PickerMode} from "shared/types";
import {OwlDateTimeComponent} from "@danielmoncada/angular-datetime-picker";

@Component({
    selector: 'app-month-picker',

    template: `
        <!--suppress TypeScriptValidateTypes -->
        <div class="input-group iconed" [ngClass]="{'input-group-sm': small}">
            <i class="bi bi-calendar"></i>
            <input class="form-control" [owlDateTimeTrigger]="dt" value="{{ (value | date : dateFormat) || '&nbsp;' }}"/>
            <i *ngIf="nullable && value" class="bi bi-x-circle" (click)="handleClear()"></i>
        </div>
        <input [owlDateTime]="dt" class="invisible disabled w-100 position-absolute" [owlDateTimeTrigger]="dt"
               style="max-height: 0; bottom: 0" [formControl]="$any(formControl)">
        <owl-date-time #dt [pickerType]="'calendar'" [startView]="startView" (monthSelected)="pickMonth($event, dt)"
                       panelClass="month-picker" [pickerMode]="pickerMode"></owl-date-time>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthPickerComponent),
            multi: true,
        },
    ],
})
export class MonthPickerComponent extends BaseValueAccessor implements OnDestroy {
    @Output() cleared = new EventEmitter();
    @Input() dateFormat = 'LLL yyyy';
    @Input() nullable: boolean = false;
    @Input() placeholder = 'выбрать месяц';
    @Input() small = true;
    @Input() pickerMode: PickerMode = 'popup'
    startView: DateViewType = DateView.YEAR

    constructor() {
        super();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    pickMonth(d: Date, datepicker: OwlDateTimeComponent<any>) {
        this.formControl.patchValue(d);
        datepicker.close();
     }

    handleClear() {
        this.formControl.patchValue(null);
        this.cleared.emit(true);
    }
}
