import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {NavbarContentService} from 'app/modules/navigation/navbar/navbar-content.service';
import {SidebarService} from 'app/modules/navigation/sidebar/sidebar.service';
import {DashboardService} from 'app/services/dashboard.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    template: `
        <div class="h-100">
            <div class="p-0 border-0">
                <div class="p-3 pt-1">
                    <div class="d-grid gap-3 align-items-center">
                        <div class="d-flex justify-content-between">

                            <div *ngIf="(navbar.pageTitle$ | async) as pageTitle " class="me-3 sd-flex align-items-center text-primary">
                                <h3>
                                    {{ pageTitle }}
                                </h3>
                            </div>
                            
                            <button *ngIf="navbar.showBackBtn$ | async" class="btn btn-link ps-0 fs-6 text-decoration-none" (click)="location.back()"><i class="bi bi-arrow-left-circle"></i> Назад</button>
                            <button *ngIf="isRootUser$ | async" class="btn btn-link pe-0 fs-6 text-decoration-none" (click)="flushCache()"><i class="bi bi-trash2"></i> Очистить кэш</button>
                            
                            
                            <!-- <ng-container *ngIf="(sidebarService.subMenu$ | async) as subMenu"> -->
                            <!--     <div class="d-flex align-items-center"> -->
                            <!--         <div class="btn-group" dropdown> -->
                            <!--             <button id="button-basic" dropdownToggle type="button" -->
                            <!--                     class="btn btn-primary dropdown-toggle">Меню <span class="caret"></span> -->
                            <!--             </button> -->
                            <!--             <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"> -->
                            <!--                 <li *ngFor="let subMenuItem of subMenu"><a class="dropdown-item" [routerLink]="subMenuItem.route">{{ subMenuItem.label }}</a></li> -->
                            <!--             </ul> -->
                            <!--         </div> -->
                            <!--     </div> -->
                            <!-- </ng-container> -->

                           

                            <ng-container *ngIf="(navbar.template$ | async) as template; else widthStub">
                                <ng-template [ngTemplateOutlet]="template"></ng-template>
                            </ng-container>
                            <ng-template #widthStub>
                                <div class="w-75"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})

export class NavbarComponent implements OnInit {

    isRootUser$ = this.ds.userInfo$.pipe(map(userInfo => userInfo.root));

    constructor(
        public navbar: NavbarContentService,
        public sidebarService: SidebarService,
        public location: Location,
        private ds: DashboardService
    ) {
    }

    ngOnInit() {

    }

    public flushCache(): void {
        this.ds.flushAllCache();
    }
}
