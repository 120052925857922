import {CurrencyPipe} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';
import {MONEY_COEFF} from 'app/app.module';

@Component({
    selector: 'app-money-input',
    providers: [CurrencyPipe],
    template: `
        <input
                (focusin)="focus = true;"
                (focusout)="focus = false;"
                [formControl]="innerFormControlForEdit"
                [formlyAttributes]="field"
                [class.is-invalid]="showError"
                currencyMask
                [options]="{precision: 0}"
                type="money"
                class="form-control"
        >
        <ng-container *ngIf="to['withPercents']">
            <div class="btn-group btn-group-sm">
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(0)">0%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(10)">+10%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(20)">+20%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(30)">+30%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(40)">+40%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(50)">+50%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(60)">+60%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(70)">+70%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(80)">+80%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(90)">+90%</button>
                <button class="btn btn-link btn-sm" (click)="addPercentAmount(100)">+100%</button>
            </div>
        </ng-container>
    `
})

export class MoneyInputComponent extends FieldType<FieldTypeConfig> {

    innerFormControlForEdit: UntypedFormControl;

    focus = false;
    coeff: number = 1;
    originValue: number;

    constructor(
        @Inject(MONEY_COEFF) _coeff
    ) {
        super();
        this.coeff = _coeff;
    }

    ngOnInit() {
        this.innerFormControlForEdit = new UntypedFormControl(this.formControl.value / this.coeff);
        this.originValue = this.innerFormControlForEdit.value;
        this.innerFormControlForEdit.valueChanges.subscribe(value => {
            this.formControl.patchValue(value * this.coeff);
        })
        this.formControl.valueChanges.subscribe(value => {
            this.innerFormControlForEdit = new UntypedFormControl(value / this.coeff);
            this.innerFormControlForEdit.valueChanges.subscribe(value => {
                this.formControl.patchValue(value * this.coeff);
            })
        })
    }

    addPercentAmount(percents: number) {
        this.innerFormControlForEdit.patchValue(this.originValue + (this.originValue / 100) * percents);
    }
}
