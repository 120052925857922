// @ts-ignore
import {Injectable, InjectionToken} from '@angular/core';
import {BudgetCompetition} from 'app/modules/competition/competition.interfaces';
import {VacancyInterface} from 'app/modules/wage/vacancy/vacancy-fetch.service';
import {ComparativeAnalyticData, GroupedAnalyticData} from 'app/shared/types';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {CalculusVersion} from 'shared/calculus/calculus-versions';
// @ts-ignore
import {Wage2023Utils} from 'shared/calculus/versions/2023/wage2023-utils.service';
import {PeriodInterface} from 'shared/components/calendar/calendar.component';
import {CellDataPayload, ColumnConfig, PropStub} from 'shared/modules/table/table-config.model';
import {makeEnum} from 'shared/utils/make-enum';
import {md5} from 'shared/utils/utils';

export enum Months {
    January = 1,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export enum YearHalfs {
    H1 = 1,
    H2,
}

export type PayrollPerMonth = {
    [key in Months]: number;
};

export type PayrollPerYearHalf = {
    [key in YearHalfs]: number;
};

export type DistributionByMonth<T> = {
    [key in Months]: T;
};

export const payrollPerYearHalfDefault: PayrollPerYearHalf = {1: 0, 2: 0};

export enum Quarters {
    Q1 = 1,
    Q2,
    Q3,
    Q4,
}

export enum QuarterName {
    Q1 = 'Q1',
    Q2 = 'Q2',
    Q3 = 'Q3',
    Q4 = 'Q4',
    Year = 'Year',
}

export enum EmploymentStatusEnum {
    ACCEPTED_OFFER = 1,
    WORKING = 2,
    CHILDCARE = 3,
    FIRED = 4,
}

export enum WageWorkRemotelyEnum {
    DOMESTIC = 1,
    ABROAD = 2,
    NOT = 99,
    ETC = 100
}

export type PayrollPerQuarter = {
    [key in Quarters]: number;
};

export type PayrollPerQuarterName = {
    [key in QuarterName]: number;
};

export type FreezeNetPayrollPayout =
    DistributionByMonth<string>
    & PayrollPerQuarterName;

export type FreezeGrossPayrollPayout = PayrollPerQuarterName;

export interface ContributionParams {
    limit_base: number;
    month_payroll: number;
    month_payroll_lesser_under_limit_contr_percent: number;
    month_payroll_lesser_over_limit_contr_percent: number;
    month_payroll_greater_under_limit_contr_percent: number;
    month_payroll_greater_over_limit_contr_percent: number;
}

export type ContributionParamsPerMonth = {
    [key in Months]: ContributionParams[];
};


export enum WageEntryTypeEnum {
    employee = 1,
    vacancy,
    outsource,
}

export interface WageFundEntry {
    wage_entry_id: string;
    year: number;
    wage_holder_private_person_id: number;
    wage_holder_display_name: string;
    private_person_id: number;
    private_person_display_name: string;
    employee_id: number;
    employee_code: string;
    entry_type: WageEntryTypeEnum;
    private_person_photo_url: string;
    company_name: string;
    company_opex_name: string;
    contribution_params: ContributionParamsPerMonth;
    agency_name: string;
    vacation_ratio: number;
    department_name: string;
    department_group_name: string;
    department_ma_id: number;
    department_ma_name: string;
    sub_department_ma_id: number;
    sub_department_ma_name: number;
    sub_department_detailed_ma_id: number;
    sub_department_detailed_ma_name: number;
    cfo: {
        cfo_id: number;
        cfo_name: string;
        cfo_group_name: string;
    };
    division_name: string;
    position_name: string;
    position_grade_name: string;
    head_private_person_display_name: string;
    functional_head_person_display_name: string;
    full_name: string;
    company_id: number;
    agency_id: number;
    agency_for_accounting_id: number;
    agency_for_accounting_name: string;
    agency_opex_id: number;
    agency_opex_name: string;
    department_id: number;
    division_id: number;
    position_id: number;
    position_grade_id: number;
    head_private_person_id: number;
    functional_head_private_person_id: number;
    hired_date: string;
    fired_date: string;
    forecast_fired_date: string;
    salary_change_date: string;
    department_change_date: string;
    fired: boolean;
    comment: string;
    is_vacancy: boolean;
    vacancy: VacancyInterface;
    salary_change_rationale: string;
    employment_status_description: string;
    employment_status_id: number;
    history_employment_status_id: EmploymentStatusEnum;
    history_employment_status_date: string;
    enter_date: string;
    reason_for_dismissal_description: string;
    reason_for_dismissal_id: number;
    is_top: boolean;
    work_remotely: WageWorkRemotelyEnum;
    work_remotely_label: string;
    salary_net_payroll: number;
    salary_gross_payroll: number;
    previous_salary_net_payroll: number;
    previous_allowance_net: number;
    previous_wage_net: number;
    previous_wage_gross: number;
    allowance_net: number;
    wage_net: number;
    wage_gross: number;
    pit_custom_rate: number;
    salary_per_month: PayrollPerMonth;
    salary_per_month_gross?: PayrollPerMonth;
    premium_per_year: number;
    salary_freeze_amount: number;
    salary_freeze_percentage: number;
    salary_freeze_start_date: string;
    salary_freeze_end_date: string;
    estimation_grade: string;
    forecast_net_payroll_net_bonus: number;
    forecast_gross_payroll_gross_bonus_soc: number;
    forecast_gross_payroll_soc: number;
    salary_per_month_net_payroll: PayrollPerMonth;
    salary_per_month_gross_payroll: PayrollPerMonth;
    freeze_per_month_net_payroll: PayrollPerMonth;
    freeze_net_payroll_payout: FreezeNetPayrollPayout;
    freeze_gross_payroll_payout: FreezeGrossPayrollPayout;
    net_unfreezing_payroll_per_month: PayrollPerMonth;
    gross_unfreezing_payroll_per_month: PayrollPerMonth;
    net_remain_unfreezing_payroll_per_month: PayrollPerMonth;
    gross_remain_unfreezing_payroll_per_month: PayrollPerMonth;
    surcharge_net_per_month_deduction: PayrollPerMonth;
    net_payroll_for_accounting: PayrollPerMonth;
    gross_payroll_for_accounting: PayrollPerMonth;
    net_cash_payroll_per_month: PayrollPerMonth;
    gross_cash_payroll_per_month: PayrollPerMonth;
    net_payroll_for_accounting_without_vacation_reserves: PayrollPerMonth;
    gross_payroll_for_accounting_without_vacation_reserves: PayrollPerMonth;
    pit_excess: PayrollPerMonth;
    fss_payroll: PayrollPerMonth;
    pit_per_month_payroll: PayrollPerMonth;

    bonus_per_quarter: PayrollPerQuarter;
    bonus_per_quarter_net_kpi_act: PayrollPerQuarter;
    bonus_per_quarter_net_kpi: PayrollPerQuarter;
    bonus_per_quarter_surcharcge_net_kpi: PayrollPerQuarter;
    bonus_per_quarter_surcharcge_net_kpi_deduction: PayrollPerQuarter;
    bonus_per_quarter_net_kpi_for_accounting: PayrollPerQuarter;
    bonus_per_quarter_gross_kpi_for_accounting: PayrollPerQuarter;

    bonus_per_half: PayrollPerYearHalf;
    bonus_per_half_net_kpi_act: PayrollPerYearHalf;
    bonus_per_half_net_kpi: PayrollPerYearHalf;
    bonus_per_half_surcharcge_net_kpi: PayrollPerYearHalf;
    bonus_per_half_surcharcge_net_kpi_deduction: PayrollPerYearHalf;
    bonus_per_half_net_kpi_for_accounting: PayrollPerYearHalf;
    bonus_per_half_gross_kpi_for_accounting: PayrollPerYearHalf;

    bonus_per_year: number;
    bonus_per_year_net_kpi_act: number;
    bonus_per_year_net_kpi: number;
    bonus_per_year_surcharcge_net_kpi: number;
    bonus_per_year_surcharcge_net_kpi_deduction: number;
    bonus_per_year_net_kpi_for_accounting: number;
    bonus_per_year_gross_kpi_for_accounting: number;

    premium_per_year_net_percentage: number;
    premium_per_year_net_forecast: number;
    premium_per_year_net_estimation_grade_based: number;
    premium_per_year_net_act: number;
    premium_per_year_net: number;
    premium_per_year_surcharcge_net_deduction: number;
    premium_per_year_net_for_accounting: number;
    premium_per_year_gross_for_accounting: number;

    provision_net: number;
    provision_gross: number;
    previous_year_unified_extra_payments_net: PayrollPerMonth;
    previous_year_unified_extra_payments_gross: PayrollPerMonth;
    paid_for_provision: number;
    previous_year_unified_extra_payments_net_act: PayrollPerMonth;
    previous_year_unified_extra_payments_gross_act: PayrollPerMonth;

    allowance_for_deduction_per_month: PayrollPerMonth;

    total_social_contributions_premium: number;
    accrued_vacation_reserve: PayrollPerMonth;
    social_contributions_vacation_reserve: DistributionByMonth<number>;
    day_balance_vacation_reserve: DistributionByMonth<number>;
    day_balance: number;
    day_balance_year_end: number;
    gross_acc_vac_res_soc_contrib_surcharge_deduction: PayrollPerMonth;
    gross_accrued_vacation_reserve_social_contributions?: PayrollPerMonth;
    gross_acc_vac_res_soc_contrib_for_accounting: PayrollPerMonth;
    payroll_vacation_reserve: PayrollPerMonth;
    gross_payroll_vacation_reserve: PayrollPerMonth;
    financial_aid_per_month_payroll: PayrollPerMonth;
    surcharge_net_kpi_per_month_payroll: PayrollPerMonth;
    surcharge_net_per_month_payroll: PayrollPerMonth;
    deduction_per_month_payroll: PayrollPerMonth;
    gross_fin_assistance: PayrollPerMonth;
    gross_severance_payment: PayrollPerMonth;
    total_net_payroll?: number;
    total_net_bonus?: number;
    total_net_bonus_original?: number;
    total_net_payroll_net_bonus?: number;
    total_gross_payroll_gross_bonus?: number;
    total_gross_payroll_gross_bonus_soc?: number;
    total_gross_payroll?: number;
    total_gross_payroll_soc?: number;
    total_net_payroll_plan?: number;
    total_net_bonus_plan?: number;
    total_net_payroll_net_bonus_plan?: number;
    total_gross_payroll_gross_bonus_plan?: number;
    total_gross_payroll_gross_bonus_soc_plan?: number;
    total_gross_payroll_gross_bonus_soc_fin?: PayrollPerMonth;
    paid_days_from_reserve: number;
    compensation_payment: PayrollPerMonth;
    pfr: PayrollPerMonth;
    fss: PayrollPerMonth;
    fssns: PayrollPerMonth;
    ffoms: PayrollPerMonth;
    social_contributions: PayrollPerMonth;
    social_contributions_act: PayrollPerMonth;
    social_contributions_allowance_for_deduction: PayrollPerMonth;
    social_contributions_previous_year_unified_extra_payments: PayrollPerMonth;
    social_contributions_compensation_payment: PayrollPerMonth;
    social_contributions_for_accounting: PayrollPerMonth;
    social_contributions_paid_leave_reserve: PayrollPerMonth;
    changed?: string[];
    changedClassList?: { [column: string]: string; };
    diffs?: WageEntryDiffMap;
    errors?: string[];
    overall?: number;
    settings?: WageAttributeSettings;
    calculus_version: CalculusVersion;
    plan_months: number[];
    plan_halfs: number[];
    plan_quarters: number[];
    outsource: boolean;
    civilLegal: boolean;
    count: number;
    is_transfered: boolean;
    is_transfered_out: boolean;
    is_transfered_in: boolean;
    premium_base_percantage: number;
}

export type HalfYearFormulaType = {
    [key in keyof typeof YearHalfs]: string
};

export type WageAttributeSettings = {
    [key in keyof typeof wageAttributes]?: {
        auto?: boolean,
        formula?: string,
        effective_from?: string,
        effective_value?: any
        previous_value?: any
    }
};

export const wageFundEntryPropStub = PropStub<WageFundEntry>();

export type WageTotals = Partial<Pick<WageFundEntry,
    // tslint:disable-next-line:max-union-size
    | 'salary_net_payroll'
    | 'salary_gross_payroll'
    | 'salary_per_month'
    | 'total_net_payroll'
    | 'premium_per_year'
    | 'salary_freeze_amount'
    | 'salary_freeze_percentage'
    | 'salary_freeze_start_date'
    | 'salary_freeze_end_date'
    | 'forecast_net_payroll_net_bonus'
    | 'forecast_gross_payroll_gross_bonus_soc'
    | 'forecast_gross_payroll_soc'
    | 'salary_per_month_net_payroll'
    | 'salary_per_month_gross_payroll'
    | 'freeze_per_month_net_payroll'
    | 'freeze_net_payroll_payout'
    | 'freeze_gross_payroll_payout'
    | 'net_unfreezing_payroll_per_month'
    | 'gross_unfreezing_payroll_per_month'
    | 'net_remain_unfreezing_payroll_per_month'
    | 'gross_remain_unfreezing_payroll_per_month'
    | 'surcharge_net_per_month_deduction'
    | 'net_payroll_for_accounting'
    | 'gross_payroll_for_accounting'
    | 'net_payroll_for_accounting_without_vacation_reserves'
    | 'gross_payroll_for_accounting_without_vacation_reserves'
    | 'pit_excess'
    | 'payroll_vacation_reserve'
    | 'fss_payroll'
    | 'bonus_per_quarter'
    | 'bonus_per_quarter_net_kpi_act'
    | 'bonus_per_quarter_net_kpi'
    | 'bonus_per_quarter_surcharcge_net_kpi'
    | 'bonus_per_quarter_surcharcge_net_kpi_deduction'
    | 'bonus_per_quarter_net_kpi_for_accounting'
    | 'bonus_per_quarter_gross_kpi_for_accounting'
    | 'bonus_per_half'
    | 'bonus_per_half_net_kpi_act'
    | 'bonus_per_half_net_kpi'
    | 'bonus_per_half_surcharcge_net_kpi'
    | 'bonus_per_half_surcharcge_net_kpi_deduction'
    | 'bonus_per_half_net_kpi_for_accounting'
    | 'bonus_per_half_gross_kpi_for_accounting'
    | 'bonus_per_year'
    | 'bonus_per_year_net_kpi_act'
    | 'bonus_per_year_net_kpi'
    | 'bonus_per_year_surcharcge_net_kpi'
    | 'bonus_per_year_surcharcge_net_kpi_deduction'
    | 'bonus_per_year_net_kpi_for_accounting'
    | 'bonus_per_year_gross_kpi_for_accounting'
    | 'allowance_for_deduction_per_month'
    | 'premium_per_year_net_percentage'
    | 'premium_per_year_net_forecast'
    | 'premium_per_year_net_estimation_grade_based'
    | 'premium_per_year_net_act'
    | 'premium_per_year_net'
    | 'premium_per_year_surcharcge_net_deduction'
    | 'premium_per_year_net_for_accounting'
    | 'premium_per_year_gross_for_accounting'
    | 'provision_net'
    | 'provision_gross'
    | 'previous_year_unified_extra_payments_net'
    | 'previous_year_unified_extra_payments_gross'
    | 'paid_for_provision'
    | 'previous_year_unified_extra_payments_net_act'
    | 'previous_year_unified_extra_payments_gross_act'
    | 'total_social_contributions_premium'
    | 'accrued_vacation_reserve'
    | 'social_contributions_vacation_reserve'
    | 'day_balance_vacation_reserve'
    | 'day_balance'
    | 'day_balance_year_end'
    | 'gross_accrued_vacation_reserve_social_contributions'
    | 'gross_acc_vac_res_soc_contrib_surcharge_deduction'
    | 'gross_acc_vac_res_soc_contrib_for_accounting'
    | 'gross_payroll_vacation_reserve'
    | 'total_net_bonus'
    | 'total_net_payroll_net_bonus'
    | 'total_gross_payroll_gross_bonus'
    | 'total_gross_payroll_gross_bonus_soc'
    | 'total_gross_payroll'
    | 'total_gross_payroll_soc'
    | 'total_net_payroll_plan'
    | 'total_net_bonus_plan'
    | 'total_net_payroll_net_bonus_plan'
    | 'total_gross_payroll_gross_bonus_plan'
    | 'total_gross_payroll_gross_bonus_soc_plan'
    | 'total_gross_payroll_gross_bonus_soc_fin'
    | 'financial_aid_per_month_payroll'
    | 'surcharge_net_kpi_per_month_payroll'
    | 'surcharge_net_per_month_payroll'
    | 'deduction_per_month_payroll'
    | 'compensation_payment'
    | 'social_contributions'
    | 'social_contributions_act'
    | 'social_contributions_allowance_for_deduction'
    | 'social_contributions_previous_year_unified_extra_payments'
    | 'social_contributions_compensation_payment'
    | 'social_contributions_for_accounting'
    | 'social_contributions_paid_leave_reserve'
    | 'overall'>>;

export const wageAttributes = makeEnum(
    'wage_entry_id',
    'year',
    'wage_holder_private_person_id',
    'wage_holder_display_name',
    'private_person_id',
    'private_person_display_name',
    'employee_code',
    'entry_type',
    'private_person_photo_url',
    'company_name',
    'contribution_params',
    'agency_name',
    'agency_opex_name',
    'agency_for_accounting_name',
    'vacation_ratio',
    'department_name',
    'division_name',
    'position_name',
    'position_grade_name',
    'head_private_person_display_name',
    'functional_head_person_display_name',
    'full_name',
    'company_id',
    'company_opex_name',
    'agency_id',
    'agency_opex_id',
    'agency_for_accounting_id',
    'department_id',
    'department_ma_id',
    'department_ma_name',
    'sub_department_ma_id',
    'sub_department_ma_name',
    'sub_department_detailed_ma_id',
    'sub_department_detailed_ma_name',
    'division_id',
    'position_id',
    'position_grade_id',
    'head_private_person_id',
    'functional_head_private_person_id',
    'hired_date',
    'fired_date',
    'forecast_fired_date',
    'department_change_date',
    'fired',
    'comment',
    'salary_change_date',
    'salary_change_rationale',
    'employment_status_description',
    'employment_status_id',
    'enter_date',
    'reason_for_dismissal_description',
    'reason_for_dismissal_id',
    'is_top',
    'work_remotely',
    'salary_net_payroll',
    'salary_gross_payroll',
    'pit_custom_rate',
    'salary_per_month',
    'salary_per_month_gross',
    'bonus_per_quarter',
    'premium_per_year',
    'salary_freeze_amount',
    'salary_freeze_percentage',
    'salary_freeze_start_date',
    'salary_freeze_end_date',
    'estimation_grade',
    'forecast_net_payroll_net_bonus',
    'forecast_gross_payroll_gross_bonus_soc',
    'forecast_gross_payroll_soc',
    'salary_per_month_net_payroll',
    'salary_per_month_gross_payroll',
    'freeze_per_month_net_payroll',
    'freeze_net_payroll_payout',
    'freeze_gross_payroll_payout',
    'net_unfreezing_payroll_per_month',
    'gross_unfreezing_payroll_per_month',
    'net_remain_unfreezing_payroll_per_month',
    'gross_remain_unfreezing_payroll_per_month',
    'surcharge_net_per_month_deduction',
    'net_payroll_for_accounting',
    'gross_payroll_for_accounting',
    'net_cash_payroll_per_month',
    'gross_cash_payroll_per_month',
    'net_payroll_for_accounting_without_vacation_reserves',
    'gross_payroll_for_accounting_without_vacation_reserves',
    'pit_excess',
    'fss_payroll',
    'bonus_per_quarter_net_kpi_act',
    'bonus_per_quarter_net_kpi',
    'bonus_per_quarter_surcharcge_net_kpi',
    'bonus_per_quarter_surcharcge_net_kpi_deduction',
    'bonus_per_quarter_net_kpi_for_accounting',
    'bonus_per_quarter_gross_kpi_for_accounting',
    'bonus_per_quarter_net_year',
    'bonus_per_half',
    'bonus_per_half_net_kpi_act',
    'bonus_per_half_net_kpi',
    'bonus_per_half_surcharcge_net_kpi',
    'bonus_per_half_surcharcge_net_kpi_deduction',
    'bonus_per_half_net_kpi_for_accounting',
    'bonus_per_half_gross_kpi_for_accounting',
    'bonus_per_year',
    'bonus_per_year_net_kpi_act',
    'bonus_per_year_net_kpi',
    'bonus_per_year_surcharcge_net_kpi',
    'bonus_per_year_surcharcge_net_kpi_deduction',
    'bonus_per_year_net_kpi_for_accounting',
    'bonus_per_year_gross_kpi_for_accounting',
    'allowance_for_deduction_per_month',
    'premium_per_year_net_percentage',
    'premium_per_year_net_forecast',
    'premium_per_year_net_estimation_grade_based',
    'premium_per_year_net_act',
    'premium_per_year_net',
    'premium_per_year_surcharcge_net_deduction',
    'premium_per_year_net_for_accounting',
    'premium_per_year_gross_for_accounting',
    'provision_net',
    'provision_gross',
    'previous_year_unified_extra_payments_net',
    'paid_for_provision',
    'previous_year_unified_extra_payments_net_act',
    'previous_year_unified_extra_payments_gross_act',
    'previous_year_unified_extra_payments_gross',
    'total_social_contributions_premium',
    'accrued_vacation_reserve',
    'social_contributions_vacation_reserve',
    'day_balance_vacation_reserve',
    'day_balance',
    'day_balance_year_end',
    'gross_acc_vac_res_soc_contrib_surcharge_deduction',
    'gross_accrued_vacation_reserve_social_contributions',
    'gross_acc_vac_res_soc_contrib_for_accounting',
    'payroll_vacation_reserve',
    'payroll_gross_vacation_reserve',
    'financial_aid_per_month_payroll',
    'surcharge_net_kpi_per_month_payroll',
    'surcharge_net_per_month_payroll',
    'deduction_per_month_payroll',
    'total_net_payroll',
    'total_net_bonus',
    'total_net_bonus_original',
    'total_net_payroll_net_bonus',
    'total_gross_payroll_gross_bonus',
    'total_gross_payroll_gross_bonus_soc',
    'total_gross_payroll',
    'total_gross_payroll_soc',
    'total_net_payroll_plan',
    'total_net_bonus_plan',
    'total_net_payroll_net_bonus_plan',
    'total_gross_payroll_gross_bonus_plan',
    'total_gross_payroll_gross_bonus_soc_plan',
    'total_gross_payroll_gross_bonus_soc_fin',
    'paid_days_from_reserve',
    'compensation_payment',
    'social_contributions',
    'social_contributions_act',
    'social_contributions_allowance_for_deduction',
    'social_contributions_previous_year_unified_extra_payments',
    'social_contributions_compensation_payment',
    'social_contributions_for_accounting',
    'social_contributions_paid_leave_reserve',
    'changed',
    'diffs',
    'errors',
    'overall',
    'settings',
    'plan_months',
    'plan_halfs',
    'plan_quarters',
    'outstaff',
    'wage_net',
    'wage_gross',
    'previous_wage_net',
    'previous_wage_gross',
    'previous_salary_net_payroll',
    'previous_allowance_net',
    'allowance_net',
    'vacancyMeta'
);

export type WageAttributeTitle = keyof typeof wageAttributes;

export interface WageAttribute {
    wage_attribute_id?: number;
    wage_attribute_title: WageAttributeTitle;
    value: any;
}

export type WageDiffMap = Map<string, WageDiffMapItem>;
export type WageEntryDiffMap = Map<WageAttributeTitle, WageAttributeDiff>;

export type WageDiffMapApi = { [key: string]: WageDiffMapItemApi };
export type WageEntryDiffMapApi = { [key: string]: WageAttributeDiffApi };

export type WageDiffMapItem = {
    wage_entry_id: string;
    private_person_id: number;
    wage_holder_private_person_id: number;
    private_person_display_name?: string;
    presence?: number;
    entry_type?: WageEntryTypeEnum;
    diffs: WageEntryDiffMap;
};

export type WageDiffMapItemApi = Omit<WageDiffMapItem, 'diffs'> & { diffs: WageEntryDiffMapApi };

export type WageAttributeDiff = {
    effective_since: Date;
    old_value: any;
    new_value: any;
    items?: Observable<any>;
    old_label?: string;
    new_label?: string;
};

export type WageAttributeDiffApi = Omit<WageAttributeDiff, 'effective_since'> & { effective_since: string };

export interface WageAnalytics {
    department_list: GroupedAnalyticData;
    agency_list: GroupedAnalyticData;
    fte_cost: ComparativeAnalyticData;
    employee_count: ComparativeAnalyticData;
    fluidity: ComparativeAnalyticData;
    vacancy_list: ComparativeAnalyticData;
    between_salary: ComparativeAnalyticData;
    other: ComparativeAnalyticData;
}

export const COLUMN_NAME_TOKEN = new InjectionToken('COLUMN_NAME_TOKEN');

export interface ActivityItem {
    private_person_id: number;
    private_person_display_name: string;
    head_private_person_full_name: string;
    functional_head_private_person_full_name: string;
    agency_name: string;
    company_name: string;
    portal_department_name: string;
    financial_department_name: string;
    portal_position_name: string;
    month: string;
    date: string;
    client_name: string;
    client_agency_name: string;
    activity_type_name: string;
    media_type_name: string;
    comment: string;
    occupancy_percent: number;
    wage_fund_entry: WageFundEntry;
    gross_payroll_for_month: number;
    x;
    quarter_premium_gross_bonus_kpi_for_month: number;
    year_premium_gross_bonus_year_for_month: number;
    gross_payroll_and_gross_bonus_for_month: number;
    gross_payroll_gross_bonus_soc_for_month: number;
}

export const activityItemPropStub = PropStub<ActivityItem>();

export interface CreateWageOutsourceApiModel {
    name: string;
    surname: string;
    patronymic: string | null;
    company_id: number | null;
    department_id: number | null;
    agency_id: number | null;
    position_id: number | null;
    head_private_person_id: number | null;
    functional_head_private_person_id: number | null;
    snils: number | null;
    birth_date: Date | null;
    year: number | null;
    hired_date: Date;
    enter_date: Date;
    employee_code: string | null;
    is_budget: boolean | null;
    budget_id: number | null;
    salary_net_payroll: number | null;
    salary_gross_payroll: number | null;
    comment: string | null;
    wage_entry_id: string;
    pit_custom_rate: number | null;
}

export const createWageOutsourceApiModelStub = PropStub<CreateWageOutsourceApiModel>();


export interface CreateVacancyApiModel {
    description: string;
    rationale: string;
    position_id: number;
    department_id: number;
    forecast_hired_date: Date;
    participant_private_person_id_list: number[];
    replaced_private_person_id_list: number[] | null;
    forecast_salary_net_payroll: number;
    forecast_salary_net_payroll_hired_month?: number;
    forecast_bonus_per_half: number | null;
    forecast_bonus_per_year: number | null;
    forecast_premium_per_year: number | null;
    agency_id: number;
    company_id: number;
    wage_holder_private_person_id: number;
    head_private_person_id: number;
    budget_id: number | null;
    wage_entry_id: string;
}

export const createVacancyApiModelStub = PropStub<CreateVacancyApiModel>();

export type WageBudgetEntry = Pick<WageFundEntry,
    // tslint:disable-next-line:max-union-size
    'wage_entry_id' |
    'wage_holder_private_person_id' |
    'wage_holder_display_name' |
    'private_person_id' |
    'private_person_display_name' |
    'employee_code' |
    'entry_type' |
    'private_person_photo_url' |
    'company_name' |
    'contribution_params' |
    'agency_name' |
    'agency_for_accounting_name' |
    'vacation_ratio' |
    'department_name' |
    'division_name' |
    'position_name' |
    'position_grade_name' |
    'head_private_person_display_name' |
    'functional_head_person_display_name' |
    'full_name' |
    'company_id' |
    'agency_id' |
    'agency_for_accounting_id' |
    'department_id' |
    'division_id' |
    'position_id' |
    'position_grade_id' |
    'head_private_person_id' |
    'functional_head_private_person_id' |
    'hired_date' |
    'fired_date' |
    'forecast_fired_date' |
    'department_change_date' |
    'fired' |
    'is_vacancy' |
    'vacancy' |
    'plan_months' |
    'plan_halfs' |
    'plan_quarters' |
    'year' |
    'comment' |
    'salary_change_rationale' |
    'employment_status_description' |
    'employment_status_id' |
    'history_employment_status_id' |
    'history_employment_status_date' |
    'enter_date' |
    'reason_for_dismissal_description' |
    'reason_for_dismissal_id' |
    'is_top' |
    'salary_freeze_percentage' |
    'salary_freeze_start_date' |
    'salary_freeze_end_date' |
    'salary_gross_payroll' |
    'pit_custom_rate' |
    'salary_net_payroll' |
    'salary_per_month' |
    'salary_per_month_net_payroll' |
    'surcharge_net_per_month_payroll' |
    'net_payroll_for_accounting' |
    'gross_payroll_for_accounting' |
    'bonus_per_quarter' |
    'bonus_per_half' |
    'bonus_per_half_net_kpi_act' |
    'bonus_per_half_net_kpi_for_accounting' |
    'bonus_per_year' |
    'bonus_per_year_net_kpi_act' |
    'bonus_per_year_net_kpi_for_accounting' |
    'premium_per_year_net_percentage' |
    'premium_per_year_net_forecast' |
    'premium_per_year_net_act' |
    'premium_per_year_net_for_accounting' |
    'total_net_payroll' |
    'total_net_bonus' |
    'total_net_bonus_original' |
    'total_net_payroll_net_bonus' |
    'total_gross_payroll_soc' |
    'total_gross_payroll_gross_bonus' |
    'total_gross_payroll_gross_bonus_soc' |
    'pit_per_month_payroll' |
    'outsource' |
    'civilLegal' |
    'count' |
    'changed' |
    'changedClassList' |
    'diffs' |
    'errors' |
    'is_transfered' |
    'is_transfered_out' |
    'is_transfered_in' |
    'settings' | 'calculus_version'> & {
    wage_budget_id: number,
    employee_id: number,
    vacancyMeta: Pick<VacancyInterface, 'replaced_private_person_list'> | null,
    deleted: boolean,
    budget_created_date: string,
    root_budget_created_date: string,
    root_created_at: string
};

export const wageBudgetEntryPropStub = PropStub<WageBudgetEntry>();

export type WageEntry = | WageFundEntry | WageBudgetEntry;

@Injectable()
export abstract class TableCache {
    abstract getCellData(
        column: ColumnConfig, row: WageBudgetEntry | WageFundEntry,
        index: number, contextMenu
    ): CellDataPayload;

    abstract getHeaderData(column: ColumnConfig);

    abstract getTotalData(totals: WageTotals, column: ColumnConfig);
}

export interface WageBudgetPermissions {
    ownsBudget: boolean;
    isEditable: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    canDelegate: boolean;
    canStartCompetition: boolean;
    canCancelCompetition: boolean;
    canViewStats: boolean;
    canViewShared: boolean;
    canDeleteItems: boolean;
}

export type WageBudgetData = {
    name: string;
    author_user_id: number;
    comment: string;
    year: number;
    year_created: number;
    current_budget_id: number;
    current_budget_created_date: string;
    root_budget_created_date: string;
    is_group: boolean;
    is_originaly_empty: boolean;
    current: WageBudgetEntry[];
    root_budget_id: number;
    competition: BudgetCompetition;
    is_shared: boolean;
    permission: WageBudgetPermissions
};

export type WageBudgetSummary = {
    delta: number;
    fteCost: number;
    originFteCost: number;
    prevFundFteCost?: number;
};

export enum CompetitionTypeEnum {
    AnnualBudgeting = 1,
    Rebudgeting,
    CurrentChanges,
    WholeGroupBudgeting,
    President,
}

export enum CompetitionNameEnum {
    AnnualBudgeting = 'Ежегодное бюджетирование',
    Rebudgeting = 'Перебюджетирование',
    CurrentChanges = 'Текущие изменения',
    WholeGroupBudgeting = 'ФОТ группы',
    President = 'Согласование президентом',
}

export type CreateWageEntryModel = {
    private_person_id: number,
    employee_id: number,
    functional_head_private_person_id: number,
    direct_head_private_person_id: number,
    copy_wage_data: boolean,
    effective_period: PeriodInterface
};

export type CreateWageEntryApiRequest = Omit<CreateWageEntryModel, 'effective_period'> & {
    effective_period_from: string,
    effective_period_to: string,
};

export interface DelegatedInterface {
    id: number;
    budgetId: number;
    effectiveTo: string;
    hiddenPrivatePersonIdList: number[] | null;
    shownPrivatePersonIdList: number[] | null;
    hiddenPrivatePersonNameList?: string[];
    shownPrivatePersonNameList?: string[];
    untilApproved: boolean;
    sharedToUserId: number;
    sharedToUserDisplayName: string;
    isActive: boolean;
    comment: string;
}

export interface DelegatingInterface {
    budget_id: number;
    user_id: number;
    hidden_private_person_id_list: number[] | null;
    shown_private_person_id_list: number[] | null;
    until_approved: boolean;
    effective_to: Date | null;
    with_parent: boolean | null;
    comment: string;
}

export type DelegatingApiModel = Omit<DelegatingInterface, 'effective_to'> & { effective_to: string };

export const delegatingPropStub = PropStub<DelegatingInterface>();

export interface WageStatsSettings {
    department_id: number;
    department_name: string;
    head_private_person_name: string;
    cfo_name: string;
    department_group_name: string;
    wage_stats: WageStats;
}

export interface WageStats {
    department_id: number;
    plan: WageStatsPlan;
    fact: WageStatsFact;
}

export interface WageStatsFact {
    billing_fact: number;
    revenue_fact: number;
    fte_fact: number;
    fte_immutable: number;
    fte_fact_net: number;
    ns_fact: PayrollPerMonth[];
    ns_vacancy_fact: PayrollPerMonth[];
    ns_outstaff_fact: PayrollPerMonth[];
}

export interface WageStatsPlan {
    billing_plan: number;
    revenue_plan: number;
}

export type WageCalculatedStats = { department_id: number, ns_fact: string, ns_vacancy_fact: string, ns_outstaff_fact: string };

export interface WageBudgetStats {
    budget_id: number;
    fte_cost: number;
    prev_fund_fte_cost: number;
    fte_cost_delta: number;
    ns_plan: string;
    ns_vacancy_plan: string;
    ns_outstaff_plan: string;
}

export interface WageStatsApiModel {
    year: number;
    changes: ({ department_id: number } & (Partial<WageStatsPlan> | Partial<WageStatsFact>))[];
}

export const wageStatsPropStub = PropStub<WageStatsSettings>();

export interface PrivatePersonInterface {
    id: number;
    name: string;
}

export const wageFunEntryToBudgetEntry = (w: WageFundEntry): WageBudgetEntry => {
    // console.log(w.private_person_display_name, md5(`${w.wage_holder_private_person_id}${w.employee_id}`))
    return {
        deleted: false, employee_id: w.employee_id, vacancyMeta: w.vacancy ? {
            replaced_private_person_list: w.vacancy.replaced_private_person_list
        } : null, wage_budget_id: null,
        wage_entry_id: md5(`${w.wage_holder_private_person_id}${w.employee_id}`),
        // wage_entry_id: w.wage_entry_id,
        wage_holder_private_person_id: w.wage_holder_private_person_id,
        wage_holder_display_name: w.wage_holder_display_name,
        private_person_id: w.private_person_id,
        private_person_display_name: w.private_person_display_name,
        employee_code: w.employee_code,
        private_person_photo_url: w.private_person_photo_url,
        company_name: w.company_name,
        contribution_params: w.contribution_params,
        agency_name: w.agency_name,
        agency_for_accounting_name: w.agency_for_accounting_name,
        vacation_ratio: w.vacation_ratio,
        department_name: w.department_name,
        division_name: w.division_name,
        position_name: w.position_name,
        position_grade_name: w.position_grade_name,
        head_private_person_display_name: w.head_private_person_display_name,
        functional_head_person_display_name: w.functional_head_person_display_name,
        full_name: w.full_name,
        company_id: w.company_id,
        agency_id: w.agency_id,
        agency_for_accounting_id: w.agency_for_accounting_id,
        department_id: w.department_id,
        division_id: w.division_id,
        position_id: w.position_id,
        position_grade_id: w.position_grade_id,
        head_private_person_id: w.head_private_person_id,
        functional_head_private_person_id: w.functional_head_private_person_id,
        hired_date: w.hired_date,
        fired_date: w.fired_date,
        forecast_fired_date: w.forecast_fired_date,
        department_change_date: w.department_change_date,
        fired: w.fired,
        plan_months: w.plan_months,
        plan_halfs: w.plan_halfs,
        plan_quarters: w.plan_quarters,
        year: w.year,
        comment: w.comment,
        salary_change_rationale: w.salary_change_rationale,
        employment_status_description: w.employment_status_description,
        employment_status_id: w.employment_status_id,
        enter_date: w.enter_date,
        reason_for_dismissal_description: w.reason_for_dismissal_description,
        reason_for_dismissal_id: w.reason_for_dismissal_id,
        is_top: w.is_top,
        salary_freeze_percentage: w.salary_freeze_percentage,
        salary_freeze_start_date: w.salary_freeze_start_date,
        salary_freeze_end_date: w.salary_freeze_end_date,
        salary_gross_payroll: w.salary_gross_payroll,
        salary_net_payroll: w.salary_net_payroll,
        salary_per_month: w.salary_per_month,
        salary_per_month_net_payroll: w.salary_per_month_net_payroll,
        surcharge_net_per_month_payroll: w.surcharge_net_per_month_payroll,
        net_payroll_for_accounting: w.net_payroll_for_accounting,
        bonus_per_quarter: w.bonus_per_quarter,
        bonus_per_half: w.bonus_per_half,
        bonus_per_half_net_kpi_act: w.bonus_per_half_net_kpi_act,
        bonus_per_half_net_kpi_for_accounting: w.bonus_per_half_net_kpi_for_accounting,
        bonus_per_year: w.bonus_per_year,
        bonus_per_year_net_kpi_act: w.bonus_per_year_net_kpi_act,
        bonus_per_year_net_kpi_for_accounting: w.bonus_per_year_net_kpi_for_accounting,
        premium_per_year_net_percentage: w.premium_per_year_net_percentage,
        premium_per_year_net_forecast: w.premium_per_year_net_forecast,
        premium_per_year_net_act: w.premium_per_year_net_act,
        premium_per_year_net_for_accounting: w.premium_per_year_net_for_accounting,
        pit_per_month_payroll: w.pit_per_month_payroll,
        outsource: w.outsource,
        civilLegal: w.civilLegal,
        count: w.count,
        changed: w.changed,
        diffs: w.diffs,
        settings: w.settings,
        is_transfered: w.is_transfered,
        is_transfered_out: w.is_transfered_out,
        is_transfered_in: w.is_transfered_in,
        gross_payroll_for_accounting: w.gross_payroll_for_accounting,
        total_net_payroll: w.total_net_payroll,
        total_net_bonus: w.total_net_bonus,
        total_net_payroll_net_bonus: w.total_net_payroll_net_bonus,
        total_gross_payroll_gross_bonus: w.total_gross_payroll_gross_bonus,
        total_gross_payroll_gross_bonus_soc: w.total_gross_payroll_gross_bonus_soc,
        pit_custom_rate: w.pit_custom_rate,
        history_employment_status_date: w.history_employment_status_date,
        history_employment_status_id: w.history_employment_status_id,
        calculus_version: w.calculus_version,
        root_created_at: null,
        budget_created_date: null,
        root_budget_created_date: null,
        is_vacancy: w.is_vacancy,
        vacancy: w.vacancy,
        entry_type: w.entry_type,
    };
};

export const convertWageFunEntryToBudgetEntry = (entries: WageFundEntry[]): WageBudgetEntry[] => entries.map(e => wageFunEntryToBudgetEntry(e));

type WageBudgetEntryForFormula = Pick<WageBudgetEntry,
    'total_net_payroll' |
    'salary_net_payroll'
> & {
    salary_per_month: number,
    salary_per_month1: number,
    salary_per_month2: number,
    salary_per_month3: number,
    salary_per_month4: number,
    salary_per_month5: number,
    salary_per_month6: number,
    salary_per_month7: number,
    salary_per_month8: number,
    salary_per_month9: number,
    salary_per_month10: number,
    salary_per_month11: number,
    salary_per_month12: number,
    length_of_service_per_year: number
};

export const getFlatEntry = (entry: WageBudgetEntry | WageFundEntry, wageUtils: Wage2023Utils): WageBudgetEntryForFormula => {
    return {
        total_net_payroll: entry.total_net_payroll,
        salary_net_payroll: entry.salary_net_payroll,
        salary_per_month: _.sum(Object.values(entry.net_payroll_for_accounting)),
        salary_per_month1: entry.salary_per_month['1'],
        salary_per_month2: entry.salary_per_month['2'],
        salary_per_month3: entry.salary_per_month['3'],
        salary_per_month4: entry.salary_per_month['4'],
        salary_per_month5: entry.salary_per_month['5'],
        salary_per_month6: entry.salary_per_month['6'],
        salary_per_month7: entry.salary_per_month['7'],
        salary_per_month8: entry.salary_per_month['8'],
        salary_per_month9: entry.salary_per_month['9'],
        salary_per_month10: entry.salary_per_month['10'],
        salary_per_month11: entry.salary_per_month['11'],
        salary_per_month12: entry.salary_per_month['12'],
        length_of_service_per_year: wageUtils.howLongWorkThisYear(entry) + 1
    };
};
