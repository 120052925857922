import { Injectable } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class WageFundControlsService {
    @BindObservable()
    public selectedYear = (new Date()).getFullYear();
    public selectedYear$: Observable<number>;

    @BindObservable()
    withVacancies = true;
    withVacancies$: Observable<boolean>;

    @BindObservable()
    withFired = true;
    withFired$: Observable<boolean>;

    @BindObservable()
    withPrivacy = false;
    withPrivacy$: Observable<boolean>;

    @BindObservable()
    withOutsource = false;
    withOutsource$: Observable<boolean>;

    @BindObservable()
    viewAll = false;
    viewAll$: Observable<boolean>;

    searchString$ = new BehaviorSubject<string>(undefined);

    constructor() {
    }
}
