import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorInterceptor } from './error.interceptor';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  private static applyTokenToRequest(request: HttpRequest<any>) {
    const token = AuthService.token;
    if (token) {
      request = request.clone({ headers: request.headers.append('Authorization', `Bearer ${token}`) });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 1200 == 401
    return next.handle(TokenInterceptor.applyTokenToRequest(request)).pipe(
      catchError(e => {
        if (e.error?.error_code === 1200 || e.status === 401) {
          AuthService.removeToken();
        }
        throw e;
      }),
    );
  }
}
