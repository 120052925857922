import {ControlValueAccessor, UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

export class BaseValueAccessor implements ControlValueAccessor {
  formControl = new UntypedFormControl(null);
  protected sub: Subscription;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value() {
    return this.formControl.value;
  }

  set value(val) {
    this.formControl.setValue(val);
  }

  constructor() {
    this.sub = this.formControl.valueChanges.subscribe((val) => this.onChange(val));
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.formControl.disable()
      : this.formControl.enable();
  }
}
