import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-user-avatar',
    template: `
        <div class="wrapper" [ngClass]="{'bg-gray': error}">
            <img [src]="photoUrl" (error)="onPhotoLoadingError()" />
        </div>
    `
})

export class AvatarComponent implements OnChanges {
    @Input() photoUrl: string;
    @HostBinding('class.error')
    public error = false;

    ngOnChanges(changes: SimpleChanges): void {
        this.error = false;
    }

    onPhotoLoadingError() {
        this.error = true;
    }

}