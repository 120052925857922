import {Injectable} from '@angular/core';
import {WageAttributeDiff, wageAttributes, WageAttributeTitle, WageBudgetEntry, WageDiffMap, WageDiffMapItem, WageFundEntry} from 'app/modules/wage/shared/wage.interfaces';
import {BindObservable} from 'bind-observable';
import _, {isNumber, isObject} from 'lodash';
import {Observable, of, Subject} from 'rxjs';
import {ModalService} from 'shared/modules/modal/modal.service';
import {PusherService} from 'shared/services/pusher.service';
import {toPHPDate} from 'shared/utils/form.utils';

export const fieldsWithDate: WageAttributeTitle[] = [
    'company_id',
    'agency_id',
    'agency_for_accounting_id',
    'department_id',
    'division_id',
    'position_id',
    'position_grade_id',
    'head_private_person_id',
    'functional_head_private_person_id',
    'salary_per_month',
];

export const complexFormulaFields = {
    bonus_per_half: [
        'bonus_per_half_1',
        'bonus_per_half_2',
        'bonus_per_half_net_kpi_for_accounting'
    ]
};

export const changeItemNeedsDate = (person: WageDiffMapItem, attr: WageAttributeTitle) => {
    return person.presence === 0 && person.private_person_display_name.indexOf('ВАКАНСИЯ') === -1  && fieldsWithDate.includes(attr);
};

@Injectable({
    providedIn: 'root'
})
export abstract class WageChangesService {

    @BindObservable()
    protected _originWage: WageFundEntry[] | WageBudgetEntry[] = [];
    protected _originWage$: Observable<WageFundEntry[]> | Observable<WageBudgetEntry[]>;

    get originWage(): WageFundEntry[] | WageBudgetEntry[] {
        return this._originWage;
    }

    set originWage(wageFundEntries: WageFundEntry[] | WageBudgetEntry[]) {
        this._originWage = wageFundEntries;
        if (wageFundEntries.length) {
            this._originWageGroupedByWageId.clear();
            wageFundEntries.forEach((wageEntry) => {
                if (!this._originWageGroupedByWageId.has(wageEntry.wage_entry_id)) {
                    this._originWageGroupedByWageId.set(wageEntry.wage_entry_id, wageEntry);
                }
            });
        }
    }

    protected _originWageGroupedByWageId: Map<string, WageFundEntry | WageBudgetEntry> = new Map();

    get originWageGroupedByWageId(): Map<string, WageFundEntry | WageBudgetEntry> {
        return this._originWageGroupedByWageId;
    }

    private _diff: WageDiffMap = new Map();

    get diff() {
        return this._diff;
    }

    set diff(d: WageDiffMap) {
        this._diff = d;
    }

    get diffAsArray() {
        return Array.from(this._diff.entries()).map(([a, b]) => ({
            ...b, diffs: Array.from(b.diffs.entries()).reduce((acc, [a, b]) => ({
                ...acc,
                [a]: b
            }), {})
        }));
    }

    public changeSignal$ = new Subject();
    public removalSignal$ = new Subject<{ flush: boolean, wageEntryId: string, wageAttributeTitle: string }>();

    skipBroadcast = false;

    get wageChangesCount$(): Observable<number> {
        return of(this._diff.size);
    }

    get wageChangesCount(): number {
        return this._diff.size;
    }

    keypressEventHandler = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.shiftKey && event.code === 'KeyZ') {
            const flushedWageDiffMap = JSON.parse(localStorage.getItem('_flushedWageDiffMap'), (key, value) => {
                if (typeof value === 'object' && value !== null) {
                    if (value.dataType === 'Map') {
                        return new Map(value.value);
                    }
                }
                return value;
            });
            if (flushedWageDiffMap.size > 0) {
                console.info('Wage budget changes restored.');
                this._diff = flushedWageDiffMap;
                this.changeSignal$.next();
            }
        }
    }

    constructor(
        protected pusher: PusherService,
        private modal: ModalService,
    ) {

        window.addEventListener('keypress', this.keypressEventHandler);
    }

    private addToDiffMap(wageEntryId: string, wageAttributeTitle: WageAttributeTitle, diffItem: WageAttributeDiff) {
        if (this._diff.has(wageEntryId) === false) {
            // Если записи по сотруднику нет, то просто добавляем новую
            this._diff.set(wageEntryId, {
                wage_entry_id: wageEntryId,
                private_person_id: this.getValueFromOriginWage(wageEntryId, wageAttributes.private_person_id),
                wage_holder_private_person_id: this.getValueFromOriginWage(
                    wageEntryId, wageAttributes.wage_holder_private_person_id),
                private_person_display_name: this.getPrivatePersonDisplayNameFromOrigin(wageEntryId),
                diffs: new Map([[wageAttributeTitle, diffItem]]),
                // ставим presence по-умолчанию 0, предполагая, что изменение вносится в запись, которая была (не "пришла" и не "ушла")
                presence: 0
            });
        } else {
            // Если запись есть, то мерджим
            this._diff.get(wageEntryId).diffs.set(wageAttributeTitle, diffItem);
        }
    }

    protected getValueFromOriginWage(wageEntryId: string, wageAttributeTitle: WageAttributeTitle) {
        return this._originWageGroupedByWageId.has(wageEntryId) ? this._originWageGroupedByWageId.get(wageEntryId)[wageAttributeTitle] : null;
    }

    protected getPrivatePersonDisplayNameFromOrigin(wageEntryId: string) {
        return this._originWageGroupedByWageId.has(wageEntryId) ? this._originWageGroupedByWageId.get(wageEntryId).private_person_display_name : wageEntryId;
    }

    pushChangesForAll(
        wageAttributeTitle: WageAttributeTitle,
        {effective_since, new_value, items}: { effective_since: Date, new_value: any, items?: Observable<any[]> }
    ) {
        this._originWageGroupedByWageId.forEach((wageEntry, wageEntryId) => {
            const newValue = isObject(new_value) ? Object.assign({}, new_value) : new_value;
            this.addToDiffMap(wageEntryId, wageAttributeTitle, {
                effective_since,
                old_value: this.getValueFromOriginWage(wageEntryId, wageAttributeTitle),
                new_value: newValue,
                items,
            });
        });
        this.changeSignal$.next();
    }

    // Пушим изменения атрибутов в карту
    pushChanges(
        wageEntryId: string,
        wageAttributeTitle: WageAttributeTitle,
        {effective_since, new_value, items}: { effective_since: Date, new_value: any, items?: Observable<any[]> },
        force = false
    ) {

        new_value = isNumber(new_value) ? Math.floor(new_value) : new_value;

        const oldValue = JSON.stringify([this.getValueFromOriginWage(wageEntryId, wageAttributeTitle)]);
        const isValueChagned = !_.isEqual(oldValue, new_value);

        if (wageAttributeTitle === 'settings') {
            new_value = Object.assign({}, this.getValueFromOriginWage(wageEntryId, wageAttributeTitle), new_value);
            Object.keys(new_value).forEach((key) => {
                // Если авторасчет включили, а потом выключили - убираем его из изменений и убираем изменение самого атрибута
                if (new_value[key]['auto'] === true && oldValue === JSON.stringify(null)) {
                    this.removePersonItemChanges(wageEntryId, key, true);
                    delete new_value[key]['auto'];
                    if (!Object.keys(new_value[key]).length) {
                        delete new_value[key];
                    }
                }
            });
        }

        if (isValueChagned || force) {
            this.addToDiffMap(wageEntryId, wageAttributeTitle, {
                effective_since,
                old_value: this.getValueFromOriginWage(wageEntryId, wageAttributeTitle),
                new_value,
                items,
            });
            this.changeSignal$.next();
        } else {
            this.removePersonItemChanges(wageEntryId, wageAttributeTitle);
        }
    }

    removePersonItemChanges(wageEntryId: string, wageAttributeTitle: string, silent: boolean = false) {
        if (
            this._diff.has(wageEntryId) &&
            this._diff.get(wageEntryId).diffs.has(wageAttributeTitle as any)
        ) {
            this._diff.get(wageEntryId).diffs.delete(wageAttributeTitle as any);
        }

        if (Object.keys(complexFormulaFields).includes(wageAttributeTitle)) {
            Object.values(complexFormulaFields).flat().forEach((key) => {
                if (
                    this._diff.get(wageEntryId).diffs.get('settings').new_value &&
                    this._diff.get(wageEntryId).diffs.get('settings').new_value[key] &&
                    (!this._diff[wageEntryId]?.diffs?.settings?.old_value || !this._diff[wageEntryId]['diffs']['settings']['old_value'][key])
                ) {
                    const newValue = {
                        ...this._diff.get(wageEntryId).diffs.get('settings').new_value,
                    };
                    delete newValue[key];
                    this._diff.get(wageEntryId).diffs.set('settings', {
                        ...this._diff.get(wageEntryId).diffs.get('settings'),
                        new_value: newValue
                    });

                }
            });
        }

        if (
            this._diff.has(wageEntryId) &&
            this._diff.get(wageEntryId).diffs.size === 0
        ) {
            this._diff.delete(wageEntryId);
        }

        if (!silent) {
            this.changeSignal$.next();
        }
    }

    removeAllPersonChanges(wageEntryId: string) {
        this._diff.delete(wageEntryId);
        this.changeSignal$.next();
    }

    // tslint:disable-next-line:cognitive-complexity
    mergeChagnes<T extends (WageFundEntry | WageBudgetEntry)>(entry: T, customWageDiffMap: WageDiffMap = null): T {
        const wageDiffMap = customWageDiffMap ?? this._diff;
        if (wageDiffMap.has(entry.wage_entry_id)) {
            const diffEntry = wageDiffMap.get(entry.wage_entry_id);
            const changedEntry = entry;
            const exceptions = Object.keys(complexFormulaFields);
            // tslint:disable-next-line:cyclomatic-complexity
            for (const wageAttributeTitle of wageDiffMap.get(entry.wage_entry_id).diffs.keys()) {

                if (wageAttributeTitle.indexOf('total') !== -1) {
                    continue;
                }

                const diffEntryDiffs = diffEntry.diffs.get(wageAttributeTitle);
                if (wageAttributeTitle === wageAttributes.settings) {
                    // Список атрибутов в настройках
                    const settingsAttributes = Object.keys(diffEntryDiffs.new_value);

                    for (const settingsAttribute of settingsAttributes) {
                        // Список настроек атрибута
                        const attributeSettingsKeys = diffEntryDiffs.new_value[settingsAttribute];

                        for (const attributeSettingsKey in attributeSettingsKeys) {
                            if (attributeSettingsKeys.hasOwnProperty(attributeSettingsKey)) {
                                if (!!changedEntry.settings === false) {
                                    changedEntry.settings = {};
                                }
                                if (!!changedEntry.settings[settingsAttribute] === false) {
                                    changedEntry.settings[settingsAttribute] = {};
                                }
                                if (attributeSettingsKey !== 'effective_from') {
                                    changedEntry.settings[settingsAttribute][attributeSettingsKey] = diffEntryDiffs.new_value[settingsAttribute][attributeSettingsKey];
                                }
                            }
                        }
                    }
                } else if (wageAttributeTitle === wageAttributes.bonus_per_half) {
                    if (!!changedEntry.settings === false) {
                        changedEntry[wageAttributeTitle] = diffEntryDiffs.new_value;
                    }
                    if (changedEntry.settings && (!changedEntry.settings['bonus_per_half_1'] || !changedEntry.settings['bonus_per_half_1']['formula'])) {
                        changedEntry[wageAttributeTitle]['1'] = diffEntryDiffs.new_value['1'];
                    }
                    if (changedEntry.settings && (!changedEntry.settings['bonus_per_half_2'] || !changedEntry.settings['bonus_per_half_2']['formula'])) {
                        changedEntry[wageAttributeTitle]['2'] = diffEntryDiffs.new_value['2'];
                    }
                } else {
                    changedEntry[wageAttributeTitle] = diffEntryDiffs.new_value;
                }


                if (!!changedEntry.changed) {
                    changedEntry.changed.push(wageAttributeTitle);
                } else {
                    changedEntry.changed = [wageAttributeTitle];
                }

                // if (typeof wageDiffMap[entry.wage_entry_id].diffs[wageAttributeTitle].new_value === 'object' && typeof wageDiffMap[entry.wage_entry_id].diffs[wageAttributeTitle].old_value === 'object') {
                //     if (!!changedEntry.changedClassList === false) {
                //         changedEntry.changedClassList = {};
                //     }
                //     if (wageAttributeTitle !== 'settings') {
                //         changedEntry.changedClassList[wageAttributeTitle] = Object.keys(wageDiffMap[entry.wage_entry_id].diffs[wageAttributeTitle].new_value)
                //                                                                   .filter((key) => wageDiffMap[entry.wage_entry_id].diffs[wageAttributeTitle].old_value && wageDiffMap[entry.wage_entry_id].diffs[wageAttributeTitle].old_value[key] !== wageDiffMap[entry.wage_entry_id].diffs[wageAttributeTitle].new_value[key])
                //                                                                   .map((key) => 'changed_' + key.toString())
                //                                                                   .join(' ');
                //     }
                // }

                if (!!changedEntry.settings === false) {
                    changedEntry.settings = {};
                }

                if (!!changedEntry.settings[wageAttributeTitle] === false) {
                    changedEntry.settings[wageAttributeTitle] = {};
                }

                changedEntry.settings[wageAttributeTitle] = {
                    ...changedEntry.settings[wageAttributeTitle],
                    effective_from: !!diffEntryDiffs.effective_since
                                    ? toPHPDate(diffEntryDiffs.effective_since)
                                    : null
                };
            }
            // Object.keys(wageDiffMap[entry.wage_entry_id].diffs).forEach(wageAttributeTitle => {
            //
            //
            // });
            // entry.diffs = wageDiffMap[entry.wage_entry_id].diffs;
            // return changedEntry;
        }
        return entry;
    }

    flush() {
        this.resetDiffMap();
        this.refresh();
    }

    resetDiffMap() {
        if (this._diff.size > 0) {
            localStorage.setItem('_flushedWageDiffMap', JSON.stringify(this._diff, (key, value) => {
                if (value instanceof Map) {
                    return {
                        dataType: 'Map',
                        value: Array.from(value.entries()), // or with spread: value: [...value]
                    };
                } else {
                    return value;
                }
            }));
        }
        this._originWage = [];
        this._diff.clear();
    }

    refresh() {
        this.changeSignal$.next();
    }

    // remove [settings][settings]
    refreshSettings() {
        Object.keys(this._diff).forEach((key) => {
            if (this._diff[key] && this._diff[key]['diffs']['settings'] && this._diff[key]['diffs']['settings']['new_value']['settings']) {
                delete this._diff[key]['diffs']['settings']['new_value']['settings'];
                if (Object.keys(this._diff[key]['diffs']['settings']['new_value']).length === 0) {
                    this.removePersonItemChanges(key, 'settings');
                }
                if (this._diff[key] && JSON.stringify(this._diff[key]['diffs']['settings']['new_value']) === JSON.stringify(this._diff[key]['diffs']['settings']['old_value'])) {
                    this.removePersonItemChanges(key, 'settings');
                }
            }
        });
        this.changeSignal$.next();
    }
}
