<app-modal [title]="title" [modalRef]="modalRef">
    <div class="modal-body">
        <div [innerHTML]="message"></div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="modalRef.hide()">
            {{ closeButtonTitle }}
        </button>
    </div>
</app-modal>
